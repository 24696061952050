import React, { Fragment, useEffect, useState } from "react";
import { Title } from "../SideBar";
import ClientBilling from "./Billing";
import { useDispatch, useSelector } from 'react-redux';
import { setIsChildGroup, setCurrentGroup, loadAllFacilities } from "../../redux/globalfunctions";
import isNullorEmpty from "../../Utilities/isNullorEmpty";
import Spinner from "../../Utilities/Spinner";

const ParentBilling = props => {

    const currentGroup = useSelector(state => state.globalState.currentGroup)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        if(!isNullorEmpty(currentGroup)){
            dispatch(loadAllFacilities())
        }             
    },[currentGroup])

    useEffect(() => {
        console.log(props)
        if(props && loading){
            setLoading(false)
        }             
    },[props])

    return <>
        {loading ? <Spinner /> : isNullorEmpty(props.childGroups) ? <ClientBilling /> : <Fragment>
            {currentGroup ? <ClientBilling/> : 
            <div className="parentbilling billing">
                <div className="billing-message">            
                    <Title />
                    <div className="message-cont">    
                        <div className="billingTablecont">
                            <div className="billing-text">Select a Group</div>
                            <div className="billing-options">
                                {props.childGroups.map(g => <button key={g} onClick={() => {dispatch(setCurrentGroup(g));dispatch(setIsChildGroup(true))}}>{g.toUpperCase()}</button>)}
                            </div> 
                        </div> 
                    </div>
                </div>
            </div>}
        </Fragment>}
    </>
    

}
export default ParentBilling;