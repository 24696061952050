import * as actions from './actions';
import updateObject from '../Utilities/updateObject';

const initialState = {
    loading: true,
    user: null,
    facilities: null,
    allFacilities: null, 
    ivfluids: [],
    isChildGroup: false,
    currentGroup: null
}



const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actions.UPDATE:
        return updateObject(state, { [action.field]: action[action.field] })
    case actions.LOGOUT:
        return initialState
    default:
        return state;

    }
}

export default reducer;