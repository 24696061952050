import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Form, Col, Button, Spinner, Alert } from 'react-bootstrap';
import axios from 'axios';
import { cloneDeep, capitalize } from 'lodash';
import isNullorEmpty from '../Utilities/isNullorEmpty';
import Config from '../Utilities/Config';
import dolphinCareLogo from '../Assets/Logo.png';
import { useSearchParams } from 'react-router-dom';
import Wrapper from './Wrapper';

const RegisterForm = (props) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const currentEmail = searchParams.get("email")
    const currentToken = searchParams.get("token")

    const [user, setUser] = useState({
        first_name: {
            value: "",
            errMsg: ""
        },
        last_name: {
            value: "",
            errMsg: ""
        },
        password: {
            value: "",
            errMsg: ""
        },
        confirmpassword: {
            value: "",
            errMsg: ""
        },
        email:{
            value:searchParams.get("email")
        },
        token:{
            value:searchParams.get("token")
        }
    })
    const routerLoc = useLocation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [validatedLink, setValidatedLink] = useState(false)
    const [currentGroup, setCurrentGroup] = useState(null)
    const [acceptTerms, setAcceptTerms] = useState(false)
    const [validatedFields, setValidatedFields] = useState(false)
    const [newUserSuccess, setNewUserSuccess] = useState(false)
    const [registerError, setRegisterError] = useState(false)



    const removeQueryParams = () => {
        if (!isNullorEmpty(routerLoc.search)) {
            sessionStorage.setItem('key', routerLoc.search.split('=')[1]);
            navigate(routerLoc.pathname, { replace: true })
        }
    }

    useEffect(() => {
        console.log(currentEmail, currentToken)
        removeQueryParams()
        if (!isNullorEmpty(currentEmail) && !isNullorEmpty(currentToken)) {
            axios.post('/api/registeraccount/', { 'email': currentEmail, 'token': currentToken }, Config()).then((res) => {
                setValidatedLink(res.data['verified'])
                setCurrentGroup(res.data['group'])
                setLoading(false)
            }).catch(err => {
                console.log(err);
                if (err.response.status === 501) {
                    setValidatedLink(false)
                    setLoading(false)
                }
            })
        }
        else {
            setValidatedLink(false)
            setLoading(false)
        }
    }, [])

    const registerUser = () => {
        setLoading(true);
        console.log(user)

        const userToSave = {};
        for (let [key, value] of Object.entries(user)) {
            userToSave[key] = value.value;
        }
        userToSave['group'] = currentGroup
        axios.post('/api/createuser/', userToSave, Config()).then((res) => {
            console.log(res.data)
            setNewUserSuccess(true)
            setLoading(false)
        }).catch(err => {
            console.log(err);
            setRegisterError(true);
            setLoading(false);
        })
    }

    const inputChanged = (event, inputIdentifier) => {
        let value = ['string', 'number'].includes(typeof event) ? event
            : event.target.value;
        const newUser = cloneDeep(user);
        newUser[inputIdentifier].value = value;
        setUser(newUser);
    }

    const getError = inputIdentifier => {
        if (inputIdentifier === "password") {
            return user.password.value.length < 8 ? 'Password must be at least 8 characters' : ""
        }
        else if (inputIdentifier === "confirmpassword") {
            return user.confirmpassword.value != user.password.value ? 'Does not match password' : ""
        }
        else {
            return isNullorEmpty(user[inputIdentifier].value) ? `${inputIdentifier.replace(/_/g, ' ')} cannot be blank` : ""
        }
    }
    const validate = (inputIdentifier) => {
        let err = getError(inputIdentifier)
        const newUser = cloneDeep(user);
        newUser[inputIdentifier].errMsg = capitalize(err);
        setUser(newUser);
        if (err === '') {
            for (const key in user) {
                err += getError(key)
            }
        }
        setValidatedFields(err === '')
    }

    return <Wrapper><div className='register'>
        {loading ? <Spinner animation='border' variant='dark' /> :
            newUserSuccess ? <div>
                <Alert key={'success'} variant={'success'}>
                    <Alert.Heading>DolphinCare is ready to use!</Alert.Heading>
                    <p>Your account has been created successfully.<br />
                        <a href="https://chrome.google.com/webstore/detail/dolphincare/efapffijphkepcdbdgklkgpnmpdajjgf">Install the DolphinCare extension here</a> and log in to your EHR system to start using the app.</p>
                </Alert>
            </div> :
                registerError ? <div>Error registering your account. Please try again. <br /><a href='mailto:support@dolphincare.ai'>Contact support</a> if error persists.</div> :
                validatedLink ? <Form>
                    <h2>Create Your DolphinCare Account</h2>
                    <Form.Group as={Col}>
                        {/* <Form.Label>Username</Form.Label> */}
                        <Form.Control size='sm'
                            value={user.email.value}
                            disabled readonly />

                        {/* <Form.Label>First Name</Form.Label> */}
                        <Form.Control size='sm'
                            placeholder="First Name"
                            value={user.first_name.value}
                            onChange={e => inputChanged(e, 'first_name')}
                            onBlur={e => validate('first_name')} />
                        {!isNullorEmpty(user.first_name.errMsg) &&
                            <Form.Label className='error'>{user.first_name.errMsg}</Form.Label>}
                        {/* <Form.Label>Last Name</Form.Label> */}
                        <Form.Control size='sm'
                            placeholder="Last Name"
                            value={user.last_name.value}
                            onChange={e => inputChanged(e, 'last_name')}
                            onBlur={e => validate('last_name')} />
                        {!isNullorEmpty(user.last_name.errMsg) &&
                            <Form.Label className='error'>{user.last_name.errMsg}</Form.Label>}

                        {/* <Form.Label>Create Password</Form.Label> */}
                        <Form.Control size='sm'
                            placeholder="Password"
                            type='password'
                            value={user.password.value}
                            onChange={e => inputChanged(e, 'password')}
                            onBlur={e => validate('password')} />
                        {!isNullorEmpty(user.password.errMsg) && <Form.Label className='error'>{user.password.errMsg}</Form.Label>}

                        <Form.Control size='sm'
                            placeholder=" Confirm Password"
                            type='password'
                            value={user.confirmpassword.value}
                            onChange={e => inputChanged(e, 'confirmpassword')}
                            onBlur={e => validate('confirmpassword')} />
                        {!isNullorEmpty(user.confirmpassword.errMsg) && <Form.Label className='error'>{user.confirmpassword.errMsg}</Form.Label>}
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Check type='checkbox' checked={acceptTerms} onChange={() => setAcceptTerms(!acceptTerms)} label={<span>I read and agree to the <a href="/termsofuse" target="_blank">Terms of Use</a></span>} />
                    </Form.Group>
                    <div>
                        <Button disabled={!acceptTerms || !validatedFields} onClick={() => registerUser()}>Register</Button>
                    </div>
                </Form> : <div>Link is invalid. Please try again. <br /><a href='mailto:support@dolphincare.ai'>Contact support</a> if you think this message is an error.</div>}
    </div></Wrapper>
}

export default RegisterForm