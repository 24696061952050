import axios from "axios";
import React, { useState } from "react";
import Config from "../Utilities/Config";
import isNullorEmpty from "../Utilities/isNullorEmpty";

const BackendCreds = () => {
    const [message, setMessage] = useState('')
    const sendCredsToFile = () => {
        const inputArray = [...document.getElementsByTagName('input')]
        if (inputArray.find(e => isNullorEmpty(e.value) && e.id !== 'path')){
            setMessage(inputArray.find(e => isNullorEmpty(e.value) && e.id !== 'path').id +' cannot be blank')
        } else {
            const values = {};
            inputArray.forEach(e => values[e.id] = e.value)
            axios.post('/api/internal/backendcreds/', values, Config()).then(res => setMessage('Credentials sent!')).catch(err => setMessage(err.response.data))
        }
    }

    return <div className="backendcreds">
        <input id="name" placeholder="Company Name" />
        <input id="username" placeholder="Username" />
        <input id="password" placeholder="Password" />
        <input id="encryptionkey" placeholder="Encryption Key" />
        <input id="path" placeholder="Full Backup Path (optional)" />
        <button onClick={sendCredsToFile}>Submit</button>
        <div>{message}</div>
    </div>
}


export default BackendCreds