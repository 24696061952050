import Cookies from 'js-cookie';

const Config = () => {
    const csrf = localStorage.getItem('dc_token');
    return ({
        headers: { "X-CSRFToken": csrf },
        timeout: 60 * 60 * 1000
    })
}

export default Config;