import React, { Fragment, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { Navigate, useHistory, useSearchParams } from 'react-router-dom'
import SignIn from './UserInterface/SignIn';
import IVList from './UserInterface/IVList';
import UserInterface from './UserInterface/UserInterface';
import axios from 'axios';
import Config from './Utilities/Config';
import Spinner from './Utilities/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { loadFacilities, loadAllFacilities, setUser } from './redux/globalfunctions';
import Logout from './Utilities/Logout';
import isNullorEmpty from './Utilities/isNullorEmpty';

const Client = (props) => {


    const routerLocation = useLocation()
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector(state => state.globalState.user)
    const { signout } = Logout()
    const [billing, setBilling] = useState(false)
    const [noActiveFacs, setNoActiveFacs] = useState(null)
    const [childGroups, setChildGroups] = useState(null)

    useEffect(() => {
        checkSession()
    }, [])

    useEffect(() => {
        if (props.idle) { signout() }
    }, [props.idle])

    const checkSession = () => {
        axios.post('/api/checkdashboardsession/', {}, Config()).then(res => {
            res = JSON.parse(res.data)
            setChildGroups(res['childGroups'])
            res['user']['billing-only'] = res['billing'] === "Trial Ended" || res['payingInactive']
            dispatch(setUser(res['user']))           
            // getGroupsBilling(res.data)
            setBilling(res['billing'])
            setNoActiveFacs(res['payingInactive'])            
            dispatch(loadFacilities())
            dispatch(loadAllFacilities())        
                   
            if ((res['billing'] === 'Trial Ended' || res['payingInactive']) && routerLocation.pathname !== '/client/billing'){
                navigate('/client/billing')
            }
            // else if ((routerLocation.pathname == '/client/users' && res['user'].p_user_management === 0) || 
            //     (routerLocation.pathname == '/client/settings' && res['user'].p_edit_preferences === 0) ||
            //     (routerLocation.pathname == '/client/billing' && res['user'].p_billing === 0) || 
            //     res['billing'] === false){
            //         navigate('/client/history')
            // }
            else if (routerLocation.pathname == '/client/signin') {
                navigate('/client')
            }           
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            dispatch(setUser(null))
            navigate('/client/signin')
        })
    }

    const getGroupsBilling = (user) => {
        axios.get(`/api/groupbillingdisplaystatus/${user.group}/`, Config()).then((res) => {
            setBilling(res.data)
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
        })
    }


    return loading ? <div className='client-loading'><Spinner /></div> : 
    <>
        {!user ?
        <Routes>
                <Route path='/' element={<Navigate to='signin' replace={true} />} />
                <Route path='signin' element={<SignIn checkSession={checkSession} />} />
        </Routes>
        : <UserInterface billing={billing} noActiveFacs={noActiveFacs} childGroups={childGroups}/>}
    </>
};

export default Client;