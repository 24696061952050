import React, { useEffect, useState, Fragment } from "react";
import { Form, Row, Col, Button } from 'react-bootstrap';
import { cloneDeep, capitalize } from 'lodash';
import isNullorEmpty from "../Utilities/isNullorEmpty";
import Spinner from "../Utilities/Spinner";
import CustomModal from "../Utilities/CustomModal";

const ClientUserFacilities = props => {
    
    const [currentFacilities, setCurrentFacilities] = useState([])

    useEffect(() => {
        if(!isNullorEmpty(props.facilities)){
            console.log(props.user.facilities.value)
            const userFacilities = props.user.facilities.value.split(',').map(f => parseInt(f))
            setCurrentFacilities(userFacilities == 0 ? props.facilities.map(f => f.facilityid) : userFacilities)  
        }    
    }, [props.facilities]) 

    const updateFacility = (event, id, all=false) => {
        let facilitiesCopy = all ? [] : [...currentFacilities]
        if(event.target.checked){
            if(all){
                props.facilities.forEach(f => facilitiesCopy.push(f.facilityid))
            }
            else{
                facilitiesCopy.push(id) 
            }
        }
        else if(!event.target.checked && !all){
            facilitiesCopy = facilitiesCopy.filter(f => f !== id)
        }
        setCurrentFacilities(facilitiesCopy)
    }

    useEffect(() => {
        if(currentFacilities && props.facilities){
            let userCopy = cloneDeep(props.user)
            userCopy.facilities.value = currentFacilities.length === props.facilities.length ? '0' : currentFacilities.join(',')
            props.setUser(userCopy)
        }        
    }, [currentFacilities])

    const handleClose = () => {
        props.setFacilitySpecific(props.user.facilities.value !== "0")
        props.setShowPage(false)
    }
    
    return <div className="client-user-facilities">
        <CustomModal height='90%' width="fit-content" closeModal={() => handleClose()} backArrow title='facility access' cancelBtnTxt='close'>       
                {!props.facilities ? <Spinner /> : <>                        
                    <Form.Label className="form-label" >
                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.37492 14.0417H2.16659V4.03503C2.16661 3.78577 2.24507 3.54283 2.39085 3.34064C2.53663 3.13845 2.74234 2.98726 2.97884 2.90849L9.31217 0.797909C9.49066 0.738461 9.68072 0.72227 9.86669 0.75067C10.0527 0.779071 10.2292 0.85125 10.3818 0.961262C10.5344 1.07127 10.6587 1.21597 10.7445 1.38343C10.8302 1.55089 10.8749 1.73632 10.8749 1.92445V14.0417H11.6666V6.90878C11.6666 6.85024 11.6796 6.79244 11.7047 6.73954C11.7298 6.68664 11.7663 6.63995 11.8115 6.60285C11.8568 6.56574 11.9098 6.53915 11.9666 6.52497C12.0234 6.5108 12.0826 6.50939 12.14 6.52087L13.8785 6.8692C14.1476 6.92299 14.3898 7.06832 14.5638 7.28047C14.7379 7.49263 14.8331 7.75853 14.8333 8.03295V14.0417H15.6249C15.8349 14.0417 16.0362 14.1251 16.1847 14.2736C16.3332 14.422 16.4166 14.6234 16.4166 14.8334C16.4166 15.0433 16.3332 15.2447 16.1847 15.3932C16.0362 15.5416 15.8349 15.625 15.6249 15.625H1.37492C1.16496 15.625 0.963592 15.5416 0.815126 15.3932C0.666659 15.2447 0.583252 15.0433 0.583252 14.8334C0.583252 14.6234 0.666659 14.422 0.815126 14.2736C0.963592 14.1251 1.16496 14.0417 1.37492 14.0417Z" fill="white"/>
                        </svg>
                        facilities
                    </Form.Label>                  
                    <div className="facility-list">
                        <Form.Check custom className="all-none" type='checkbox' checked={props.facilities.every(fac => currentFacilities.includes(fac.facilityid))} onChange={(e) => updateFacility(e, props.facilities, true)} label="All/None" id="all-none"/>                          
                        <div className="facility-columns">
                        {props.facilities.map(f => <Form.Check custom type='checkbox' label={f.facility_name} key={f.facilityid} id={f.facilityid} checked={currentFacilities.includes(f.facilityid)} onChange={(e) => updateFacility(e, f.facilityid)} />)}
                        </div>
                    </div>
                </>}
                {/* {!isNullorEmpty(errMsg) && <div className='error'>{errMsg}</div>} */}     
        </CustomModal>
    </div>
}

export default ClientUserFacilities;