import React, { useEffect, useState } from 'react';
import {capitalize} from 'lodash';
import { formatDateTime } from '../Utilities/FormatDate';
import dynamicSort from '../Utilities/SortTable';
import { BackArrow } from '../Utilities/SvgImages';


const UserFeedback = (props) => {

    const [sortField, setSortField] = useState("-date")

    const changeSortField = field => {
        setSortField(sortField === field ? `-${field}` : field)
    }

    
    return <div className='usersInfo'>
        <div onClick={() => props.setViewFeedback(false)} className='pointer'><BackArrow/></div>
        <h2>Feedback</h2>
        <table>
            <tr>
                <th onClick={() => changeSortField("user_last_name")}>User
                    <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                </th>
                <th onClick={() => changeSortField("group")}>Group
                    <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                </th>
                <th onClick={() => changeSortField("facility")}>Facility
                    <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                </th>
                <th onClick={() => changeSortField("resident")}>Mrid
                    <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                </th>
                <th onClick={() => changeSortField("date")}>Date
                    <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                </th>
                <th onClick={() => changeSortField("action")}>Button Clicked
                    <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                </th>
                <th onClick={() => changeSortField("action")}>Message
                    <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                </th>
            </tr>
            {props.feedback && props.feedback.sort(dynamicSort(sortField)).map(a =>
                <tr key={a.id}>
                    <td id={a.user}>{capitalize(a.user_first_name) + " " + capitalize(a.user_last_name) + " (" + a.user + ")"}</td>
                    <td>{a.group_name}</td>
                    <td>{a.facilityid}</td>
                    <td>{a.mrid}</td>
                    <td>{formatDateTime(a.date)}</td>
                    <td>{a.user_response_K0520A2 == 1 ? "Yes" : "No"}</td>
                    <td>{a.message}</td>
                </tr>)}
        </table>
    </div>
}

export default UserFeedback;

