
import React from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUser, resetState } from "../redux/globalfunctions";

export default function Logout () {
    const navigate = useNavigate()
    const dispatch = useDispatch() 
    
    const signout = () => {
        axios.post('/api/logout/').then(res => {
            window.localStorage.removeItem('dc_token');
            dispatch(resetState())
            navigate('/client/signin')
        }).catch(err => {
            console.log(err.response);
        }) 
    }  

    return {signout}
}