import dolphinCareLogo from './../Assets/Logo.png';
import dolphinCareLogoIcon from './../Assets/InvertedIcon.png';
import React from 'react'

const Wrapper = props => <div className="App">
    <header>
        <img src={dolphinCareLogo} />

    </header>
    <div className='main'>
        {props.children}

    </div>
    <img className='icon' src={dolphinCareLogoIcon} />
</div>


export default Wrapper