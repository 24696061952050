import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import Config from "../Utilities/Config";
import { getPdfUrl } from "../Utilities/getPdfUrl";
import Spinner from "../Utilities/Spinner";
import '../connect.scss';


const Document = () => {
    const routerParams = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const [url, setUrl] = useState(null)
    const [err, setErr] = useState(null)
    const [fullPage, setFullPage] = useState(null)

    useEffect(() => {
        if (routerParams.id && routerParams.id != 'null') {
            // if (window.location.host === "internal.dolphincare.ai" || window.location.host === "localhost:3001") {
            //     getDoc('internal')
            // } else {
                const interval = setInterval(() => {
                    if (!url && localStorage.getItem('dc_token')) {
                        getDoc('api')
                        clearInterval(interval)
                    }
                }, 500);
            // }
        }
    }, [])

    const getDoc = (namespace) => {
        axios.get(`/${namespace}/document/${routerParams.type}/${routerParams.id}/${routerParams.feature ? routerParams.feature + "/" : ""}`, Config()).then(res => {
            if (res.data === '') {
                setErr('Failed to load Document.')
            }
            else if (routerParams.type === 'connect') {
                setUrl(res.data)
            } else {
                setUrl(getPdfUrl(res.data))
            }
        }).catch(err => {
            if (err.response.status === 401) {
                setErr('You do not have permission to view this document')
            } else {
                setErr('Failed to load Document.')
            }
        })

        // axios.get(`/${namespace}/document/fast/${routerParams.type}/${routerParams.id}/`, Config()).then(res => {
        //     console.log(res.data)
        //     // if (res.data === '') {
        //     //     setErr('Failed to load Document.')
        //     // }
        //     // else if (routerParams.type === 'connect') {
        //     //     setUrl(res.data)
        //     // } else {
        //     //     setUrl(getPdfUrl(res.data))
        //     // }
        // }).catch(err => {
        //     console.log(err.response)
        //     // if (err.response.status === 401) {
        //     //     setErr('You do not have permission to view this document')
        //     // } else {
        //     //     setErr('Failed to load Document.')
        //     // }
        // })
    }
    useEffect(() => {
        const nav = document.getElementsByClassName('navigation')
        if (nav.length > 0) {
            try {
                console.log(document.getElementsByTagName('body')[0].offsetHeight)
                nav[0].style.height = document.getElementsByTagName('body')[0].offsetHeight - 24 + 'px'
            } catch {
                nav[0].height = '100vh'
            }
        }
    })



    return <div className={"document" + (routerParams.type !== 'connect' || !url ? ' h' : '')}>
        {/* routerParams.type === 'connect' ? <div dangerouslySetInnerHTML={{ __html: url }}></div> :  */}
        {!url && !err ? <Spinner /> : url ?
            routerParams.type === 'connect' ? <div dangerouslySetInnerHTML={{ __html: url.replaceAll('&nbsp;', '\u00A0') }}></div> :
                <iframe height="20%" width="20%" src={url + (searchParams.get('fullpage') === 'true' && routerParams.type !== 'connect' ? '' : "#toolbar=0")} allowFullScreen></iframe> :
            <p>{err}</p>}
    </div>
}


export default Document;