import React, { useEffect, useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import isNullorEmpty from '../Utilities/isNullorEmpty';
import Config from '../Utilities/Config';
import {formatDateTime,formatDate} from '../Utilities/FormatDate';
import  dynamicSort from '../Utilities/SortTable';
import { Outlet, Link } from "react-router-dom";
// import vars from '../../../dc/vars.py';

const SelectBlackList = () => {

    const [loading, setLoading] = useState(true)
    const [blackListIpAttempts, setBlackListIpAttempts] = useState(null)
    const [blackLisEmailAttempts, setBlackListEmailAttempts] = useState(null)
    const [error, setError] = useState(null)
    const [sortField, setSortField] = useState("date")



    useEffect(() => {
        console.log("in blacklist");
        // if (window.location.host !== "internal.dolphincare.ai" && window.location.host !== "localhost:3001") {
        //     console.log(window.location.host)
        //     window.location.replace("http://internal.dolphincare.ai/");
        // }
        // else {
        selectBlackListsInfo()
        // }
    }, [])


    const selectBlackListsInfo= ()=>{
        axios.get('/api/internal/selectblacklistipattempts/', Config()).then((res) => {
            setBlackListIpAttempts(res.data)
            console.log("blacklist ip is: ",res.data)
            setLoading(false)
        }).catch(err => {
            console.log(err);
            setError(`Couldn't get black list records for ${err.response.data}`)
        })
        axios.get('/api/internal/selectblacklistemailattempts/', Config()).then((res) => {
            setBlackListEmailAttempts(res.data)
            console.log("blacklist email is: ",res.data)
            setLoading(false)
        }).catch(err => {
            console.log(err);
            setError(`Couldn't get black list records for ${err.response.data}`)
        })
    }

    const removeIpBlakList = (removeIp) => { 
        const trashId= "trash_" + removeIp
        const spinnerId= "spinner_" + removeIp
        document.getElementById(trashId).style.display="none"  
        document.getElementById(spinnerId).style.display="block"  
        axios.post('/api/internal/removeipblacklist/', {ip: removeIp}, Config()).then((res) => {
            console.log("blacklist is: ",res.data)
            selectBlackListsInfo()
            document.getElementById(trashId).style.display="block"  
            document.getElementById(spinnerId).style.display="none" 

        }).catch(err => {
            console.log(err);
            setError(`Couldn't get black list records for ${err.response.data}`)
            document.getElementById(spinnerId).style.display="none"
        })       
    }

    const removeEmailBlakList = (removeEmail) => { 
        const trashId= "trash_" + removeEmail
        const spinnerId= "spinner_" + removeEmail
        document.getElementById(trashId).style.display="none"  
        document.getElementById(spinnerId).style.display="block"       
        axios.post('/api/internal/removeemailblacklist/', {email:removeEmail}, Config()).then((res) => {
            console.log("blacklist is: ",res.data)
            selectBlackListsInfo()
            document.getElementById(trashId).style.display="block"  
            document.getElementById(spinnerId).style.display="none" 

        }).catch(err => {
            console.log(err);
            setError(`Couldn't get black list records for ${err.response.data}`)
            document.getElementById(spinnerId).style.display="none"
        })       
    }

    return <div className='blacklistInfo'>
    {loading ? <Spinner animation='border' variant='dark' /> :
        error ? <div>{error}</div> :
        <div>
        <Form className='wider'>
        {/* <h2>BlackList Information</h2> */}
        <br></br>
        {!isNullorEmpty(blackListIpAttempts) &&
        <>
                    <h4 className='tableHeader'>BlackList Ip</h4>                
                    <table>
                        <tr>
                            <th onClick={()=>setSortField("date")}>Date                       
                                <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-arrow-down filterIcon" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                                </svg>
                            </th>
                            <th onClick={()=>setSortField("expires")}>Expires
                                <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-arrow-down filterIcon" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                                </svg>
                            </th>
                            <th onClick={()=>setSortField("ip")}>Ip
                                <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-arrow-down filterIcon" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                                </svg>
                            </th>
                            <th onClick={()=>setSortField("reason")}>Reason
                                <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-arrow-down filterIcon" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                                </svg>
                            </th>
                            <th>
                            </th>
                        </tr>
                    {blackListIpAttempts.sort(dynamicSort(sortField)).map(bi =>    
                     <tr k={bi.id}>                                       
                        <td>{formatDateTime(bi.date)}</td>
                        <td>{bi.expires}</td>
                        <td>{bi.ip}</td>
                        <td>{bi.reason}</td>
                        <td onClick={()=>removeIpBlakList(bi.ip)}>
                            <Spinner animation='border' variant='dark' className="deleteSpinner" id={`spinner_${bi.ip}`}/>
                            <svg xmlns="http://www.w3.org/2000/svg"  className="bi bi-trash3-fill deleteIcon" id={`trash_${bi.ip}`} viewBox="0 0 16 16">
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
                            </svg>
                        </td>
                    </tr>
                     )} 
                     </table> 
                     </>
                    }
  
                    <br></br>
                    {!isNullorEmpty(blackLisEmailAttempts) &&
                    <>
                    <h4 className='tableHeader'>BlackList Email</h4>                
                    <table>
                        <tr>
                            <th onClick={()=>setSortField("date")}>Date                       
                                <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-arrow-down filterIcon" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                                </svg>
                            </th>
                            <th onClick={()=>setSortField("expires")}>Expires
                                <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-arrow-down filterIcon" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                                </svg>
                            </th>
                            <th onClick={()=>setSortField("email")}>Email
                                <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-arrow-down filterIcon" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                                </svg>
                            </th>
                            <th onClick={()=>setSortField("reason")}>Reason
                                <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-arrow-down filterIcon" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                                </svg>
                            </th>
                            <th>
                            </th>
                        </tr>
                    {blackLisEmailAttempts.sort(dynamicSort(sortField)).map(be =>    
                     <tr key={be.id}>                                       
                        <td>{formatDateTime(be.date)}</td>
                        <td>{be.expires}</td>
                        <td>{be.email}</td>
                        <td>{be.reason}</td>
                        <td onClick={()=>removeEmailBlakList(be.email)}>
                            <Spinner animation='border' variant='dark' className="deleteSpinner" id={`spinner_${be.email}`}/>
                            <svg xmlns="http://www.w3.org/2000/svg"  className="bi bi-trash3-fill deleteIcon" id={`trash_${be.email}`} viewBox="0 0 16 16">
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
                            </svg>
                        </td>
                    </tr>
                     )} 
                     </table> 
                </>
                }                
                </Form>
                </div>
    }
        </div>

}

export default SelectBlackList