



export const waitCursor = () => {
    document.getElementsByTagName('html')[0].style.cursor = 'wait';
}

export const defaultCursor = () => {
    document.getElementsByTagName('html')[0].style.cursor = 'default';
}



