import isNullorEmpty from './isNullorEmpty';

export const formatDate = (date, hideYear = false) => {
    try {
        if (isNullorEmpty(date)) {
            return date;
        }
        date = new Date(date);
        //check if date's nothing
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    } catch { return date; }
}

export const formatStringDate = (date, hideYear = false) => {
    try {
        if (isNullorEmpty(date)) {
            return date;
        }
        date = new Date(date.replace(/-/g, '/'));
        //check if date's nothing
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    } catch { return date; }
}
export const formatDateTime = date => {
    try {
        if (isNullorEmpty(date)) {
            return date;
        }
        date = new Date(date);
        //check if date's nothing
        let hours = date.getHours();
        let ampm = hours > 11 ? 'PM' : 'AM';
        hours = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${hours}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()} ${ampm}`;
    } catch { return date; }
}