import axios from "axios";
import React, { useState, useEffect, Fragment } from "react";
import Config from "../Utilities/Config";
import { Form, Button, Spinner } from 'react-bootstrap';
import dynamicSort from '../Utilities/SortTable';
import { formatDateTime } from '../Utilities/FormatDate';

const ReleaseNotes = () => {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [notes, setNotes] = useState(null)
    const [currentNote, setCurrentNote] = useState('')

    useEffect(() => {
        getNotes()
    }, [])

    const addNote = () => {    
        setLoading(true) 
        axios.post(`/api/internal/releasenotes/`, {'notes': currentNote}, Config()).then((res) => {      
            setCurrentNote('')      
            setError('')
            getNotes()
            setLoading(false)             
        }).catch(err => {   
            console.log(err);
            setError(`Couldn't update note: ${err.response.data}`)
            setLoading(false) 
        })
    }

    const getNotes = () => { 
        axios.get(`/api/internal/releasenotes/`, Config()).then((res) => {  
            setError('')          
            setNotes(res.data)
            setLoading(false)             
        }).catch(err => {   
            console.log(err);
            setError(`Couldn't get notes: ${err.response.data}`)
            setLoading(false) 
        })
    }
   

    return <div className='usersInfo'>
    {loading ? <Spinner animation='border' variant='dark' /> :
        error ? <div>{error}</div> :
            <div className="release">
                <Form className='wider'>
                    <h2>Release Notes</h2>
                    <Form.Control as="textarea" rows={3} 
                    placeholder="Enter new release note"
                    value={currentNote}
                    onChange={(e) => setCurrentNote(e.target.value)} />
                    <Button onClick={() => addNote()}>ADD</Button>
                    <h6>All Release Notes:</h6>
                    {notes.length > 0 && <Fragment>                        
                        <table>
                            <tbody>
                                <tr>
                                    <th>Date</th>                               
                                    <th>Note</th>
                                </tr>
                                {notes && notes.sort(dynamicSort('-date_added')).map(n =>
                                    <tr key={n.id}>
                                        <td>{formatDateTime(n.date_added)}</td>
                                        <td>{n.notes}</td>                            
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </Fragment>}
                </Form>
            </div>
}
</div>

}


export default ReleaseNotes