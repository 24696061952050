import React, { useEffect, useState, Fragment } from 'react';
import CustomModal from '../Utilities/CustomModal';
import { Form, FormGroup, Row, Col } from 'react-bootstrap';
import {capitalize} from 'lodash';
import axios from 'axios';

const NewGroup = (props) => {

    const stringFields = ['name', 'group_code', 'frontend_username', 'frontend_password', 'secondary_username', 'secondary_password', 'backup_username', 
    'backup_password']
    const getInitialGroupObj = () => {
        let groupObj = {}
        stringFields.map(f => groupObj[f] = "")
        groupObj.active = 1;
        groupObj.billing_status = "Registered";
        groupObj.billing_price = 250;
        groupObj.has_backend = 0;
        groupObj.report_tab_type = 'P';
        groupObj.urlid = null;
        groupObj.is_for_accuracy = 1;
        groupObj.has_connect = 0;
        return groupObj
    }
    const [newGroup, setNewGroup] = useState(getInitialGroupObj())
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [updatedFields, setUpdatedFields] = useState([])

    const changeGroupInput = (field, value) => {
        let groupCopy = {...newGroup} 
        if(field === "billing_status"){
            groupCopy['active'] = (value == "Registered" || value == "Trialing" || value == "Paying") ? 1 : 0
        }
        else if (['frontend', 'secondary','backup'].some(s => field.includes(s)) && !updatedFields.includes(field)){
            let updatedFieldsCopy = [...updatedFields]
            updatedFieldsCopy.push(field)
            setUpdatedFields(updatedFieldsCopy)
        }
        groupCopy[field] = value
        setNewGroup(groupCopy) 
    }

    const addGroup = () => {
        setLoading(true)
        let objToSave = {...newGroup}
        objToSave.group_code = objToSave.group_code.toLowerCase()
        objToSave['updated_fields'] = updatedFields
        axios.post(`/api/internal/addgroup/`,objToSave).then((res) => {
            props.getGroups()
            setLoading(false)
            props.setShowPopup(false)
        }).catch(error => {
            console.log(error);
            let err = error.response
            if(err.status === 409 && err.data){
                let errorsList = [];
                for (let [key, value] of Object.entries(err.data)){
                    errorsList.push(value[0] ? key + ": " + value[0] : '')
                };
                setError(errorsList.length > 0 ? errorsList.reduce((a, b) => a + '\u000A' + b) : 'Error adding group');
            }
            else{
                setError(`Error adding group: ${error.response.data}`)
            } 
            setLoading(false)
        })
    }


    return <CustomModal height="90%" title="Add Group" closeModal={() => {props.setShowPopup(false); setUpdatedFields([])}} loading={loading}
    onSave={() => addGroup()}
    >       
        <div className="newgroup">
        {error && <div className='error'>{error}</div>}
        <Row>
        <Form.Group>
            <Form.Control id="name" placeholder="Group Name" value={newGroup.name} onChange={(e) => changeGroupInput('name', e.target.value)} />
            <Form.Control id="group_code" placeholder="Group_code" value={newGroup.group_code} onChange={(e) => changeGroupInput('group_code', e.target.value)} />
            </Form.Group>
        </Row>
            
            {['frontend', 'secondary', 'backup'].map(field => <>
                <Form.Control id={field + "-username"} placeholder={`${field === "frontend" ? "" : capitalize(field)} Username`} value={newGroup[field + "_username"]} onChange={(e) => changeGroupInput(field + "_username", e.target.value)} />
                <Form.Control id={field + "-password"} placeholder={`${field === "frontend" ? "" : capitalize(field)} Password`} value={newGroup[field + "_password"]} onChange={(e) => changeGroupInput(field + "_password", e.target.value)} />
            </>)}
            <Form.Control id="urlid" placeholder="Url id" value={newGroup.urlid} onChange={(e) => changeGroupInput('urlid', e.target.value)} />
            <label for="newgroupdd"><b>Billing Status</b></label>
                <div className="group-drop">
                    <Form.Control as="select" name='newgroupdd'
                        value={newGroup.billing_status}
                        onChange={(e) => changeGroupInput('billing_status', e.target.value)}>
                        {props.billingStatuses.map(s => <option key={s} value={s}>{s}</option>)}
                    </Form.Control>
                </div>
                {newGroup.billing_status !== "Paying" && <><label for="newgroupprice-dd"><b>Billing Price</b></label>
                <div>
                    <Form.Control as="select" name='newgroupprice-dd'
                        value={newGroup.billing_price}
                        onChange={(e) => changeGroupInput('billing_price', e.target.value)}>
                        {props.billingPrices.map(p => <option key={p} value={p}>${p}.00</option>)}
                    </Form.Control>
                </div></>}
                <label for="newgroupretporttab"><b>Report Tab Type</b></label>
                <div className="group-drop">
                    <Form.Control as="select" name='newgroupretporttab'
                        value={newGroup.billing_status}
                        onChange={(e) => changeGroupInput('billing_status', e.target.value)}>
                        {['P','C'].map(s => <option key={s} value={s}>{s}</option>)}
                    </Form.Control>
                </div>

            <Form.Check type='checkbox' checked={newGroup.active == 1} label="Active" onChange={() => changeGroupInput('active', newGroup.active == 1 ? 0 : 1)} />
            <Form.Check type='checkbox' checked={newGroup.has_backend == 1} label="Has data relay" onChange={() => changeGroupInput('has_backend', newGroup.has_backend == 1 ? 0 : 1)} />
            <Form.Check type='checkbox' checked={newGroup.has_connect == 1} label="Has connect" onChange={() => changeGroupInput('has_connect', newGroup.has_connect == 1 ? 0 : 1)} />
            <Form.Check type='checkbox' checked={newGroup.is_for_accuracy == 1} label="Has accuracy report" onChange={() => changeGroupInput('is_for_accuracy', newGroup.is_for_accuracy == 1 ? 0 : 1)} />
        </div>
    </CustomModal>
}

export default NewGroup