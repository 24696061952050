import React, { useEffect, useState, Fragment } from "react";
import { Form, Row, Col, Button, Tab, Tabs } from 'react-bootstrap';
import Spinner from "../../Utilities/Spinner";
import CustomModal from "../../Utilities/CustomModal";

const FacilitiesForm = (props) => { 

    const updateFacility = (event, id, all=false) => {
        let facilitiesCopy = all ? [] : [...props.selectedFacilities]
        if(event.target.checked){
            if(all){
                props.facilities.forEach(f => facilitiesCopy.push(f.facilityid))
            }
            else{
                facilitiesCopy.push(id) 
            }
        }
        else if(!event.target.checked && !all){
            facilitiesCopy = facilitiesCopy.filter(f => f !== id)
        }
        props.setSelectedFacilities(facilitiesCopy)
    }
    
    return <div className="billing-facilities client-user-facilities">
        <CustomModal maxHeight='95%' height="95%" closeModal={() => {props.setShowFacilityForm(false); props.setSelectedFacilities([])}} next={() => {props.setShowFacilityForm(false); props.setShowPaymentForm(true)}} title='Add Subscription' saveBtnTxt='next'
            disableSave={props.selectedFacilities.length === 0}>       
                {!props.facilities ? <Spinner /> : <>  
                    <Tabs activeKey="facilities" fill>
                        <Tab eventKey="facilities" title={<span><svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.37492 14.0417H2.16659V4.03503C2.16661 3.78577 2.24507 3.54283 2.39085 3.34064C2.53663 3.13845 2.74234 2.98726 2.97884 2.90849L9.31217 0.797909C9.49066 0.738461 9.68072 0.72227 9.86669 0.75067C10.0527 0.779071 10.2292 0.85125 10.3818 0.961262C10.5344 1.07127 10.6587 1.21597 10.7445 1.38343C10.8302 1.55089 10.8749 1.73632 10.8749 1.92445V14.0417H11.6666V6.90878C11.6666 6.85024 11.6796 6.79244 11.7047 6.73954C11.7298 6.68664 11.7663 6.63995 11.8115 6.60285C11.8568 6.56574 11.9098 6.53915 11.9666 6.52497C12.0234 6.5108 12.0826 6.50939 12.14 6.52087L13.8785 6.8692C14.1476 6.92299 14.3898 7.06832 14.5638 7.28047C14.7379 7.49263 14.8331 7.75853 14.8333 8.03295V14.0417H15.6249C15.8349 14.0417 16.0362 14.1251 16.1847 14.2736C16.3332 14.422 16.4166 14.6234 16.4166 14.8334C16.4166 15.0433 16.3332 15.2447 16.1847 15.3932C16.0362 15.5416 15.8349 15.625 15.6249 15.625H1.37492C1.16496 15.625 0.963592 15.5416 0.815126 15.3932C0.666659 15.2447 0.583252 15.0433 0.583252 14.8334C0.583252 14.6234 0.666659 14.422 0.815126 14.2736C0.963592 14.1251 1.16496 14.0417 1.37492 14.0417Z" fill="white" />
                                </svg> Facilites</span>}>
                            <Form.Label className="form-label" >
                            Which Facilities will be using the app?
                            </Form.Label>
                            {/* <p className="facilities-desc" >Which Facilities will be using the app?</p> */}
                            <div className="facility-list">
                                <Form.Check custom className="all-none" type='checkbox' checked={props.facilities.every(fac => props.selectedFacilities.includes(fac.facilityid))} onChange={(e) => updateFacility(e, props.facilities, true)} label="All/None" id="all-none" />
                                <div className="facility-columns">
                                    {props.facilities.map(f => <Form.Check custom type='checkbox' label={f.facility_name} key={f.facilityid} id={f.facilityid} checked={props.selectedFacilities.includes(f.facilityid)} onChange={(e) => updateFacility(e, f.facilityid)} />)}
                                </div>
                            </div>
                        </Tab>
                        <Tab title={<span><svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="billingIcon">
                            <path d="M15.6666 3.66659H2.33329V1.99992H15.6666M15.6666 11.9999H2.33329V6.99992H15.6666M15.6666 0.333252H2.33329C1.40829 0.333252 0.666626 1.07492 0.666626 1.99992V11.9999C0.666626 12.4419 0.842221 12.8659 1.15478 13.1784C1.46734 13.491 1.89127 13.6666 2.33329 13.6666H15.6666C16.1087 13.6666 16.5326 13.491 16.8451 13.1784C17.1577 12.8659 17.3333 12.4419 17.3333 11.9999V1.99992C17.3333 1.55789 17.1577 1.13397 16.8451 0.821407C16.5326 0.508847 16.1087 0.333252 15.6666 0.333252Z" fill="#6c757d" />
                        </svg>Billing Details</span>} disabled></Tab>
                    </Tabs>                      
                   
                </>}
                {/* {!isNullorEmpty(errMsg) && <div className='error'>{errMsg}</div>} */}     
        </CustomModal>
    </div>
}


export default FacilitiesForm