import React, { useEffect, useState, Fragment } from "react";
import { Title } from "../SideBar";

const ProcessingPage = props => {


    return <div className="processing-page">
        <Title/>
        <div className="tablesContainer">
            <div className="billingTablecont">
            <div className="header-processing">Thank you for your purchase!</div>
            <div>We are processing your subscription.</div>
            <div>We will let you know when your order is processed.</div>
            <div>We look forward to streamlining your MDS assessment process.</div>
            </div> 
        </div>

    </div>

}
export default ProcessingPage ;