const dynamicSort = (property) => {
    var sortOrder = 1;
    var result;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return (a, b) => {
        if (property === 'count' || property === 'total') {
            result = (parseInt(a[property]) < parseInt(b[property])) ? -1 : (parseInt(a[property]) > parseInt(b[property])) ? 1 : 0;
        }
        else {
            result = (formatFieldForSort(a[property]) < formatFieldForSort(b[property])) ? -1 : (formatFieldForSort(a[property]) > formatFieldForSort(b[property])) ? 1 : 0;
        }
        return result * sortOrder;
    }
}
export default dynamicSort;


const formatFieldForSort = val => {
    val = val ? val : ''
    try {
        return val.toLowerCase().trim()
    }
    catch {
        return val
    }
}