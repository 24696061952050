import React from "react";
import LogoSpinner from '../Assets/LogoSpinner.gif'
import SmallSpinner from '../Assets/SmallSpinner.gif'
import TinySpinner from '../Assets/TinySpinner.gif'


const Spinner = props => <div className="spinner">
    {props.small ?
        <img src={SmallSpinner} alt='spinner' width={props.size ? props.size : 100} height={props.size ? props.size : 100} />
        : props.tiny ?
            <img src={TinySpinner} alt='spinner' width={props.size ? props.size : 100} height={props.size ? props.size : 100} />
            : <img src={LogoSpinner} alt='spinner' width={props.size ? props.size : 100} height={props.size ? props.size : 100} />}
</div>


export default Spinner;