import React, { Fragment, useEffect, useState } from 'react';
import dolphinCareLogo from '../Assets/Logo.png';
import LoginGif from '../Assets/DolphinCareLoginScreen.gif';
import axios from 'axios';
import Spinner from '../Utilities/Spinner';
import LostPassword from '../Assets/LostPassword.png'


const SignIn = (props) => {
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(true)
    const [page, setPage] = useState('login')
    const [values, setValues] = useState({ email: '', password: '', verifycode: '', resetcode: '', newpass1: '', newpass2: '' })

    const setValue = (key, value) => {
        setValues({ ...values, [key]: value })
    }


    const pages = {
        login: {},
        verify: {
            title: 'VERIFICATION CODE',
            paragraph: 'A verification code has been sent to your email. The code is valid for 10 minutes.'
        },
        enteremail: {
            title: 'FORGOT YOUR PASSWORD?',
            paragraph: 'Enter the email address registered with DolphinCare and we will send you a password reset code.'
        },
        resetcode: {
            title: 'PASSWORD RESET CODE',
            paragraph: 'A code has been sent to your email. Enter the code in the box below to begin the password reset process. The code is valid for 15 minutes.'
        },
        newpass: {
            title: 'SET NEW PASSWORD',
            paragraph: 'Reset your password below. Password must be atleast 8 characters.'
        },
        congrats: {
            title: 'CONGRATS!',
            paragraph: 'You have successfully changed your password. You can now log in with your new password.'
        },
    }



    const hideClass = {
        remove: id => document.getElementById(id).classList.remove('dc_hide'),
        add: id => document.getElementById(id).classList.add('dc_hide'),
        toggle: id => document.getElementById(id).classList.toggle('dc_hide')
    }

    const Button = props => <input onClick={props.onClick} id={props.id} type="button" value={props.value} />


    const Eye = props => <div className="eye" onClick={() => {
        document.getElementById(props.txtId).type = document.getElementById(`dc_astext${props.i}`).classList.contains('dc_hide') ? 'password' : 'text';
        hideClass.toggle('dc_astext' + props.i)
        hideClass.toggle('dc_aspassword' + props.i)
    }}>
        <svg id={`dc_astext${props.i}`} fill="currentColor" viewBox="0 0 16 16"><path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"></path><path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"></path></svg>
        <svg id={`dc_aspassword${props.i}`} className='dc_hide' fill="currentColor" viewBox="0 0 16 16"><path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" /><path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" /><path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" /></svg>
    </div>

    const TextBox = props => <input id={props.id} key={props.id}
        onKeyUp={e => { if (e.key === 'Enter') { document.getElementById(props.btnId).click() } }}
        onChange={e => setValue(props.field, e.target.value)}
        placeholder={props.placeholder}
        type={props.type ? props.type : 'text'}
        value={values[props.field]} />

    const BackArrow = () => <svg className='backarrow' width="21" height="19" viewBox="0 0 21 19" fill="none" onClick={goBackPage}>
        <path d="M19.5417 8.20834H4.22258L8.91133 2.57668C9.13058 2.3129 9.23606 1.97283 9.20457 1.63128C9.17308 1.28972 9.0072 0.974672 8.74342 0.755427C8.47964 0.536181 8.13956 0.430701 7.79801 0.462192C7.45646 0.493682 7.14141 0.659564 6.92216 0.923344L0.463831 8.67334C0.420381 8.73499 0.381525 8.79975 0.347581 8.86709C0.347581 8.93168 0.347582 8.97043 0.257165 9.03501C0.198618 9.18311 0.167964 9.34076 0.166748 9.50001C0.167964 9.65926 0.198618 9.81691 0.257165 9.96501C0.257165 10.0296 0.257165 10.0683 0.347581 10.1329C0.381525 10.2003 0.420381 10.265 0.463831 10.3267L6.92216 18.0767C7.04361 18.2225 7.19569 18.3397 7.36759 18.4201C7.53949 18.5005 7.72699 18.542 7.91675 18.5417C8.21855 18.5423 8.51103 18.4372 8.74342 18.2446C8.87421 18.1362 8.98232 18.003 9.06157 17.8527C9.14081 17.7024 9.18964 17.538 9.20523 17.3688C9.22083 17.1996 9.2029 17.029 9.15247 16.8668C9.10204 16.7046 9.02009 16.5539 8.91133 16.4233L4.22258 10.7917H19.5417C19.8843 10.7917 20.2129 10.6556 20.4551 10.4134C20.6973 10.1711 20.8334 9.84258 20.8334 9.50001C20.8334 9.15744 20.6973 8.8289 20.4551 8.58666C20.2129 8.34443 19.8843 8.20834 19.5417 8.20834Z" fill="#545454" />
    </svg>

    const defaultErrorMessage = 'OOPS! Something went wrong. Contact support if error persists.'

    const signIn = () => {
        setLoading(true)
        axios.post('/api/login/', { email: values.email, password: values.password }, { headers: { 'X-CSRFToken': window.localStorage.getItem('dc_browser') } }).then(res => {
            if (res.status === 205) {
                setLoading(false)
                setPage('verify')
            }
            else {
                window.localStorage.setItem('dc_token', res.data);
                props.checkSession()
            }
        }).catch(err => {
            setLoading(false)
            if (err.response.status === 423) {
                setAlert('Too many invalid login attempts were made. Contact support to unlock your account.')
            } else if (err.response.status === 403) {
                setAlert(<>Your free trial has ended. Contact your admin to upgrade your account.</>)
            }  else if (err.response.status === 401) {
                setAlert('Incorrect email or password')
            } else {
                setAlert(defaultErrorMessage)
            }
        })
    }

    useEffect(() => {
        setAlert('')
    }, [page])

    const forgotPassword = () => {
        setPage('enteremail')
    }

    const verify = () => {
        setLoading(true)
        axios.post('/api/registerbrowser/', { email: values.email, password: values.password, code: values.verifycode }).then(res => {
            console.log(res)
            try {
                res = JSON.parse(res.data)
                window.localStorage.setItem('dc_token', res.csrf);
                window.localStorage.setItem('dc_browser', res.code);
                props.checkSession()
                // setLoading(false)
            } catch (ex) {
                console.log(ex)
                setLoading(false)
            }
        }).catch(err => {
            setLoading(false)
            if (err.response.status === 423) {
                setAlert('You have tried too many invalid codes. Contact support to unlock you account.')
            } else if (err.response.status === 401) {
                setAlert('Invalid code')
            } else {
                setAlert(defaultErrorMessage)
            }
        })
    }

    const enterEmail = () => {
        setLoading(true)
        axios.post('/api/forgotpassword/', { email: values.email }).then(res => {
            setLoading(false)
            setPage('resetcode')
        }).catch(err => {
            setLoading(false)
            if (err.response.status === 411) {
                setAlert('Failed to send email.')
            } else {
                setAlert(defaultErrorMessage)
            }
        })
    }

    const resetCode = () => {
        setLoading(true)
        axios.post('/api/verify/', { email: values.email, code: values.resetcode }).then(res => {
            setLoading(false)
            setPage('newpass')
        }).catch(err => {
            setLoading(false)
            if (err.response.status === 411) {
                setAlert('The email address submitted is not registered with DolphinCare.')
            } else if (err.response.status === 423) {
                setAlert('You have tried too many invalid codes. Contact support to unlock you account.')
            } else if (err.response.status === 401) {
                setAlert('Invalid code.')
            } else {
                setAlert(defaultErrorMessage)
            }
        })
    }

    const newPass = () => {
        if (values.newpass1 !== values.newpass2) {
            setAlert('New passwords do not match.')
        } else if (values.newpass1.length < 8) {
            setAlert('Password must be atleast 8 characters.')
        } else {
            setLoading(true)
            axios.post('/api/changepassword/', { email: values.email, code: values.resetcode, password: values.newpass1 }).then(res => {
                setLoading(false)
                setPage('congrats')
            }).catch(err => {
                setLoading(false)
                if (err.response.status === 406) {
                    setAlert('Please enter a password that you have not used in the past.')
                } else if (err.response.status === 401) {
                    setAlert('Invalid Code.')
                } else {
                    setAlert(defaultErrorMessage)
                }
            })
        }
    }

    const goBackPage = () => {
        setPage(page === 'resetcode' ? 'enteremail' : page === 'enteremail' ? 'login' : page)
    }


    return <div className={page === 'login' ? 'login' : 'login pass'}>
        {page === 'login' && <div className='loginback'><img src={LoginGif} /></div>}
        <div className='loginframe'>
            <div className='cont'>

                {loading ? <Spinner /> : <Fragment>

                    {page === 'resetcode' || page === 'enteremail' && <BackArrow />}
                    {page !== 'login' && <div className='passimg'><img src={LostPassword} /></div>}
                    <div className='changepass'>
                        <img className='logo' src={dolphinCareLogo} />
                        {pages[page].title && <h5>{pages[page].title}</h5>}
                        {pages[page].paragraph && <p>{pages[page].paragraph}</p>}
                        {alert && page !== 'login' && <div className='dc_alert'>{alert}</div>}

                        {page === 'login' && <Fragment>
                            {/* <TextBox id='dc_email' placeholder='Email' btnId='dc_signin' field='email'/> */}
                            {TextBox({ id: 'dc_email', placeholder: 'Email', btnId: 'dc_signin', field: 'email' })}
                            <div>
                                {TextBox({ id: 'dc_password', placeholder: 'Password', btnId: 'dc_signin', field: 'password', type: 'password' })}
                                <Eye txtId='dc_password' i='1' />
                            </div>
                            {/* <TextBox id='dc_password' placeholder='Password' btnId='dc_signin' type='password' field='password'/> */}
                            {alert && <div className='dc_alert'>{alert}</div>}
                            <a onClick={forgotPassword} id='dc_forgotlink' className='forgotlink'>Forgot Password?</a>
                            {/* {Button({ id:'dc_signin', value:'SIGN IN', onClick:signIn})} */}
                            <Button id='dc_signin' value='SIGN IN' onClick={signIn} />
                        </Fragment>}

                        {page === 'verify' && <Fragment>
                            {/* <TextBox id='dc_code' placeholder='Verification Code' btnId='dc_btnverify' field='verifycode' /> */}
                            {TextBox({ id: 'dc_code', placeholder: 'Verification Code', btnId: 'dc_btnverify', field: 'verifycode' })}
                            <Button id='dc_btnverify' value='SUBMIT' onClick={verify} />
                        </Fragment>}

                        {page === 'enteremail' && <Fragment>
                            {/* <TextBox id='dc_regemail' placeholder='Email' btnId='dc_btnregemail' field='email' /> */}
                            {TextBox({ id: 'dc_regemail', placeholder: 'Email', btnId: 'dc_btnregemail', field: 'email' })}
                            <Button id='dc_btnregemail' value='SUBMIT' onClick={enterEmail} />
                        </Fragment>}

                        {page === 'resetcode' && <Fragment>
                            {/* <TextBox id='dc_vercode' placeholder='Password Reset Code' btnId='dc_btncode' field='resetcode' /> */}
                            {TextBox({ id: 'dc_vercode', placeholder: 'Password Reset Code', btnId: 'dc_btncode', field: 'resetcode' })}
                            <Button id='dc_btncode' value='SUBMIT' onClick={resetCode} />
                        </Fragment>}

                        {page === 'newpass' && <Fragment>
                            <div>
                                {/* <TextBox id='dc_password1' placeholder='New Password' btnId='dc_btnnewpass' type='password' field='newpass1' /> */}
                                {TextBox({ id: 'dc_password1', placeholder: 'New Password', btnId: 'dc_btnnewpass', type: 'password', field: 'newpass1' })}
                                <Eye txtId='dc_password1' i='1' />
                            </div>
                            <div>
                                {/* <TextBox id='dc_password2' placeholder='Confirm New Password' btnId='dc_btnnewpass' type='password' field='newpass2' /> */}
                                {TextBox({ id: 'dc_password2', placeholder: 'Confirm New Password', btnId: 'dc_btnnewpass', type: 'password', field: 'newpass2' })}
                                <Eye txtId='dc_password2' i='2' />
                            </div>
                            <Button id='dc_btnnewpass' value='SUBMIT' onClick={newPass} />
                        </Fragment>}

                        {page === 'congrats' && <Fragment>
                            <Button id='dc_rtrnlogin' value='RETURN TO LOGIN' onClick={() => setPage('login')} />
                        </Fragment>}

                    </div>
                </Fragment>}
            </div>
        </div>
    </div>
}

export default SignIn