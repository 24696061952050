import axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import Config from "../../Utilities/Config";
import { Title } from "../SideBar";
import TableScrollBar from 'react-table-scrollbar';
import { startCase, capitalize } from "lodash";
import Spinner from "../../Utilities/Spinner";
import { useDispatch, useSelector } from 'react-redux';
import ClientMainBilling from "./ClientMainBilling";
import isNullorEmpty from "../../Utilities/isNullorEmpty";
import PendingFacilities from "./SubscribePendingFac";
import CustomModal from '../../Utilities/CustomModal';
import { Form, Row, Col, Button } from 'react-bootstrap';
import Payment from "./PaymentForm";
import Validation from "../../Utilities/Validation";
import { loadAllFacilities } from "../../redux/globalfunctions";
import { formatDate } from "../../Utilities/FormatDate";
import ProcessingPage from "./ProcessingPage";

const ClientBillingFacility = props => {

    const [showBilling, setShowBilling] = useState(true)
    const [error, setError] = useState(null)
    const facilityDetails = props.facility;
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const facilities = useSelector(state => state.globalState.allFacilities)
    const currentGroup = useSelector(state => state.globalState.currentGroup)
    const [billingDetails, setBillingDtails] = useState(null)
    const [billingPerGroup, setBillingPerGroup] = useState(true)
    const [billingEmail, setBillingEmail] = useState("")
    const [showEmailPopup, setShowEmailPopup] = useState(false)
    const [emailError, setEmailError] = useState("")
    const [showPaymentForm, setShowPaymentForm] = useState(false) 
    const [saving, setSaving] = useState(false)
    const [ranGetBilling, setRanGetBilling] = useState(false)
    const user = useSelector(state => state.globalState.user)
    const [showProcessingPage, setShowProcessingPage] = useState(false)


    useEffect(() => {
        //console.log("facilityDetails: ",facilityDetails)
        if(facilities && !ranGetBilling){
            var group;
            if (facilityDetails !== undefined) {
                group = facilityDetails.group
                setBillingPerGroup(false)
            }
            else
                group = facilities[0].group
            getPaymentDetails(); 
        }              
    }, [facilities, showBilling])


    const getPaymentDetails = () => {
        console.log("in payment details")
        axios.get(`/api/paymentdetails/${currentGroup}/${facilityDetails !== undefined ? facilityDetails.id : null}/`, Config()).then(res => {
            // let billing_res = {}
            // for (const [key, value] of Object.entries(res.data)) {
            //     billing_res[key] = value[0]
            // }
            if(res.data === 'billing4'){
                setShowProcessingPage(true)
                setLoading(false)
            }
            setBillingDtails(res.data)
            setBillingEmail(res.data["Shopper Email"])
            // console.log("payment details: ", res.data)
            dispatch(loadAllFacilities())
            if (facilities)
                setLoading(false)
            setRanGetBilling(true)
        }).catch(err => {
            setLoading(false)
            console.log(err.response)
            setError(err.response.status === 409 ? "Your subscription is no longer active. Contact support@dolphincare.ai for assistance" : "Error loading billing data. Contact support if error persists.")    
        })
    }

    const saveBillingInfo = () => {
        setSaving(true)        
        // console.log("payment details: ", billingDetails)
        // console.log("billingEmail: ", billingEmail)
        axios.get(`/api/updatebillingemail/${currentGroup}/${billingDetails["Shopper ID"]}/${billingEmail}/`, Config()).then(res => {
            console.log("update email res:", res.data);
            getPaymentDetails()
            setShowEmailPopup(false)
            setSaving(false)
        }).catch(err => {
            console.log(err)
            // setEmailError(`Error updating billing email. Contact support if error persists.`)
            setEmailError(`Error updating billing email. Contact support if error persists.`)
            setSaving(false)
        })
    }

    const validateEmail = (value) => {
        const err = Validation(value, {required: true, isEmail: true}).replace(/{field}/ig, 'email'.replace(/_/g, ' '))
        setEmailError(capitalize(err))
        return err;
    }

    const changeBillingDetails = (field, value) => {
        let billingDCopy = {...billingDetails}
        billingDCopy[field] = value
        setBillingDtails(billingDCopy)
    }

    const closeBillingPopUp = () => {
        setShowEmailPopup(false)
        setBillingEmail(billingDetails["Shopper Email"])
        //document.getElementById("billingPopUpContainer").style.display = 'none';
    }

    // const openPopup = (text) => {
    //     console.log("clicked")
    //     setPopUpText(text);
    //     setShowEmailPopup(true)
    // }

    const OwnerSvg = () => <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg" className="billingIcon">
        <path d="M2.00004 18.3333C1.54171 18.3333 1.14948 18.1702 0.823374 17.8441C0.496707 17.5174 0.333374 17.1249 0.333374 16.6666V8.33325C0.333374 7.87492 0.496707 7.48242 0.823374 7.15575C1.14948 6.82964 1.54171 6.66658 2.00004 6.66658H2.83337V4.99992C2.83337 3.84714 3.23976 2.86436 4.05254 2.05159C4.86476 1.23936 5.84726 0.833252 7.00004 0.833252C8.15282 0.833252 9.1356 1.23936 9.94837 2.05159C10.7606 2.86436 11.1667 3.84714 11.1667 4.99992V6.66658H12C12.4584 6.66658 12.8509 6.82964 13.1775 7.15575C13.5037 7.48242 13.6667 7.87492 13.6667 8.33325V16.6666C13.6667 17.1249 13.5037 17.5174 13.1775 17.8441C12.8509 18.1702 12.4584 18.3333 12 18.3333H2.00004ZM2.00004 16.6666H12V8.33325H2.00004V16.6666ZM7.00004 14.1666C7.45837 14.1666 7.85087 14.0035 8.17754 13.6774C8.50365 13.3508 8.66671 12.9583 8.66671 12.4999C8.66671 12.0416 8.50365 11.6491 8.17754 11.3224C7.85087 10.9963 7.45837 10.8333 7.00004 10.8333C6.54171 10.8333 6.14949 10.9963 5.82337 11.3224C5.49671 11.6491 5.33337 12.0416 5.33337 12.4999C5.33337 12.9583 5.49671 13.3508 5.82337 13.6774C6.14949 14.0035 6.54171 14.1666 7.00004 14.1666ZM4.50004 6.66658H9.50004V4.99992C9.50004 4.30547 9.25698 3.7152 8.77087 3.22909C8.28476 2.74297 7.69448 2.49992 7.00004 2.49992C6.3056 2.49992 5.71532 2.74297 5.22921 3.22909C4.7431 3.7152 4.50004 4.30547 4.50004 4.99992V6.66658Z" fill="#545454" />
    </svg>

    const CostSvg = () => <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="billingIcon">
        <path d="M14.6667 17.3334H1.33333C1.11232 17.3334 0.900358 17.2456 0.744078 17.0893C0.587797 16.9331 0.5 16.7211 0.5 16.5001V1.50008C0.5 1.27907 0.587797 1.06711 0.744078 0.910826C0.900358 0.754545 1.11232 0.666748 1.33333 0.666748H14.6667C14.8877 0.666748 15.0996 0.754545 15.2559 0.910826C15.4122 1.06711 15.5 1.27907 15.5 1.50008V16.5001C15.5 16.7211 15.4122 16.9331 15.2559 17.0893C15.0996 17.2456 14.8877 17.3334 14.6667 17.3334ZM13.8333 15.6667V2.33341H2.16667V15.6667H13.8333ZM4.66667 6.50008H11.3333V8.16675H4.66667V6.50008ZM4.66667 9.83341H11.3333V11.5001H4.66667V9.83341Z" fill="#545454" />
    </svg>

    const DetailsSvg = () => <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="billingIcon">
        <path d="M9.83333 2.16659H16.5M9.83333 5.49992H14M9.83333 10.4999H16.5M9.83333 13.8333H14M1.5 2.16659C1.5 1.94557 1.5878 1.73361 1.74408 1.57733C1.90036 1.42105 2.11232 1.33325 2.33333 1.33325H5.66667C5.88768 1.33325 6.09964 1.42105 6.25592 1.57733C6.4122 1.73361 6.5 1.94557 6.5 2.16659V5.49992C6.5 5.72093 6.4122 5.93289 6.25592 6.08917C6.09964 6.24545 5.88768 6.33325 5.66667 6.33325H2.33333C2.11232 6.33325 1.90036 6.24545 1.74408 6.08917C1.5878 5.93289 1.5 5.72093 1.5 5.49992V2.16659ZM1.5 10.4999C1.5 10.2789 1.5878 10.0669 1.74408 9.91066C1.90036 9.75438 2.11232 9.66659 2.33333 9.66659H5.66667C5.88768 9.66659 6.09964 9.75438 6.25592 9.91066C6.4122 10.0669 6.5 10.2789 6.5 10.4999V13.8333C6.5 14.0543 6.4122 14.2662 6.25592 14.4225C6.09964 14.5788 5.88768 14.6666 5.66667 14.6666H2.33333C2.11232 14.6666 1.90036 14.5788 1.74408 14.4225C1.5878 14.2662 1.5 14.0543 1.5 13.8333V10.4999Z" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    const VisaSvg = () => <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="billingIcon">
        <path d="M15.6666 3.66659H2.33329V1.99992H15.6666M15.6666 11.9999H2.33329V6.99992H15.6666M15.6666 0.333252H2.33329C1.40829 0.333252 0.666626 1.07492 0.666626 1.99992V11.9999C0.666626 12.4419 0.842221 12.8659 1.15478 13.1784C1.46734 13.491 1.89127 13.6666 2.33329 13.6666H15.6666C16.1087 13.6666 16.5326 13.491 16.8451 13.1784C17.1577 12.8659 17.3333 12.4419 17.3333 11.9999V1.99992C17.3333 1.55789 17.1577 1.13397 16.8451 0.821407C16.5326 0.508847 16.1087 0.333252 15.6666 0.333252Z" fill="#545454" />
    </svg>

    const FacilitySvg = () => <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="facilityIcon">
        <path d="M1.75 21.75H3V5.95C3.00004 5.55643 3.12392 5.17284 3.3541 4.85359C3.58429 4.53434 3.90909 4.29562 4.2825 4.17125L14.2825 0.838752C14.5643 0.744886 14.8644 0.719322 15.1581 0.764165C15.4517 0.809008 15.7305 0.922975 15.9714 1.09668C16.2124 1.27038 16.4087 1.49885 16.544 1.76326C16.6794 2.02767 16.75 2.32046 16.75 2.6175V21.75H18V10.4875C18.0001 10.3951 18.0206 10.3038 18.0602 10.2203C18.0998 10.1367 18.1574 10.063 18.2289 10.0044C18.3004 9.94586 18.384 9.90386 18.4737 9.88148C18.5633 9.8591 18.6569 9.85689 18.7475 9.875L21.4925 10.425C21.9174 10.5099 22.2998 10.7394 22.5746 11.0744C22.8494 11.4094 22.9997 11.8292 23 12.2625V21.75H24.25C24.5815 21.75 24.8995 21.8817 25.1339 22.1161C25.3683 22.3505 25.5 22.6685 25.5 23C25.5 23.3315 25.3683 23.6495 25.1339 23.8839C24.8995 24.1183 24.5815 24.25 24.25 24.25H1.75C1.41848 24.25 1.10054 24.1183 0.866116 23.8839C0.631696 23.6495 0.5 23.3315 0.5 23C0.5 22.6685 0.631696 22.3505 0.866116 22.1161C1.10054 21.8817 1.41848 21.75 1.75 21.75Z" />
    </svg>

    const BackSvg = () => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="backSvg">
        <path d="M6.875 15.3001L0.275 8.70008C0.175 8.60008 0.104 8.49175 0.0619998 8.37508C0.0206665 8.25842 0 8.13342 0 8.00008C0 7.86675 0.0206665 7.74175 0.0619998 7.62508C0.104 7.50842 0.175 7.40008 0.275 7.30008L6.875 0.700082C7.05833 0.516749 7.28733 0.420749 7.562 0.412083C7.83733 0.404083 8.075 0.500082 8.275 0.700082C8.475 0.883416 8.57933 1.11242 8.588 1.38708C8.596 1.66242 8.5 1.90008 8.3 2.10008L3.4 7.00008H14.575C14.8583 7.00008 15.096 7.09575 15.288 7.28708C15.4793 7.47908 15.575 7.71675 15.575 8.00008C15.575 8.28342 15.4793 8.52075 15.288 8.71208C15.096 8.90408 14.8583 9.00008 14.575 9.00008H3.4L8.3 13.9001C8.48333 14.0834 8.57933 14.3167 8.588 14.6001C8.596 14.8834 8.5 15.1167 8.3 15.3001C8.11667 15.5001 7.88333 15.6001 7.6 15.6001C7.31667 15.6001 7.075 15.5001 6.875 15.3001Z" fill="black" />
    </svg>
    const CloseSvg = () => <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.711277 0.711216C0.798363 0.62391 0.901817 0.554642 1.01571 0.50738C1.12961 0.460118 1.25171 0.435791 1.37503 0.435791C1.49834 0.435791 1.62044 0.460118 1.73434 0.50738C1.84824 0.554642 1.95169 0.62391 2.03878 0.711216L7.00003 5.67434L11.9613 0.711216C12.0484 0.624051 12.1519 0.554908 12.2658 0.507735C12.3797 0.460562 12.5018 0.436282 12.625 0.436282C12.7483 0.436282 12.8704 0.460562 12.9842 0.507735C13.0981 0.554908 13.2016 0.624051 13.2888 0.711216C13.3759 0.798381 13.4451 0.901861 13.4923 1.01575C13.5394 1.12963 13.5637 1.2517 13.5637 1.37497C13.5637 1.49824 13.5394 1.6203 13.4923 1.73419C13.4451 1.84807 13.3759 1.95155 13.2888 2.03872L8.32565 6.99997L13.2888 11.9612C13.3759 12.0484 13.4451 12.1519 13.4923 12.2657C13.5394 12.3796 13.5637 12.5017 13.5637 12.625C13.5637 12.7482 13.5394 12.8703 13.4923 12.9842C13.4451 13.0981 13.3759 13.2016 13.2888 13.2887C13.2016 13.3759 13.0981 13.445 12.9842 13.4922C12.8704 13.5394 12.7483 13.5637 12.625 13.5637C12.5018 13.5637 12.3797 13.5394 12.2658 13.4922C12.1519 13.445 12.0484 13.3759 11.9613 13.2887L7.00003 8.32559L2.03878 13.2887C1.95161 13.3759 1.84813 13.445 1.73425 13.4922C1.62036 13.5394 1.4983 13.5637 1.37503 13.5637C1.25176 13.5637 1.12969 13.5394 1.01581 13.4922C0.901922 13.445 0.798442 13.3759 0.711277 13.2887C0.624112 13.2016 0.554969 13.0981 0.507796 12.9842C0.460623 12.8703 0.436343 12.7482 0.436343 12.625C0.436343 12.5017 0.460623 12.3796 0.507796 12.2657C0.554969 12.1519 0.624112 12.0484 0.711277 11.9612L5.6744 6.99997L0.711277 2.03872C0.623971 1.95163 0.554703 1.84818 0.507441 1.73428C0.460179 1.62038 0.435852 1.49828 0.435852 1.37497C0.435852 1.25165 0.460179 1.12955 0.507441 1.01565C0.554703 0.901756 0.623971 0.798302 0.711277 0.711216Z" fill="#1A3B5C" />
    </svg>
    const EnvelopeSvg = () => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16" className="billingIcon">
    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
  </svg>
    const EditSvg = () => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5B3993" class="bi bi-pencil" viewBox="0 0 16 16" className="editIcon">
    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
  </svg>

    return <>
        {loading ? <Spinner /> :
        showProcessingPage ? <ProcessingPage/> : 
        !showBilling ? <ClientMainBilling billingPage={showBilling} /> :
            <Fragment><Title />
                {error ? <div className="error-cont">{error}</div> :
                    billingDetails &&
                    <>
                        <div className="tablesContainer">
                            {showEmailPopup &&
                                <CustomModal title="Update billing Email" loading={saving}
                                    onSave={() => saveBillingInfo() }
                                    closeModal={() => closeBillingPopUp()}>
                                    <Form className="edituser">
                                        <Fragment>
                                            <Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label>billing email</Form.Label>
                                                     <Form.Control
                                                     value={billingEmail}
                                                     type="email"
                                                     onChange={e => setBillingEmail(e.target.value)}
                                                     placeholder="Enter email"
                                                    onBlur={e => validateEmail(e.target.value)}
                                                    /> 
                                                    {!isNullorEmpty(emailError) && <div>{emailError}</div>}
                                                </Form.Group>
                                            </Row>
                                        </Fragment>
                                    </Form>
                                </CustomModal>
                            }
                            {showPaymentForm && <Payment onClose={() => setShowPaymentForm(false)} new={false} billingDetails={billingDetails} reloadBillingDetails={getPaymentDetails}/>}
                            <div className={isNullorEmpty(facilities.find(f => f.is_active === 2)) ? 'billingTablecont tablecont fullWidth' : 'billingTablecont tablecont subFac'}>
                                {!billingPerGroup &&
                                    <div className="facBillingMainHeader">

                                        <div className="facilityheader"><span className="billingBackBtn" onClick={() => setShowBilling(false)}><BackSvg /></span><span>{facilityDetails.name}</span></div>
                                    </div>
                                }
                                {error ? <div>{error}</div> :

                                    <TableScrollBar height='100%'>                                       
                                        <div className="billingWrapper">
                                            <div className="subFacilityheader">Plan:</div>
                                            <div className="subFacWrapper">
                                                {user.billing_discount > 0 && <><div className="subFacilities"><CostSvg />Trial Period: 3 months/ ${user.billing_discount} / Facility / Month</div>
                                                <div className="subFacilities">Trial Total: ${(billingDetails["Quantity"]*user.billing_discount).toFixed(2)}</div></>}
                                                <div className="subFacilities"><CostSvg />Cost: ${user.billing_price} / Facility / Month</div>
                                                {billingPerGroup && <div className="subFacilities">Facilities: {billingDetails["Quantity"]}</div>}  
                                                <div className="subFacilities">Total Amount: ${(billingDetails["Quantity"]*user.billing_price).toFixed(2)} </div>  
                                                                                                                                 
                                            </div>
                                        </div>

                                        <div className="billingWrapper">
                                            <div className="subFacilityheader">Details:</div>
                                            <div className="subFacWrapper">
                                                <div className="subFacilities"><DetailsSvg />Invoice ID: #{billingDetails["Invoice ID"]}</div>   
                                                                 
                                                <div className="subFacilities">Last Charge Date: {formatDate(billingDetails["Last Charge Date"]).replaceAll('/','-')} 
                                                {/* {billingPerGroup && <span className="billing-amount">Amount: ${billingDetails["Last Charge Amount"]}</span>} */}
                                                </div>  
                                                <div className="subFacilities"></div>
                                                <div className="subFacilities">Next Charge Date: {formatDate(billingDetails["Next Charge Date"]).replaceAll('/','-')} 
                                                {/* {billingPerGroup && <span className="billing-amount">Amount: ${(billingDetails["Quantity"]*35).toFixed(2)}</span>} */}
                                                </div>    
                                                <div className="subFacilities"></div>  
                                                                                       
                                            </div>
                                        </div>

                                        <div className="billingWrapper">
                                            <div className="subFacilityheader">Billing:</div>
                                            <div className="subFacWrapper">
                                                <div className="subFacilities"><VisaSvg />{billingDetails['Card Network']} **** **** **** <span id="cc-last-4">{billingDetails["Last 4 Digits"]}</span></div>
                                                <div className="subFacilities">Name: <span id="cc-name">{capitalize(billingDetails["First Name"])} {capitalize(billingDetails["Last Name"])}</span></div>
                                                {!isNullorEmpty(billingDetails["Expiration Date"]) && <div className="subFacilities">Expiration: <span id="cc-exp">{billingDetails["Expiration Date"]}</span></div>}
                                                <div className="subFacilities changeLink" onClick={() => setShowPaymentForm(true)}>Change payment method</div>

                                                <div className="subFacilities"><EnvelopeSvg /><span>{billingDetails["Shopper Email"]}</span></div>
                                                <div className="subFacilities changeLink" onClick={() => setShowEmailPopup(true)}>Change Billing Email</div>
                                            </div>
                                        </div>
                                        {billingPerGroup &&
                                            <div className="billingWrapper">
                                                <div className="subFacilityheader">Facilities:</div>
                                                <div className="subFacWrapper">
                                                    <div className="subFacilities"><FacilitySvg />Participating Facilities:</div>
                                                    <div className="subFacilities facilityList">
                                                        {facilities &&
                                                            facilities.map((f) =>
                                                                f.is_active == 1 &&
                                                                <span id={`fac_${f.facilityid}`}><FacilitySvg /><span id="fac-name">{startCase(f.facility_name.toLowerCase())}</span></span>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="billingWrapper">
                                            <div className="subFacilityheader"></div>
                                            <div className="subFacWrapper">
                                            <div className="subFacilities">Questions? Need to Cancel? Email <a href='mailto:support@dolphincare.ai'>support@dolphincare.ai</a></div>
                                            </div>
                                        </div>                                              
                                        <table>
                                            <tbody>


                                            </tbody>
                                        </table>
                                    </TableScrollBar>
                                }
                            </div>
                            <PendingFacilities subFacility={billingPerGroup ? billingDetails["Subscription ID"]: null} facShopperId={billingPerGroup ? null : billingDetails["Shopper ID"]} billingPerGroup={billingPerGroup} reloadBillingDetails={() => {dispatch(loadAllFacilities()); getPaymentDetails()}}/>
                        </div>
                    </>
                }
            </Fragment>
        }
    </>

}

export default ClientBillingFacility;