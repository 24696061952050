import React, { useEffect, useState, Fragment } from 'react';
import { Form, Spinner, Button } from 'react-bootstrap';
import axios from 'axios';
import isNullorEmpty from '../Utilities/isNullorEmpty';
import {capitalize, cloneDeep} from 'lodash';
import ReactTooltip from 'react-tooltip';
import dynamicSort from '../Utilities/SortTable';
import CustomModal from '../Utilities/CustomModal';
import NewGroup from './NewGroup';
import Config from '../Utilities/Config';

const BillingInfo = (props) => {

    const [currentGroup, setCurrentGroup] = useState(null)
    const [showPopup, setShowPopup] = useState(false)
    const [sortField, setSortField] = useState("group_code")
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(null)
    const [showCredPopup, setShowCredPopup] = useState(false)
    const [showFacilityPopup, setShowFacilityPopup] = useState(false)
    const [showNewGroupPopup, setShowNewGroupPopup] = useState(false)
    const [currentFacilities, setCurrentFacilities] = useState(null)
    const [origFacilities, setOrigFacilities] = useState(null)
    const [updateSubscription, setUpdateSubscription] = useState(false)
    const [updatedFields, setUpdatedFields] = useState([])
    
    const billingStatuses = [
        "Registered", "Trialing", "Trial Ended", "Paying", "Disabled/Other"
    ]
    const billingPrices = [35,200,250]

    const changeSortField = field => {
        setSortField(sortField === field ? `-${field}` : field)
    }

    const getFacilities = (group) => {
        setCurrentGroup(props.groups.find(g => g.group_code === group))
        setShowFacilityPopup(true)        
        setSaving(true)
        axios.get(`/api/internal/getallstatusfacilitiesbygroup/${group}/`, Config()).then((res) => {
            setOrigFacilities(cloneDeep(res.data))  
            setCurrentFacilities(res.data.sort(dynamicSort('facility_name')))     
            setSaving(false)   
        }).catch(err => { 
            console.log(err);
            setError('error loading facilities: '+ err.response)
            setSaving(false)  
        })
    }

    const openPopup = (groupId, edit=true) => {
        ReactTooltip.hide() 
        const curGroup = props.groups.find(g => g.group_code === groupId)
        if(!edit){
            let currentGroupCopy = curGroup
            currentGroupCopy['frontend_password'] = curGroup.frontend_username ? '****' : ''
            currentGroupCopy['secondary_password'] = curGroup.secondary_username ? '****' : ''
            currentGroupCopy['backup_password'] = curGroup.backup_username ? '****' : ''
            setCurrentGroup(currentGroupCopy)
            setShowCredPopup(true)
        }
        else{
            setCurrentGroup(curGroup)
            setShowPopup(true)
        }
    }

    const changeGroupInput = (field, value, update=true) => {
        let groupCopy = {...currentGroup}
        if(field === "billing_email"){
            value = value.includes('@') ? value.split('@')[1] : value
        }   
        else if(field === "billing_status"){
            groupCopy['active'] = (value == "Registered" || value == "Trialing" || value == "Paying") ? 1 : 0
        }
        else if (update && ['frontend', 'secondary','backup'].some(s => field.includes(s)) && !updatedFields.includes(field)){
            let updatedFieldsCopy = [...updatedFields]
            updatedFieldsCopy.push(field)
            setUpdatedFields(updatedFieldsCopy)
        }
        groupCopy[field] = value
        setCurrentGroup(groupCopy) 
    }

    const changeFacilityInput = (field, value, id) => {
        let index = currentFacilities.findIndex(f => f.facilityid === id)
        let facilitiesCopy = [...currentFacilities]
        facilitiesCopy[index][field] = value
        facilitiesCopy[index]['edited'] = true
        setCurrentFacilities(facilitiesCopy)
    }

    const editGroup = (edit=true) => {
        console.log('editing')
        setSaving(true)
        let objToSave = {...currentGroup}
        let fields = edit ? ['frontend_username','secondary_username','backup_username'] : ['frontend_password','secondary_password','backup_password','frontend_username','secondary_username','backup_username']
        fields.map(field => {
            if(objToSave[field] == '****' || isNullorEmpty(objToSave[field])){
                delete objToSave[field]
            }
        })
        if(!edit){
            objToSave['updated_fields'] = updatedFields
        }
        else if (objToSave.billing_email === null){
            objToSave.billing_email = ''
        }  
        axios.put(`/api/internal/updategroup/${edit ? "?edit" : ''}`, objToSave, Config()).then((res) => {
            setSaving(false)
            props.getGroups()
            setShowPopup(false)
            setShowCredPopup(false)
        }).catch(err => {
            console.log(err);
            setError(`Couldn't edit user ${err.response.data}`)
            setSaving(false)
        })
    } 

    const editFacilities = () => {
        setSaving(true)
        let facilitiesToSave = currentFacilities.filter(cf => cf['edited']);
        axios.put(`/api/internal/updatefacilities/`, {'facilities' : facilitiesToSave, 'updateSubscription' : updateSubscription}, Config()).then((res) => {
            setUpdateSubscription(false)
            setSaving(false)
            props.getGroups()
            setShowFacilityPopup(false)
        }).catch(err => {
            console.log(err);
            setError(`Couldn't update facilities ${err.response.data}`)
            setShowFacilityPopup(false)
            setSaving(false)
        })
    }

    const getPassword = (field, showPass) => {
        if(showPass){
            axios.post(`/api/internal/showcreds/${currentGroup.group_code}/`, {'field':field}, Config()).then((res) => {
                changeGroupInput(field, res.data, false)
            }).catch(err => {
                console.log(err);
            })
        }
        else{
            changeGroupInput(field, '****', false)
        }
      
    }
    
    const EditIcon = () => <svg
    width={20}
    height={20}
    viewBox="0 0 16 16"
    fill="black"
    xmlns="http://www.w3.org/2000/svg">
    <path
        fillRule="evenodd"
        d="M13.498.795l.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
    </svg>

    const KeyIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-key" viewBox="0 0 16 16">
    <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z"/>
    <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
  </svg>


    return <div className='usersInfo billing-groups'>
         {showPopup && !isNullorEmpty(currentGroup) && <CustomModal title={"Edit Group - " + capitalize(currentGroup.name)} loading={saving} 
            closeModal={() => setShowPopup(false)} onSave={() => editGroup()}>
            <div>
                <label for="dd"><b>Select a Status</b></label>
                <div className="group-drop">
                    <Form.Control as="select" name='dd'
                        value={currentGroup.billing_status}
                        onChange={(e) => changeGroupInput('billing_status', e.target.value)}>
                        {billingStatuses.map(s => <option key={s} value={s}>{s}</option>)}
                    </Form.Control>
                </div>
                {currentGroup.billing_status !== "Paying" && <>
                <label for="price-dd"><b>Select a Price</b></label>
                <div>
                    <Form.Control as="select" name='price-dd'
                        value={currentGroup.billing_price}
                        onChange={(e) => changeGroupInput('billing_price', e.target.value)}>
                        {billingPrices.map(p => <option key={p} value={p}>${p}.00</option>)}
                    </Form.Control>
                </div>
                <label for="billingdiscount"><b>Billing Discount First 3 M</b></label>
                $<input id="billingdiscount" placeholder="discount amt" value={currentGroup.billing_discount} onChange={(e) => changeGroupInput('billing_discount', e.target.value)} name="billingdiscount" />.00
                </>}                
                <div><Form.Check type='checkbox' checked={currentGroup.active == 1} label="Active" onChange={() => changeGroupInput('active',currentGroup.active == 1 ? 0 : 1)}/></div>
                {currentGroup.billing_status === 'Paying' && <div>
                    <label for="billingemail"><b>Billing Email Domain</b></label>
                    <input id="billing_email" type="email" placeholder="Enter Email Domain" value={currentGroup.billing_email} onChange={(e) => changeGroupInput('billing_email', e.target.value)} name="billingemail" />
                    <Form.Check type='radio' name='billingtype' checked={currentGroup.billing === 1} label="Bill Entire Group" onChange={() => changeGroupInput('billing', 1)} />
                    <Form.Check type='radio' name='billingtype' checked={currentGroup.billing === 2} label="Bill Per Facility" onChange={() => changeGroupInput('billing', 2)} />
                </div>}
            </div>
        </CustomModal>}
        {showCredPopup && !isNullorEmpty(currentGroup) && <CustomModal title={"Edit Credentials - " + capitalize(currentGroup.name)} loading={saving} 
            closeModal={() => {setShowCredPopup(false); setUpdatedFields([])}} onSave={() => {editGroup(false); setUpdatedFields([])}}>
            <div className="editcreds">
                {['frontend', 'secondary','backup'].map(field => {
                    const showPass = currentGroup[field+'_password'] == '****' || isNullorEmpty(currentGroup[field+'_password'])
                    return <>
                    <Form.Control id={field+"-username"} placeholder={`${field === "frontend" ? "" : capitalize(field)} Username`} value={currentGroup[field+"_username"]} onChange={(e) => changeGroupInput(field+"_username", e.target.value)} />
                    <Form.Control id={field+"-password"} placeholder={`${field === "frontend" ? "" : capitalize(field)} Password`} value={currentGroup[field+"_password"]} onChange={(e) => changeGroupInput(field+"_password", e.target.value)}/>
                    {!isNullorEmpty(currentGroup[field+'_username']) && <span className='show' onClick={() => getPassword(field+'_password', showPass)}>{showPass ? "Show Password" : "Hide Password"}</span>}
                    </>
                }
                )}
            </div>
        </CustomModal>}
        {showFacilityPopup && !isNullorEmpty(currentGroup) && <CustomModal maxHeight='90%' loading={saving} title={"Facilities - " + capitalize(currentGroup.name)} closeModal={() => {setShowFacilityPopup(false); setUpdateSubscription(false)}} onSave={() => editFacilities()}>
           <div>
                <div className='legend'>
                    <Form.Check type='checkbox' checked={true} label="Active" disabled />
                    <Form.Check type='checkbox' checked={false} label="Inactive" disabled />
                </div>
                {currentGroup.billing === 1 && <div className='legend'>
                    <Form.Check type='checkbox' checked={updateSubscription} label="Also update bluesnap subscription" onChange={() => setUpdateSubscription(!updateSubscription)} />
                </div>}
                {!isNullorEmpty(currentFacilities) && currentFacilities.map(f => <div>
                    <Form.Check type='checkbox' checked={f.is_active == 1} label={capitalize(f.facility_name)} onChange={() => changeFacilityInput('is_active', f.is_active == 1 ? 0 : 1, f.facilityid)} />
                </div>)}
            </div>               
        </CustomModal>}
        {showNewGroupPopup && <NewGroup billingPrices={billingPrices} billingStatuses={billingStatuses} setShowPopup={setShowNewGroupPopup} getGroups={props.getGroups}/>}
        {!props.groups ? <Spinner animation='border' variant='dark' /> :
            error ? <div>{error}</div> :
            props.groups && <>
            <Button className='addBtn' onClick={() => setShowNewGroupPopup(true)}>Add New Group</Button>
            <h2>Groups</h2>
            <table>
                <tr>
                    <th onClick={() => changeSortField("group_code")}>Group Code
                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                    </th>
                    <th onClick={() => changeSortField("name")}>Group Name
                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                    </th>
                    <th onClick={() => changeSortField("active")}>Is Active
                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                    </th>
                    <th onClick={() => changeSortField("billing_status")}>Billing Status
                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                    </th>
                    <th onClick={() => changeSortField("billing_price")}>Price/M
                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                    </th>
                    <th onClick={() => changeSortField("billing_discount")}>First 3 M 
                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                    </th>
                    <th onClick={() => changeSortField("trial_start_date")}>Trial Started
                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                    </th>
                    <th onClick={() => changeSortField("priority")}>Billing 
                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                    </th>
                    <th onClick={() => changeSortField("active_facilities")}>Active Facilities 
                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                    </th>
                    <th>Edit</th>
                    <th>Credentials</th>
                </tr>
                {props.groups && props.groups.sort(dynamicSort(sortField)).map(g =>
                    <tr key={g.group_code} className={g.active == "0" ? "disabled" : ''}> 
                        <td>{g.group_code}</td>
                        <td>{g.name}</td>
                        <td>{g.active == "0" ? "No" : "Yes"}</td>
                        <td>{g.billing_status}</td>
                        <td>${g.billing_price}</td>
                        <td>{g.billing_discount > 0 ? '$'+g.billing_discount : ''}</td>
                        <td>{g.trial_start_date}</td>
                        <td>{g.billing == 1 ? 'Group' : g.billing === 2 ? 'Per Facility' : ""}</td>
                        <td>
                            {g.active_facilities}
                            {g.active > 0 && <span className='action-link' onClick={() => getFacilities(g.group_code)}>Change</span>}
                        </td>
                        <td>
                            <ReactTooltip border={true} backgroundColor='#575757' textColor='white' multiline={false} />
                            <span onClick={() => openPopup(g.group_code)} data-tip="Edit" className="action-icon"><EditIcon /></span>
                        </td>
                        <td>
                            <ReactTooltip border={true} backgroundColor='#575757' textColor='white' multiline={false} />
                            <span onClick={() => openPopup(g.group_code, false)} data-tip="Update Credentials" className="action-icon"><KeyIcon /></span>
                        </td>
                    </tr>
                )}
            </table></>}                  
    </div>
}

export default BillingInfo