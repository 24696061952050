import React, { useEffect, useState, Fragment } from "react";
import { Form, Row, Col, Button } from 'react-bootstrap';
import { cloneDeep, capitalize } from 'lodash';
import Validation from "../Utilities/Validation";
import isNullorEmpty from "../Utilities/isNullorEmpty";
import Spinner from "../Utilities/Spinner";
import axios from "axios";
import Config from "../Utilities/Config";
import CustomModal from "../Utilities/CustomModal";
import dynamicSort from "../Utilities/SortTable";
import ClientUserFacilities from "./ClientUserFacilities";

const ClientUserPopup = props => {

    const [updatedUserObj, setUpdatedUserObj] = useState(false)
    const [errMsg, setErrMsg] = useState(null)
    const [loading, setLoading] = useState(false) 
    const [clickedButton, setClickedButton] = useState(false)
    const [facilitySpecific, setFacilitySpecific] = useState(false)
    // const [allFacilities, setAllFacilities] = useState(null)
    const [showFacilitiesPage, setShowFacilitiesPage] = useState(false)
    
    const [user, setUser] = useState({
        id: {
            value: 0,
            rules: {},
            type: "int",
        },
        first_name: {
            value: "",
            rules: {
                required: true,
            },
            type: "string",
            errMsg: ''
        },
        last_name: {
            value: "",
            rules: {
                required: true,
            },
            type: "string",
            errMsg: ''
        },
        email: {
            value: "",
            rules: {
                isEmail: true,
                required: true
            },
            type: "string",
            errMsg: ''
        },
        is_staff: {
            value: 0,
            rules: {},
            type: "bool"
        },
        is_active: {
            value: 1,
            rules: {},
            type: "bool"
        },
        p_view_history: {
            value: 1,
            rules: {},
            type: "bool"
        },
        p_user_management: {
            value: 0,
            rules: {},
            type: "bool"
        },
        p_edit_preferences: {
            value: 0,
            rules: {},
            type: "bool"
        },
        p_billing: {
            value: 0,
            rules: {},
            type: "bool"
        },
        facilities: {
            value: '0',
            rules: {
                required: true
            },
            type: "string"
        },
        password: {
            value: '',
            rules: {
                required: true,
                isPassword: true
            },
            type: 'string',
            errMsg: ''
        },
        password2: {
            value: '',
            rules: {
                required: true
            },
            type: 'string',
            errMsg: ''
        }
    })

    useEffect(() => {
        if(props.mode !== 'new' && !updatedUserObj && props.currentUser){
            const userTemp = {...user}
            for (let key of Object.keys(user)){
                userTemp[key].value = props.currentUser[key] === true ? 1 : props.currentUser[key] === false ? 0 : props.currentUser[key];    
            } 
            setUser(userTemp) 
            setUpdatedUserObj(true)        
        }
    //     if (!isNullorEmpty(props.currentUser)) {
    //         axios.get(`/api/getallfacilitiesbygroup/`, Config()).then((res) => { 
    //             setAllFacilities(res.data.sort(dynamicSort('facility_name')))              
    //     }).catch(err => { 
    //         console.log(err);
    //     })
    // }
    }, [props.currentUser]) 

    useEffect(() => {
        if(props.mode !== 'new' && props.currentUser){
            setFacilitySpecific(props.currentUser.facilities !== "0")
        }
    }, [props.currentUser]) 

    useEffect(() => {
        if(props.mode === "new"){
            setUser( { id: {
                value: 0,
                rules: {},
                type: "int",
            },email: {
                value: "",
                rules: {
                    isEmail: true,
                    required: true
                },
                type: "string",
                errMsg: ''
            }})       
        }
    }, [props.mode]) 

    const changePass = () => {
        if (user.password2.value !== user.password.value) {
            setErrMsg('Passwords do not match');
            return;
        }
        setLoading(true);
        const errors = [];
        ['password', 'password2'].forEach(key => {
            const message = Validation(user[key].value, user[key].rules);
            if (message !== '') {
                errors.push(message.replace(/{field}/ig, key === "password2" ? "Confirm Password" : capitalize(key.replace(/_/g, ' '))))
            }
        });
        if (errors.length > 0) {
            setErrMsg(errors.reduce((a, b) => a + '\u000A' + b));
            setLoading(false);
            return;
        }
        const objToSave = {id: user.id.value, email: user.email.value, password: user.password.value}
        axios.patch('/api/updateclientuser/', objToSave, Config()).then(res => {
            try{
                props.setMode(null)
                setLoading(false)                
                props.setUpdatedPassword(true)
            }catch{}
        }).catch(err => {
            console.log(err.response);
            setErrMsg("OOPS! Something went wrong. Contact support if error persists.")
            setLoading(false);
        })     
    }
    
    const saveUser = () => {        
        setErrMsg('')
        const objCopy = {...user}
        for (let [key, value] of Object.entries(user)) {
           objCopy[key].errMsg = ''                      
        }
        setUser(objCopy)
        if(props.mode === "changepass"){
            changePass()
        }
        else{
            setLoading(true)
            const objToSave = {};
            const errors = [];
            for (let [key, value] of Object.entries(user)) {
                if (!key.includes('password')){
                    const message = Validation(value.value, value.rules);
                    if (message !== '') {
                        errors.push(message.replace(/{field}/ig, capitalize(key.replace(/_/g, ' '))))
                    }
                    objToSave[key] = value.value;
                }               
            }            
            if (errors.length > 0) {
                setErrMsg(errors.reduce((a, b) => a + '\u000A' + b));
                setLoading(false);
                return;
            }
            console.log(objToSave) 
            const url = objToSave.id > 0 && props.mode === "edit" ? '/api/updateclientuser/' : '/api/addclientuser/'      
            axios.put(url, objToSave, Config()).then(res => {
                try{
                    props.refreshUsers()
                    if(props.mode === "new"){
                        props.setAddedUser(true)
                    }
                    props.setMode(null)
                    setLoading(false)
                }catch{}
            }).catch(err => {
                console.log(err.response);
                if(err.response.status === 406){
                    setErrMsg(err.response.data + " Please try again.")
                    setLoading(false)
                }
                else{
                    setErrMsg("OOPS! Something went wrong. Contact support if error persists.")
                    setLoading(false);
                }  
            })      
        }         
    }

    const inputChanged = (event, inputIdentifier) => {
        let value = ['string', 'number'].includes(typeof event)
        ? event
        : event.target.type === 'checkbox'
            ? event.target.checked
                ? 1
                : 0
            : event.target.value;
        const newObj = cloneDeep(user) 
        newObj[inputIdentifier].value = value;
        setUser(newObj);
               
    }

    const validate = (inputIdentifier) => {
        if(!clickedButton){
            setErrMsg('')
            const err = Validation(user[inputIdentifier].value, user[inputIdentifier].rules).replace(/{field}/ig, inputIdentifier === "password2" ? "Confirm Password" : inputIdentifier.replace(/_/g, ' '))
            const newU = cloneDeep(user);
            newU[inputIdentifier].errMsg = capitalize(err);
            setUser(newU)
        }
        else{
            setClickedButton(false)
        }              
    }

    const keyUpHandler = (e) => {
        if (e.key === 'Enter') {
            document.getElementById('save-btn').click()
        }
    }
    
    return <div>
        {showFacilitiesPage ? <ClientUserFacilities setShowPage={setShowFacilitiesPage} facilities={props.allFacilities} user={user} setUser={setUser} setFacilitySpecific={setFacilitySpecific}/> :               
        <CustomModal title={props.mode === "changepass" ? 'CHANGE PASSWORD' : props.mode.toUpperCase() + ' USER'} loading={loading}
            saveBtnTxt={props.mode === "new" ? 'ADD' : 'SAVE'} onSave={(event) => {event.preventDefault(); saveUser();}}
            onMouseDown={() => setClickedButton(true)} closeModal={() => props.setMode(null)}>              
                    <Form className="edituser">
                        {props.mode === "changepass" ? <Fragment>
                            <Row>
                                <Form.Group as={Col}>
                                    {/* <Form.Control
                                        value={user.email.value}
                                        disabled readonly />  */}  
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control
                                        autoFocus
                                        value={user.password.value}
                                        type='password'
                                        onChange={e => inputChanged(e, 'password')}
                                        placeholder="Password"
                                        onBlur={e => validate('password')} />
                                    {!isNullorEmpty(user.password.errMsg) &&
                                        <Form.Label className='error'>{user.password.errMsg}</Form.Label>}
                                    <br />

                                    <Form.Label>Confirm New Password</Form.Label>
                                    <Form.Control
                                        onKeyUp={e => keyUpHandler(e)}
                                        value={user.password2.value}
                                        type='password'
                                        onChange={e => inputChanged(e, 'password2')}
                                        placeholder="Confirm Password"
                                        onBlur={e => validate('password2')} />
                                    {!isNullorEmpty(user.password2.errMsg) &&
                                        <Form.Label className='error'>{user.password2.errMsg}</Form.Label>}
                                </Form.Group>
                            </Row> </Fragment> : props.mode === "new" ? <Fragment>
                            <Form.Group>
                                    <Form.Label className="form-label" >Email</Form.Label>
                                    <Form.Control onKeyUp={e => keyUpHandler(e)} autoFocus size="sm" type="email" value={user.email.value} onChange={e => inputChanged(e, 'email')} onBlur={e => validate('email')} />
                                    {!isNullorEmpty(user.email.errMsg) && <Form.Label className='error'>{user.email.errMsg}</Form.Label>}
                            </Form.Group>
                            </Fragment> :
                            <Fragment>
                                <Row>
                                    <Form.Group as={Col}>
                                        <Form.Label className="form-label" >First Name</Form.Label>
                                        <Form.Control size='sm' value={user.first_name.value} onChange={e => inputChanged(e, 'first_name')} onBlur={e => validate('first_name')} />
                                        {!isNullorEmpty(user.first_name.errMsg) && <Form.Label className='error'>{user.first_name.errMsg}</Form.Label>}
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label className="form-label" >Last Name</Form.Label>
                                        <Form.Control size='sm' value={user.last_name.value} onChange={e => inputChanged(e, 'last_name')} onBlur={e => validate('last_name')} />
                                        {!isNullorEmpty(user.last_name.errMsg) && <Form.Label className='error'>{user.last_name.errMsg}</Form.Label>}
                                    </Form.Group>
                                </Row>
                                <Form.Group>
                                    <Form.Label className="form-label" >Email</Form.Label>
                                    <Form.Control size="sm" type="email" value={user.email.value} onChange={e => inputChanged(e, 'email')} onBlur={e => validate('email')} />
                                    {!isNullorEmpty(user.email.errMsg) && <Form.Label className='error'>{user.email.errMsg}</Form.Label>}
                                </Form.Group>
                                <Row>
                                    <Form.Group as={Col}>
                                        <Form.Label className="form-label" >Role</Form.Label>
                                        <Form.Check type='radio' name='role' checked={user.is_staff.value === 0} label='Standard User' onChange={e => inputChanged(0, 'is_staff')} />
                                        <p className="radio-desc" >Browser Extension Access</p>
                                        <Form.Check type='radio' name='role' checked={user.is_staff.value === 1} label="Dashboard User" onChange={e => inputChanged(1, 'is_staff')} />
                                        <p className="radio-desc first" >Browser Extension Access</p>
                                        <p className="radio-desc first" >Dashboard Access</p>                                    
                                        <div className="checkbox-cont">
                                            <div className="checkbox">  
                                                <Form.Label>Permissions</Form.Label>
                                                {/* <Form.Check type='checkbox' name='permission' checked={true} label="View History" disabled={true} />  */}
                                                <Form.Check disabled={user.is_staff.value === 0} type='checkbox' name='permission' checked={user.p_view_history.value == 1} label="View History" onChange={e => inputChanged(e, 'p_view_history')} />                                   
                                                <Form.Check disabled={user.is_staff.value === 0} type='checkbox' name='permission' checked={user.p_user_management.value == 1} label="User Management" onChange={e => inputChanged(e, 'p_user_management')} />
                                                <Form.Check disabled={user.is_staff.value === 0} type='checkbox' name='permission' checked={user.p_billing.value == 1} label="Billing" onChange={e => inputChanged(e, 'p_billing')} />
                                                <Form.Check disabled={user.is_staff.value === 0} className="margin-b-last" type='checkbox' name='permission' checked={user.p_edit_preferences.value == 1} label="Edit Settings" onChange={e => inputChanged(e, 'p_edit_preferences')} />
                                            </div> 
                                        </div>                             
                                    </Form.Group>
                                    <Form.Group as={Col} className="facility-access">                                          
                                        <Form.Label className="form-label">Facility Access</Form.Label>
                                        <Form.Check type='radio' name='facility-access' checked={!facilitySpecific} label="All Facilities" onChange={() => {setFacilitySpecific(false); inputChanged("0", "facilities")}} />
                                        <Form.Check type='radio' name='facility-access' checked={facilitySpecific} label="Facility Specific" onChange={() => {setFacilitySpecific(true); setShowFacilitiesPage(true)}} className="facility-radio" />     
                                        {facilitySpecific && <p className="choose-facility" onClick={() => setShowFacilitiesPage(true)}>Change Facilities</p>}             
                                        <Form.Group>
                                            <Form.Label>Status</Form.Label>
                                            <Form.Check
                                                type='radio'
                                                name='status'
                                                checked={user.is_active.value === 1}
                                                onChange={e => inputChanged(1, 'is_active')}
                                                id='active'
                                                label="Active" />
                                            <Form.Check
                                                type='radio'
                                                name='status'
                                                id='disabled'
                                                checked={user.is_active.value === 0}
                                                onChange={e => inputChanged(0, 'is_active')}
                                                label="Disabled" />
                                        </Form.Group>
                                    </Form.Group>                                    
                                </Row>
                               </Fragment>}
                        {!isNullorEmpty(errMsg) && <div className='error'>{errMsg}</div>}
                    </Form>
           
        </CustomModal>}
    </div>
}

export default ClientUserPopup;