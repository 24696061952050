import axios from "axios"
import Config from "../Utilities/Config"
import * as actions from './actions'
import {capitalize, startCase} from 'lodash';
import isNullorEmpty from "../Utilities/isNullorEmpty";
import dynamicSort from "../Utilities/SortTable";


export const loadFacilities = () => {
    return dispatch => {
        axios.get(`/api/facilities/${true}/`, Config()).then(res => {
            //Checking if group is a parent group - if yes, adding group name to facility name: 
            let fac = res.data
            const unique = [...new Set(res.data.map(f => f.group))]
            if (unique.length > 1){                
                fac = res.data.map(f => {return {...f, 'facility_name' : (startCase(f.group) + " - " + f.facility_name)}})
            }
            dispatch({ type: actions.UPDATE, field: 'facilities', facilities: fac.sort(dynamicSort('facilityid')) });
        }).catch(err => {
            console.log(err.response);
        });
    }
};

export const loadAllFacilities = () => {
    return (dispatch, getState) => {
        const url = getState().globalState.isChildGroup ? `/api/facilities/${false}/${getState().globalState.currentGroup}/` : `/api/facilities/${false}/`
        axios.get(url, Config()).then(res => {
            //Checking if group is a parent group - if yes, adding group name to facility name: 
            let fac = res.data
            const unique = [...new Set(res.data.map(f => f.group))]
            if (unique.length > 1){                
                fac = res.data.map(f => {return {...f, 'facility_name' : (startCase(f.group) + " - " + f.facility_name)}})
            }
            dispatch({ type: actions.UPDATE, field: 'allFacilities', allFacilities: fac.sort(dynamicSort('facility_name')) });
        }).catch(err => {
            console.log(err.response);
        });
    }
};

export const setIsChildGroup = value => dispatch => dispatch({ type: actions.UPDATE, field: 'isChildGroup', isChildGroup: value })
export const setCurrentGroup = group => dispatch => dispatch({ type: actions.UPDATE, field: 'currentGroup', currentGroup: group })

export const setUser = user => dispatch => dispatch({ type: actions.UPDATE, field: 'user', user: user })

export const addToIVFluids = (ivfluids, facility) => {
    return (dispatch, getState) => {
        const existingList =getState().globalState.ivfluids;
        const notInList = ivfluids.filter(i => !existingList.find(ii=>ii.id===i.id))
        const combined = existingList.concat(notInList.map(i => ({ ...i, facilityid: facility })))
        dispatch({ type: actions.UPDATE, field: 'ivfluids', ivfluids: combined })
    }
}
export const addDocToIVFluids = (id, urls, docNames) => {
    return (dispatch, getState) => {
        const existingList =getState().globalState.ivfluids;
        const addDocs = existingList.map(i => i.id === id ? ({...i, in_subset_names: docNames, 'urls':urls }) : {...i})
        dispatch({ type: actions.UPDATE, field: 'ivfluids', ivfluids: addDocs })
    }
}

export const resetState = () => dispatch => dispatch({ type: actions.LOGOUT})
 