import React, {useEffect, useState} from 'react';
import { Route, Routes, Outlet } from 'react-router';
import PreregisterUsers from './AdminPages/PreregisterUsers';
import Users from './AdminPages/Users';
import SelectInvites from './AdminPages/Invites';
import SelectServices from './AdminPages/CustomerServices';
import SelectBlackList from './AdminPages/BlackList';
import dolphinCareLogo from './Assets/Logo.png';
import dolphinCareLogoIcon from './Assets/InvertedIcon.png';
import UserActivity from './AdminPages/UserActivity';
import BackendCreds from './AdminPages/BackendCreds'
import Groups from './AdminPages/Groups';
import ReleaseNotes from './AdminPages/ReleaseNotes';
// import Facilities from './AdminPages/Facilities';
import BillingInfo from './AdminPages/BillingInfo';
import axios from 'axios';
import Config from './Utilities/Config';

import { Link } from "react-router-dom";


const Admin = () => {
    const [groups, setGroups] = useState(null)
    const [groupFilter, setGroupFilter] = useState('mqs')
    const [facilities, setFacilities] = useState(null)

    useEffect(() => {      
        getGroups() 
        // getFacilities()
    }, [])


    const getGroups = () => {
        axios.get('/api/internal/getgroups/', Config()).then((res) => {
            setGroups(res.data)
        }).catch(err => {
            console.log(err);
        }) 
    }

    const getFacilities = () => {
        axios.get('/api/internal/getallfacilities/', Config()).then((res) => {
            setFacilities(res.data)
        }).catch(err => {
            console.log(err);
        }) 
    }

    return <div className="App">
        <header>
            <img src={dolphinCareLogo} />
            <nav className="navLinks">
                <Link to="/admin/users">USERS</Link>
                <Link to="/admin/invites">INVITES</Link>
                <Link to="/admin/addusers">ADD USERS</Link>
                <Link to="/admin/blacklist">BLACK LIST</Link>
                <Link to="/admin/activity">APP ACTIVITY</Link>
                <Link to="/admin/releasenotes">RELEASES</Link>
                <Link to="/admin/billing">GROUPS</Link>
                <Link to="/admin/groups">QA PRIORITIES</Link>
                <Link to="/admin/services">CUSTOMER SERVICES</Link>
                {/* <Link to="/admin/facilities">FACILITIES</Link> */}
                <Link to="/admin/backend">BACKEND CREDS</Link>

            </nav>
        </header>
        <div className='main'>
            <Routes>
                <Route path='addusers' element={<PreregisterUsers groups={groups} />} />
                <Route path='users' element={<Users groups={groups}/>} />
                <Route path='invites' element={<SelectInvites />} />
                <Route path='blacklist' element={<SelectBlackList />} />
                <Route path='backend' element={<BackendCreds />} />
                <Route path='activity' element={<UserActivity groups={groups}/>} />
                <Route path='billing' element={<BillingInfo groups={groups} getGroups={getGroups} />} />
                <Route path='groups' element={<Groups groups={groups} getGroups={getGroups} />} />
                <Route path='services' element={<SelectServices/>} />
                {/* <Route path='facilities' element={<Facilities groups={groups} facilities={facilities} getGroups={getGroups} getFacilities={getFacilities} />} /> */}
                <Route path='releasenotes' element={<ReleaseNotes />} />
            </Routes>

        </div>
        <img className='icon' src={dolphinCareLogoIcon} />
    </div>

}

export default Admin;
