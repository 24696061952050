import React, { useEffect, useState, Fragment } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import axios from 'axios';
import isNullorEmpty from '../Utilities/isNullorEmpty';
import dynamicSort from '../Utilities/SortTable';
import {capitalize} from 'lodash';
import Config from '../Utilities/Config';

const UserPopup = (props) => {

    const [facilities, setFacilities] = useState(null)
    const [currentFacilities, setCurrentFacilities] = useState([])
    const [showFacilities, setShowFacilities] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (!isNullorEmpty(props.user)) {
                axios.get(`/api/getfacilitiesbygroup/${props.user.group}/`, Config()).then((res) => {
                    setFacilities(res.data.sort(dynamicSort('facility_name')))    
                    const userFacilities = props.user.facilities.split(',').map(f => parseInt(f))
                    console.log(userFacilities)
                    setCurrentFacilities(userFacilities == 0 ? res.data.map(f => f.facilityid) : userFacilities)  
                    setLoading(false)          
            }).catch(err => { 
                console.log(err);
                setLoading(false)  
            })
        }
    }, [props.user])
    
    const updateFacility = (event, id, all=false) => {
        let facilitiesCopy = all ? [] : [...currentFacilities]
        if(event.target.checked){
            if(all){
                facilities.forEach(f => facilitiesCopy.push(f.facilityid))
            }
            else{
                facilitiesCopy.push(id) 
            }
        }
        else if(!event.target.checked && !all){
            facilitiesCopy = facilitiesCopy.filter(f => f !== id)
        }
        setCurrentFacilities(facilitiesCopy)
    }

    
    useEffect(() => {
        if (currentFacilities.length > 0) {
            let accessCopy = {...props.dashboardAccess}
            accessCopy.facilities = currentFacilities.length === facilities.length ? '0' : currentFacilities.join(',')
            console.log(accessCopy.facilities)
            props.setDashboardAccess(accessCopy)
        }
    }, [currentFacilities])

    const dashboardAccessChanged = (event, field) => {
        let value = ['string', 'number'].includes(typeof event)
        ? event
        : event.target.type === 'checkbox'
            ? event.target.checked
                ? 1
                : 0
            : event.target.value;
        let accessCopy = {...props.dashboardAccess}
        accessCopy[field] = value
        props.setDashboardAccess(accessCopy)
    } 

    return <div className='usersPopup'>     
        <div className="passPopup" id="dashboardPopup">
            <div className="popupContainer">                
                {loading ? <div><Spinner animation='border' variant='dark'/></div> : props.dashboardAccess && props.user && <Fragment>                    
                    <h3>{capitalize(props.user.first_name) + " " + capitalize(props.user.last_name)}</h3>
                    <h5>Dashboard Access</h5>
                    <Form.Group className='dashboard-access'>
                        <Form.Check type='radio' name='role' checked={props.dashboardAccess.is_staff === 0} label='None' onChange={e => dashboardAccessChanged(0, 'is_staff')} />
                        <Form.Check type='radio' name='role' checked={props.dashboardAccess.is_staff === 1} label="Dashboard Access" onChange={e => dashboardAccessChanged(1, 'is_staff')} />
                        <div className='checkbox-cont'>
                            <div className="checkbox">               
                                <p>Permissions:</p>       
                                <Form.Check disabled={props.dashboardAccess.is_staff === 0} type='checkbox' name='permission' checked={props.dashboardAccess.p_view_history == 1} label="View History" onChange={e => dashboardAccessChanged(e, 'p_view_history')} />                
                                <Form.Check disabled={props.dashboardAccess.is_staff === 0} type='checkbox' name='permission' checked={props.dashboardAccess.p_user_management == 1} label="User Management" onChange={e => dashboardAccessChanged(e, 'p_user_management')} />
                                <Form.Check disabled={props.dashboardAccess.is_staff === 0} type='checkbox' name='permission' checked={props.dashboardAccess.p_billing == 1} label="Billing" onChange={e => dashboardAccessChanged(e, 'p_billing')} />
                                <Form.Check disabled={props.dashboardAccess.is_staff === 0} className="margin-b-last" type='checkbox' name='permission' checked={props.dashboardAccess.p_edit_preferences == 1} label="Edit Settings" onChange={e => dashboardAccessChanged(e, 'p_edit_preferences')} />   
                            </div>                           
                        </div> 
                    </Form.Group>  
                    <h5>Facility Access</h5>  
                    <div className="checkbox">
                        <Form.Check type='radio' name='facility-access' checked={facilities && facilities.length == currentFacilities.length} label="All Facilities" onChange={(e) => {setShowFacilities(false); updateFacility(e, facilities, true)}} />
                        <Form.Check type='radio' name='facility-access' checked={facilities && facilities.length > currentFacilities.length} label="Facility Specific" onChange={() => setShowFacilities(true)} className="facility-radio" />  
                        {/* <p>Current Access: {facilities && currentFacilities.length === 0 ? "None" : facilities && facilities.length > currentFacilities.length ? "Facility Specific" : "All Facilities"}</p> */}
                        {facilities && (facilities.length > currentFacilities.length || showFacilities) && <span className='expand-fac' onClick={() => setShowFacilities(!showFacilities)}><strong>{showFacilities ? "Minimize" : "Change Facilities"}</strong></span>}
                        {showFacilities && <div>
                            <Form.Check type='checkbox' checked={facilities.every(fac => currentFacilities.includes(fac.facilityid))} onChange={(e) => updateFacility(e, facilities, true)} label="ALL/NONE" />
                            <div style={{ columns: 3 }}>
                                {facilities.map(f => <Form.Check type='checkbox' key={f.facilityid} checked={currentFacilities.includes(f.facilityid)} onChange={(e) => updateFacility(e, f.facilityid)} label={f.facility_name} />)}
                            </div>
                        </div>}
                    </div> 
                    <div className='footer'>
                        <button type="button" className="btn" disabled={currentFacilities.length === 0} onClick={() => props.editUser("dashboardPopup")} > {props.saving ? <Spinner animation='border' variant='dark'/> : "Save"}</button>
                        <button type="button" className="cancel-btn" disabled={props.saving} onClick={() => props.closePopup()}>Cancel</button>
                    </div> 
                </Fragment>}               
            </div>
        </div>                    
    </div>

}
export default UserPopup;