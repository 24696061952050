import React, { useEffect, useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import isNullorEmpty from '../Utilities/isNullorEmpty';
import Config from '../Utilities/Config';
import { formatDateTime, formatDate } from '../Utilities/FormatDate';
import dynamicSort from '../Utilities/SortTable';
import { defaultCursor, waitCursor } from '../Utilities/Cursor';
import GroupFilter from './GroupFilter';
import {capitalize} from 'lodash';

const today = new Date();
//today.setHours(0,0,0,0);

const SelectInvites = () => {

    const [loading, setLoading] = useState(true)
    const [invites, setInvites] = useState(null)
    const [currentInvites, setCurrentInvites] = useState(invites)
    const [error, setError] = useState(null)
    const [sortField, setSortField] = useState("last_name")
    

    const changeSortField = field => {
        setSortField(sortField === field ? `-${field}` : field)
    }
    useEffect(() => {
        // if (window.location.host !== "internal.dolphincare.ai" && window.location.host !== "localhost:3001") {
        //     console.log(window.location.host)
        //     window.location.replace("http://app.dolphincare.ai/");
        // }
        // else {
            loadInvites()
        // }
    }, [])

    const loadInvites = () => {
        axios.get('/api/internal/getinvites/', Config()).then((res) => {
            setInvites(res.data)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            console.log(err);
            setError(`Couldn't get users invites for ${err.response.data}`)
        })
    }
    const resend = (email, group) => {
        waitCursor()
        axios.post('/api/internal/preregisteruser/', { emails: email, group: group }, Config()).then((res) => {
            defaultCursor()
            loadInvites()
            alert('Invite Resent')
        }).catch(err => {
            defaultCursor()
            console.log(err);
        })
    }


    return <div className='usersInfo'>
        {loading ? <Spinner animation='border' variant='dark' /> :
            error ? <div>{error}</div> :
                invites && <div>
                    <Form className='wider'>
                        <h2>Users Invites</h2>
                        <GroupFilter users={invites} setCurrentUsers ={setCurrentInvites}/>
                        <table>
                            <tbody>
                                <tr>
                                    <th onClick={() => changeSortField("created")}>Sent
                                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                                    </th>
                                    <th onClick={() => changeSortField("email")}>Email
                                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                                    </th>
                                    <th onClick={() => changeSortField("group")}>Group
                                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                                    </th>
                                    <th onClick={() => changeSortField("expires")}>Expiry date
                                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                                    </th>
                                    <th></th>
                                </tr>
                                {currentInvites && currentInvites.sort(dynamicSort(sortField)).map(i =>
                                    <tr key={i.id}>
                                        <td>{formatDateTime(i.created)}</td>
                                        <td>{i.email}</td>
                                        <td>{capitalize(i.group_name)}</td>
                                        {i.expires <= { today } ?
                                            <td> {formatDateTime(i.expires)}</td>
                                            : <td className='neverSignedIn'>Expired!</td>}
                                            <td className="openPopUp" onClick={()=>resend(i.email, i.group)}>Resend</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </Form>
                </div>
        }
    </div>

}
export default SelectInvites