import axios from "axios";
import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import Config from "../Utilities/Config";
import TableScrollBar from 'react-table-scrollbar'
import Spinner from "../Utilities/Spinner";
import { formatStringDate } from '../Utilities/FormatDate'
import isNullorEmpty from "../Utilities/isNullorEmpty";
import { Title } from "./SideBar";
import { useDispatch, useSelector } from "react-redux";
import { addDocToIVFluids, addToIVFluids } from '../redux/globalfunctions';
import CustomModal from "../Utilities/CustomModal";
import ReactTooltip from 'react-tooltip';
import { DocSvg, PopoutSvg, ViewDocSvg } from "../Utilities/SvgImages";
import { Spinner as BootstrapSpinner } from "react-bootstrap";
import {capitalize} from 'lodash';
import { useNavigate } from 'react-router';

// import 'react-date-range/dist/styles.css'; // main css file
// import 'react-date-range/dist/theme/default.css'; // theme css file
// import { DateRangePicker } from 'react-date-range';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import dynamicSort from "../Utilities/SortTable";

const IVList = props => {
    const [ivlist, setIvlist] = useState()
    const [dateRange, setDateRange] = useState([new Date(Date.now() - 15 * 24 * 60 * 60 * 1000), new Date()])
    const [selectedFacility, setSelectedFacility] = useState('0-0')
    const [docListToShow, setDocListToShow] = useState(false)
    const [showDocPopup, setShowDocPopup] = useState(false)
    const [currentDocInfo, setCurrentDocInfo] = useState({})
    const [currentHtmlDocIndex, setCurrentHtmlDocIndex] = useState({})
    const [currentDocIndex, setCurrentDocIndex] = useState(0)
    // const [showLogoSpinner, setShowLogoSpinner] = useState(true)
    const [sortField, setSortField] = useState('admission_date')
    const [loading, setLoading] = useState(true)
    const facilities = useSelector(state => state.globalState.facilities)
    const fullIVList = useSelector(state => state.globalState.ivfluids)
    const globalHtmlDocs = useSelector(state => state.globalState.htmlDocs)
    const dispatch = useDispatch()
    const [htmlDocs, setHtmlDocs] = useState([])
    const user = useSelector(state => state.globalState.user)
    const navigate = useNavigate()
    const [error, setError] = useState(null)
    const [responseFeedback, setResponseFeedback] = useState(null)


    useEffect(() => {
        try {
            document.getElementsByClassName('facilities')[0].style.display = 'none';
            setTimeout(() => document.getElementsByClassName('facilities')[0].style.removeProperty('display'), 500)
        } catch { }
        setIvlist(null)
        loadIvFluids()
    }, [selectedFacility])

    useLayoutEffect(() => {
        if (document.getElementsByClassName('react-daterange-picker__wrapper').length > 0) {
            [...document.getElementsByClassName('react-daterange-picker__wrapper')[0].children].forEach(el => el.style.order = 2)
            document.getElementsByClassName('react-daterange-picker__calendar-button')[0].style.order = 1
            try {
                document.getElementsByClassName('react-daterange-picker__clear-button')[0].remove();
            } catch { }
        }
        try { document.getElementsByClassName('ivlisttable')[1].style.pointerEvents = 'none' } catch { }
    })

    useEffect(() => {
        if(user && user['billing-only']){
            navigate('/client/billing')
            console.log('navigating from history page to billing')
        }
    }, [user])

    useEffect(() => {
        setIvlist(null)
        loadIvFluids()
    }, [dateRange])

    useEffect(() => {
        if (!isNullorEmpty(facilities) && selectedFacility === "0-0") {
            setSelectedFacility(facilities[0].facilityid + "-" + facilities[0].group)
        }
    }, [facilities])

    useEffect(() => {
        axios.get('/api/getresponsefeedback/', Config()).then(res => {
           setResponseFeedback(res.data)
           //set to variable in loadivfluids map through feedback and match ? - facility, mrid, and admission date - whch values do we have that are unique?
        }).catch(err => {
            console.log(err.response)           
        })
    }, [])

    // useEffect(() => {
    //     if (showLogoSpinner && ivlist) {
    //         setShowLogoSpinner(false)
    //     }
    // }, [ivlist])

    const convertToDate = (date, isString = true) => {
        date = !isString ? date.toString() : date
        return new Date(date.replace(/-/g, '/'))
    }

    const hasFeedback = (item) => {
       let feedback = responseFeedback.filter(r => r.mrid === item.medical_recordid && r.facilityid === item.facilityId && r.admission_date === item.admission_date)
       return feedback.length > 0 ? feedback[0].message : ''
    }
    const addFeedback = (list) => {
        if(user.show_mismatch_popup_pref == 1 && responseFeedback && responseFeedback.length > 0){ // if group has preference and there's feedback for this group
            const updatedList = list.map(item => {return {...item, 'feedback_message': hasFeedback(item)}} )
            return updatedList 
        }
        return list.map(item => {return {...item, 'feedback_message': ""}} )
    }

    const loadIvFluids = () => {
        setLoading(true)
        const facility = selectedFacility
        if (fullIVList && fullIVList.find(i => i.facilityid == facility)) {
            const startDate = convertToDate(dateRange[0], false).setHours(0, 0, 0, 0)
            const endDate = convertToDate(dateRange[1], false).setHours(0, 0, 0, 0)
            const filteredList = fullIVList.filter(i => i.facilityid == facility && (convertToDate(i.admission_date) >= startDate && convertToDate(i.admission_date) <= endDate))

            if (!isNullorEmpty(filteredList)) {
                setIvlist(addFeedback(filteredList))
            }
        }
        const dateParams = dateRange.map(date => date = convertToDate(date, false))
        axios.post(`/api/ivfluid/${selectedFacility.split('-')[0]}/${selectedFacility.split('-')[1]}/`, { 'dates': dateParams }, Config()).then(res => {
            try{
                const listWithFeedback = addFeedback(res.data)
                if (selectedFacility === facility) {
                    setIvlist(listWithFeedback)
                }
                setLoading(false)
                dispatch(addToIVFluids(listWithFeedback, facility))
            }
            catch(error){console.log(error)}           
        }).catch(err => {
            console.log(err.response)
            if(err.response.status === 401){
                setError("You do not have permission to access this page. Contact support for further assistance.")
                setLoading(false)
            }
        })

    }


    const getIvDoc = (admission, showPopup) => {
        ReactTooltip.hide()
        const names = admission.documents.split(';').filter(i => !isNullorEmpty(i))
        const ids = admission.doc_ids.split(';').filter(i => !isNullorEmpty(i))
        const urls = []
        for (var id of ids) {
            const type = Array.from(id)[0] === 'c' ? 'connect' : 'pdf'
            urls.push(`/document/${type}/${id.slice(1)}/?dc_token=${window.localStorage.getItem('dc_token')}&dc_browser=${window.localStorage.getItem('dc_browser')}`)            
        }
        if (!showPopup) {
            urls.map(url => window.open(url+'&fullpage=true', '_blank'))
        } else {
            setShowDocPopup(true)
            setCurrentDocInfo({ 'id': admission.id, 'urls': urls, 'in_subset_names': names })         
        }
    }

    const changeSortField = field => { setSortField((field === sortField ? '-' : '') + field) }

    const showNextDoc = (next = true) => {
        setCurrentDocIndex(next ? currentDocIndex + 1 : currentDocIndex - 1)
    }

    const NextArrow = () => <span className="arrow"><svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 11L6.5 6L1.5 1" stroke="white" stroke-width="2"/>
    </svg><svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 11L6.5 6L1.5 1" stroke="white" stroke-width="2"/>
    </svg></span>

    const PrevArrow = () => <span className="arrow"><svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.5 1L1.5 6L6.5 11" stroke="white" stroke-width="2"/>
    </svg><svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.5 1L1.5 6L6.5 11" stroke="white" stroke-width="2"/>
    </svg></span>
    
    const cleanDocName = name => {
        const sourceId = name.split('_')[0]
        return capitalize(name.replace(sourceId+'_', ''))
    }

    return <div className="ivlist">
        {!facilities || !user ? <Spinner /> : !isNullorEmpty(error) ? <div className="error">{error}</div> : 
            <>
                {showDocPopup && currentDocInfo && <CustomModal noFooter customBody height='95%' width='70%' closeModal={() => { setShowDocPopup(false); setCurrentDocIndex(0); }}>
                    <div className="doc-header">
                         {/* <span className="close" onClick={() => { setShowDocPopup(false); setCurrentDocIndex(0); }}>&times;</span>  */}
                         <div className="page-numbers">                           
                            {currentDocInfo.urls.length > 1 && <span className={currentDocIndex > 0 ? "prev" : "prev disable"} onClick={() => showNextDoc(false)}><PrevArrow/> Prev</span>}
                            <h5>{ivlist && ivlist.find(iv => iv.id === currentDocInfo.id).name} {currentDocIndex + 1 + " / " + currentDocInfo.urls.length}</h5>
                            {currentDocInfo.urls.length > 1 && <span className={currentDocIndex + 1 < currentDocInfo.urls.length ? "next" : "next disable"} onClick={() => showNextDoc()}>Next <NextArrow/></span>}
                        </div> 
                    </div>
                    <div className="doc-name">
                        <p>{currentDocInfo.urls[currentDocIndex].includes('connect') ? <Fragment><strong>PCC Connect</strong><span> - </span></Fragment> : ""}{cleanDocName(currentDocInfo.in_subset_names[currentDocIndex])}</p>
                    </div>
                    <div className="doc-container">
                        {/* <span className="iframe-cont"><iframe height="20%" width="20%" src={currentDocInfo.urls[currentDocIndex] + "#toolbar=0"} allowFullScreen></iframe></span> */}
                        {currentDocInfo.urls.map((url, i) => <span className={i === currentDocIndex ? "iframe-cont" : "iframe-cont hide"}><iframe height="20%" width="20%" src={url + "#toolbar=0"} allowFullScreen></iframe></span>)}
                    </div>  
                </CustomModal>}
                <div className="titlefilters">
                    <Title />
                    <div className="filter">
                        <div className={loading ? "disabled" : "facfilter"}><svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z" />
                            <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
                        </svg>{selectedFacility && selectedFacility !== "0-0" && facilities && facilities.find(f => f.facilityid == selectedFacility.split('-')[0]).facility_name}
                            <div className="facilities">
                                {facilities && facilities.map(f => <div key={f.facilityid + f.group} id={f.facilityid} onClick={() => setSelectedFacility(f.facilityid + "-" + f.group)}>{f.facility_name}</div>)}
                            </div>
                        </div>
                        {/* <div><svg viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.227 8.99997H12.774C13.636 8.99997 14.094 7.97997 13.521 7.33497L7.748 0.839972C7.65436 0.734277 7.53935 0.649657 7.41058 0.591704C7.28181 0.533751 7.14221 0.503784 7.001 0.503784C6.85979 0.503784 6.72018 0.533751 6.59141 0.591704C6.46265 0.649657 6.34764 0.734277 6.254 0.839972L0.478997 7.33497C-0.0940025 7.97997 0.363998 8.99997 1.227 8.99997ZM6.253 19.159C6.34664 19.2647 6.46165 19.3493 6.59042 19.4072C6.71919 19.4652 6.85879 19.4952 7 19.4952C7.14121 19.4952 7.28081 19.4652 7.40958 19.4072C7.53835 19.3493 7.65336 19.2647 7.747 19.159L13.52 12.664C14.094 12.02 13.636 11 12.773 11H1.227C0.364998 11 -0.0930026 12.02 0.479997 12.665L6.253 19.159Z" fill="black" />
                        </svg>Sort BY
                        </div> */}
                        {/* <div><svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0ZM1 4V14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15H14C14.2652 15 14.5196 14.8946 14.7071 14.7071C14.8946 14.5196 15 14.2652 15 14V4H1Z" fill="black" />
                        </svg>This Week</div> */}
                        <DateRangePicker minDate={new Date(2022, 10, 20)} className={loading ? "disabled" : ''} disabled={loading} onChange={setDateRange} value={dateRange} />
                    </div>
                </div>
                <div className="tablecont">
                    <ReactTooltip border={true} backgroundColor='#575757' textColor='white' multiline={false} />
                    <TableScrollBar height='100%'>
                        <table className="ivlisttable">
                            <thead>
                                <tr>
                                    <th className="pointer"><div>Admission Date<SortIcon onClick={() => changeSortField('admission_date')} /></div></th>
                                    <th className="pointer"><div>Name<SortIcon onClick={() => changeSortField('name')} /></div></th>
                                    <th className="pointer"><div>IV Fluids<SortIcon onClick={() => changeSortField('iv_found')} /></div></th>
                                    {user && user.show_mismatch_popup_pref == 1 && <th className="pointer"><div>Coding Conflict<SortIcon onClick={() => changeSortField('feedback_message')} /></div></th>}
                                </tr>
                            </thead>
                            <tbody>{ivlist && ivlist.sort(dynamicSort(sortField)).map(i => <tr key={i.id}>
                                <td>{formatStringDate(i.admission_date)}</td>
                                <td>{i.name} ({i.medical_recordid})</td>
                                <td>{i.iv_found ? <>
                                    <span className="doc-icon" data-tip="View" onClick={() => getIvDoc(i, true)}><DocSvg /></span>
                                    <span className="doc-icon margin" data-tip="Open in new tab" onClick={() => getIvDoc(i, false)}><PopoutSvg /> </span>
                                </>
                                    : <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
                                        <path d="M5 5.93337L1.73334 9.20003C1.61111 9.32225 1.45556 9.38337 1.26667 9.38337C1.07778 9.38337 0.922224 9.32225 0.800002 9.20003C0.67778 9.07781 0.616669 8.92226 0.616669 8.73337C0.616669 8.54448 0.67778 8.38892 0.800002 8.2667L4.06667 5.00003L0.800002 1.73337C0.67778 1.61114 0.616669 1.45559 0.616669 1.2667C0.616669 1.07781 0.67778 0.922255 0.800002 0.800032C0.922224 0.67781 1.07778 0.616699 1.26667 0.616699C1.45556 0.616699 1.61111 0.67781 1.73334 0.800032L5 4.0667L8.26667 0.800032C8.38889 0.67781 8.54445 0.616699 8.73334 0.616699C8.92222 0.616699 9.07778 0.67781 9.2 0.800032C9.32222 0.922255 9.38334 1.07781 9.38334 1.2667C9.38334 1.45559 9.32222 1.61114 9.2 1.73337L5.93334 5.00003L9.2 8.2667C9.32222 8.38892 9.38334 8.54448 9.38334 8.73337C9.38334 8.92226 9.32222 9.07781 9.2 9.20003C9.07778 9.32225 8.92222 9.38337 8.73334 9.38337C8.54445 9.38337 8.38889 9.32225 8.26667 9.20003L5 5.93337Z" fill="grey" />
                                    </svg>
                                }</td>
                                {user && user.show_mismatch_popup_pref == 1 && <td>{i.feedback_message}</td>}
                                {/* <td> */}
                                {/* */}
                                {/* <span className="doc-icon" data-tip="View" onClick={() => showDocList(i)}>{i.iv_found ? <ViewDocSvg /> : null}</span> */}
                                {/* <span className="doc-icon" data-tip="View" onClick={() => getIvDoc(i, true)}>{i.iv_found ? <ViewDocSvg /> : null}</span>
                                     */}
                                {/* </td> */}
                            </tr>)}
                                {loading ? <tr className="loading-data">
                                    <td><span><BootstrapSpinner animation="border" /></span></td>
                                    <td colSpan={user && user.show_mismatch_popup_pref == 1 ? "3" : "2"}><span className="text">Loading data</span></td> 
                                </tr> : <tr></tr>}
                            </tbody>
                        </table>
                    </TableScrollBar>
                </div>
            </>}
    </div>

}

export default IVList;


const SortIcon = props => <svg onClick={props.onClick} className="filterIcon" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 12L0.5 8.5L1.205 7.795L4 10.585L6.795 7.795L7.5 8.5L4 12ZM4 0L7.5 3.5L6.795 4.205L4 1.415L1.205 4.205L0.5 3.5L4 0Z" fill="#1A3B5C" />
</svg>;
