import React from 'react';
import Wrapper from './Wrapper';

const PrivacyPolicy = () => {

    return <Wrapper><div>
        {/* <div onClick={window.open('http://localhost:3000/popup.js')}>Open Popup</div> */}
        <h2>Website Privacy Policy</h2>
        <p>Last modified: July 20, 2022</p>
        <h5>Introduction</h5>
        <p>DolphinCare Ltd. (<strong>"Company"</strong> or <strong>"We"</strong>) respects your privacy and is committed to protecting your privacy through compliance with this policy.</p>
        <p>This policy describes the types of information we may collect from you or that you may provide when you use our DolphinCare browser entension and our practices for collecting, using, maintaining, protecting, and disclosing that information.</p>
        <p>This policy applies to information we collect:</p>
        <ul>
            <li>On this Website.</li>
            <li>In email, text, and other electronic messages between you and this Website.</li>
            <li>Through mobile and desktop applications you download from this Website, which may provide dedicated non-browser-based interaction between you and this Website.</li>
        </ul>
        <p>It does not apply to information collected by:</p>
        <ul>
            <li>Us offline or through any other means, including on any other website operated by Company or any third party; or</li>
            <li>Any third party (including your employer(s)), including through any application or content (including advertising) that may link to or be accessible from or through the Website.</li>
        </ul>
        <p>Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Website. By accessing or using this Website, you agree to this privacy policy. This policy may change from time to time (see <a href="#changes-to-policy">Changes to Our Privacy Policy</a>). Your continued use of this Website after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.&nbsp;</p>

        <h5>Children Under the Age of 16</h5>
        <p>Our Website is not intended for children under 16 years of age. No one under age 16 may provide any personal information to or on the Website. We do not knowingly collect personal information from children under 16. If you are under 16, do not use or provide any information on this Website or through any of its features, register on the Website, use any of the interactive or public comment features of this Website, or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or username you may use. If we learn we have collected or received personal information from a child under 16 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 16, please contact us at <a href="mailto:support@dolphincare.ai">support@dolphincare.ai</a>.</p>
        <p>California residents under 16 years of age may have additional rights regarding the collection and sale of their personal information. Please see <a href="#state-privacy-rights">Your State Privacy Rights</a> for more information.</p>

        <h5>Information We Collect About You and How We Collect It</h5>
        <p>We collect several types of information from and about users of our Website, including information:</p>
        <ul>
            <li>By which you may be personally identified, such as name, title, postal address, email address, telephone number, or username ("<strong>personal information</strong>");</li>
            <li>That is about you but individually does not identify you, such as employer, location, facility, or job description; and/or</li>
            <li>About your internet connection, the equipment you use to access our Website, and usage details.</li>
        </ul>
        <p>We collect this information:</p>
        <ul>
            <li>Directly from you when you provide it to us.</li>
            <li>Automatically as you navigate through the site. Information collected automatically may include usage details, IP addresses, and information collected through cookies and other tracking technologies.</li>
            <li>From third parties, for example, our business partners.</li>
        </ul>
        <p><em>Information You Provide to Us</em></p>
        <p>The information we collect on or through our Website may include:</p>
        <ul>
            <li>Information that you provide by filling in forms on our Website. This includes information provided at the time of registering to use our Website, using our service(s), providing material and feedback, or requesting further services. We may also ask you for information when you report a problem with our Website.</li>
            <li>Records and copies of your correspondence (including email address(es)) if you contact us.</li>
            <li>Your responses to questions that we might ask you to answer for research purposes.</li>
            <li>Your search queries on the Website.</li>
        </ul>
        <p>You also may provide information to be published or displayed (hereinafter, <strong>"posted"</strong>) on public areas of the Website, or transmitted to other users of the Website or third parties (collectively, <strong>"User Contributions"</strong>"). Your User Contributions are posted on and transmitted to others at your own risk. Although we limit access to certain pages, please be aware that no security measures are perfect or impenetrable. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons.</p>
        <p><em>Information We Collect Through Automatic Data Collection Technologies</em></p>
        <p>As you navigate through and interact with our Website, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:</p>
        <ul>
            <li>Details of your visits to our Website, including traffic data, location data, logs, and other communication data and the resources that you access and use on the Website.</li>
            <li>Information about your computer and internet connection, including your IP address, operating system, and browser type.</li>
        </ul>
        <p>The information we collect automatically may include personal information. It helps us to improve our Website and to deliver a better and more personalized service, including by enabling us to:</p>
        <ul>
            <li>Estimate our usage patterns.</li>
            <li>Store information about your preferences, allowing us to customize our Website according to your individual interests.</li>
            <li>Speed up your searches.</li>
            <li>Recognize you when you return to our Website.</li>
        </ul>
        <p>The technologies we use for this automatic data collection may include:</p>
        <ul>
            <li><strong> Cookies (or browser cookies).</strong>A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system may issue cookies when you direct your browser to our Website.&nbsp;</li>
            <li><strong> Web Beacons.</strong> Pages of our Website may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).&nbsp;</li>
        </ul>
        <h5>Third-Party Use of Cookies and Other Tracking Technologies</h5>
        <p>Some content or applications, including advertisements, on the Website are served by third-parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our website. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.&nbsp;</p>
        <p>We do not control these third parties&apos; tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. For information about how you can opt out of receiving targeted advertising from many providers, see <a href="#how-use-info">Choices About How We Use and Disclose Your Information</a>.</p>
        <h5>How We Use Your Information</h5>
        <p>We use information that we collect about you or that you provide to us, including any personal information:</p>
        <ul>
            <li>To present our Website and its contents to you.</li>
            <li>To provide you with information, products, or services that you request from us.</li>
            <li>To fulfill any other purpose for which you provide it.</li>
            <li>To provide you with notices about your account/subscription, including expiration and renewal notices.</li>
            <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
            <li>To notify you about changes to our Website or any products or services we offer or provide though it.</li>
            <li>To allow you to participate in interactive features on our Website.</li>
            <li>In any other way we may describe when you provide the information.</li>
            <li>For any other purpose with your consent.</li>
        </ul>
        <h5>Disclosure of Your Information</h5>
        <p>We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.&nbsp;</p>
        <p>We may disclose personal information that we collect or you provide as described in this privacy policy:</p>
        <ul>
            <li>To our subsidiaries and affiliates.</li>
            <li>To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.</li>
            <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of DolphinCare&apos;s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by DolphinCare about our Website users is among the assets transferred.</li>
            <li>To fulfill the purpose for which you provide it. For example, if you give us an email address to use the &quot;email a friend&quot; feature of our Website, we will transmit the contents of that email and your email address to the recipients.</li>
            <li>For any other purpose disclosed by us when you provide the information.</li>
            <li>With your consent.</li>
        </ul>
        <p>We may also disclose your personal information:</p>
        <ul>
            <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
            <li>To enforce or apply our terms of use and other agreements, including for billing and collection purposes.</li>
            <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of DolphinCare, our customers, or others.</li>
        </ul>
        <h5 id="how-use-info">Choices About How We Use and Disclose Your Information</h5>
        <p>We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:</p>
        <ul>
            <li><strong>Tracking Technologies and Advertising.</strong>You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies, please note that some parts of this site may then be inaccessible or not function properly.</li>
            <li><strong>Promotional Offers from the Company.</strong>If you do not wish to have your email address or contact information used by the Company to promote our own or third parties&apos; products or services, you can opt-out by checking the relevant box located on the form on which we collect your data (the registration form) or by sending us an email stating your request to&nbsp;<a href="mailto:support@dolphincare.ai">support@dolphincare.ai</a> or at any other time by logging into the Website and adjusting your user preferences in your account profile by checking or unchecking the relevant boxes or by sending us an email stating your request to&nbsp;<a href="mailto:support@dolphincare.ai">support@dolphincare.ai</a>. In addition, if we have sent you a promotional email, you may send us a return email asking to be omitted from future email distributions. This opt out does not apply to information provided to the Company as a result of a product purchase, warranty registration, product service experience or other transactions.</li>
        </ul>
        <p>We do not control third parties&apos; collection or use of your information to serve interest-based advertising. However these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (<strong>"NAI"</strong>") on the NAI&apos;s website.</p>
        <p>Residents of certain states, such as California, Nevada, Colorado, Connecticut, Virginia, and Utah may have additional personal information rights and choices. Please see <a href="#state-privacy-rights">Your State Privacy Rights</a> for more information.</p>

        <h5>Accessing and Correcting Your Information</h5>
        <p>You can review and change your personal information by sending us an email at <a href="mailto:support@dolphincare.ai">support@dolphincare.ai</a> to request access to, correct or delete any personal information that you have provided to us. We cannot delete your personal information except by also deleting your user account. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.</p>
        <p>Residents of certain states, such as California, Nevada, Colorado, Virginia, and Utah may have additional personal information rights and choices. Please see <a href="#state-privacy-rights">Your State Privacy Rights</a> for more information.</p>
        <h5 id="state-privacy-rights">Your State Privacy Rights</h5>
        <p>State consumer privacy laws may provide their residents with additional rights regarding our use of their personal information. To learn more about California residents&apos; privacy rights, visit <a href="https://oag.ca.gov/privacy/ccpa">https://oag.ca.gov/privacy/ccpa</a>. In addition, California's "Shine the Light" law (Civil Code Section &sect; 1798.83) permits users of our Website that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to <a href="mailto:support@dolphincare.ai">support@dolphincare.ai</a>.</p>
        <p>Colorado, Connecticut, Virginia, and Utah each provide their state residents with rights to:</p>
        <ul>
            <li>Confirm whether we process their personal information.</li>
            <li>Access and delete certain personal information.</li>
            <li>Data portability.</li>
            <li>Opt-out of personal data processing for targeted advertising and sales.</li>
        </ul>
        <p>Colorado, Connecticut, and Virginia also provide their state residents with rights to:</p>
        <ul>
            <li>Correct inaccuracies in their personal information, taking into account the information&apos;s nature processing purpose.</li>
            <li>Opt-out of profiling in furtherance of decisions that produce legal or similarly significant effects.&nbsp;</li>
        </ul>
        <p>To exercise any of these rights please send an email to <a href="mailto:support@dolphincare.ai">support@dolphincare.ai</a>. To appeal a decision regarding a consumer rights request please send an email to <a href="mailto:support@dolphincare.ai">support@dolphincare.ai</a>.</p>
        <p>Nevada provides its residents with a limited right to opt-out of certain personal information sales. Residents who wish to exercise this sale opt-out rights may submit an email to <a href="mailto:support@dolphincare.ai">support@dolphincare.ai</a>. However, please know we do not currently sell data triggering that statute's opt-out requirements.</p>

        <h5>Data Security</h5>
        <p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls. All information may be encrypted.</p>
        <p>The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.</p>
        <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.&nbsp;</p>
        <p>By using this website, you agree to grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, adapt, modify, distribute, and otherwise process any non-personally identifying data and information you provide on or through the website (“User Data”).
            We may use User Data to improve and develop one or more AI models, algorithms, and services. This may include incorporating User Data in AI training datasets, which may be used to train and refine our AI models, algorithms, and services. The AI models, algorithms, and services may be used to benefit you and other customers.
            We agree to take reasonable steps to protect the privacy and confidentiality of the User Data and will not knowingly use personally identifying information. Any User Data used for AI model training will be anonymized and scrubbed to the extent feasible, in compliance with any applicable data protection laws, including the Health Insurance Portability and Accountability Act.
            You acknowledge that the User Data may be used by us or third-parties under contract with us, for the purpose of training, refining, and enhancing our AI models, algorithms, and services.
            You retain ownership of the User Data, and this license does not grant us ownership or intellectual property rights in the User Data.</p>
        
        <h5 id="changes-to-policy">Changes to Our Privacy Policy</h5>
        <p>It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to how we treat our users' personal information, we will notify you via email. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Website and this privacy policy to check for any changes.</p>

        <h5>Contact Information</h5>
        <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at:</p>
        <p>Divrei Khayim 14, <br /> Jerusalem, Israel 9447916 <br /> or <br /> <a href="mailto:support@dolphincare.ai">support@dolphincare.ai</a></p>
    </div>
    </Wrapper>
}
export default PrivacyPolicy