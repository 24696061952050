import React, { useEffect, useState } from 'react';
import { Form, Spinner, Button, Col, Row } from 'react-bootstrap';
import isNullorEmpty from '../Utilities/isNullorEmpty';
import dynamicSort from '../Utilities/SortTable';
import {capitalize, cloneDeep} from 'lodash';
import axios from 'axios';
import Config from '../Utilities/Config'; 

const Groups = (props) => {   
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)    
    const [groups, setGroups] = useState(null)
    const [priorities, setPriorities] = useState(null)

    const numbers = [0,1,2,3,4,5]

    useEffect(() => {
        if (!isNullorEmpty(props.groups)) {
            setGroups(props.groups.sort(dynamicSort('group_code')))  
            setPriorities(props.groups.filter(g => g.priority > 0).map(p => ({group_code:p.group_code,priority:p.priority})).sort(dynamicSort('priority')))        
            setLoading(false)            
        }
    }, [props.groups])

    const updatePriority = (value, field, index) => {
        let prioritiesCopy = cloneDeep(priorities)
        if(field === "group_code"){
            prioritiesCopy = prioritiesCopy.map(p => { return {'group_code' : p.group_code === value ? '' : p.group_code, 'priority' : p.group_code === value ? 0 : p.priority} })
        }
        prioritiesCopy[index][field] = value           
        setPriorities(prioritiesCopy)
    }

    const updateAllPriorities = () => {    
        setLoading(true)   
        let updates = priorities.filter(p => !isNullorEmpty(p.group_code))
        groups.map(g => {
            if(g.priority > 0 && updates.findIndex(u => u.group_code === g.group_code) === -1){
                updates.push({...g, 'priority': 0})
            }
        })
        axios.put(`/api/internal/updatepriorities/`, {'groups': updates}, Config()).then((res) => {            
            props.getGroups()
            setLoading(false)             
        }).catch(err => {   
            console.log(err);
            setError(`Couldn't update priorities: ${err.response.data}`)
            setLoading(false) 
        })
    }

    const addAnother = () => {
        let prioritiesCopy = cloneDeep(priorities)
        prioritiesCopy.push({'group_code': '', 'priority': 0})
        setPriorities(prioritiesCopy)
    }

    return <div className='usersInfo admin-groups'>
        {loading ? <Spinner animation='border' variant='dark' /> :
            error ? <div>{error}</div> :
                groups && <div>
                    <Form className='wider'>
                        <div className='header'>
                            <h2>QA Group Priorities</h2>                      
                            <Button onClick={() => updateAllPriorities()}>Update All</Button>    
                        </div>                       
                        {priorities && priorities.map((p,i) => {
                            return <Row key={i} className="mb-3">
                                <Col xs="auto">
                                <span className='priority'>
                                    <Form.Label>Priority #</Form.Label>                               
                                    <Form.Control as="select"
                                    value={p.priority}
                                    onChange={(e) => updatePriority(e.target.value, 'priority', i)} >
                                    {numbers.map(n => <option key={n} value={n}>{n}</option>)}
                                    </Form.Control>
                                </span>
                                </Col>
                                <Col>
                                    <Form.Control as="select"
                                    value={p.group_code}
                                    onChange={(e) => updatePriority(e.target.value, 'group_code', i)} >
                                    <option value="">Select a Group</option>
                                    {groups.map(g => <option key={g.group_code} value={g.group_code}>{capitalize(g.name)} ({g.group_code})</option>)}
                                    </Form.Control>
                                </Col>
                            </Row>
                        })}   
                        <Button onClick={() => addAnother()}>Add Priority</Button>                
                    </Form>
                </div>
        }
    </div>

}
export default Groups