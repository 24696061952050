import React from "react";
import Spinner from "./Spinner";
import { Button } from 'react-bootstrap';

const CustomModal = props => {

    const customStyle = {
        width: props.width ? props.width : '40%',
        minWidth: '500px',
        height: props.height ? props.height : 'fit-content',
        minHeight: props.height ? props.height : '',
        maxHeight: props.maxHeight ? props.maxHeight : ''
    }
    //possible props: title, loading, closeModal, onMouseDown, onSave, saveBtnTxt, noFooter, customBody, backArrow, cancelBtnText, next, 
    //disableSave, backArrowFunc

    const closeButton = (onclick, onmousedown) => {
        return props.backArrow ? <span className="back" onClick={onclick}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.875 15.3L0.275 8.69996C0.175 8.59996 0.104 8.49163 0.0619998 8.37496C0.0206665 8.25829 0 8.13329 0 7.99996C0 7.86663 0.0206665 7.74163 0.0619998 7.62496C0.104 7.50829 0.175 7.39996 0.275 7.29996L6.875 0.69996C7.05833 0.516627 7.28733 0.420627 7.562 0.411961C7.83733 0.403961 8.075 0.49996 8.275 0.69996C8.475 0.883294 8.57933 1.11229 8.588 1.38696C8.596 1.66229 8.5 1.89996 8.3 2.09996L3.4 6.99996H14.575C14.8583 6.99996 15.096 7.09563 15.288 7.28696C15.4793 7.47896 15.575 7.71663 15.575 7.99996C15.575 8.28329 15.4793 8.52063 15.288 8.71196C15.096 8.90396 14.8583 8.99996 14.575 8.99996H3.4L8.3 13.9C8.48333 14.0833 8.57933 14.3166 8.588 14.6C8.596 14.8833 8.5 15.1166 8.3 15.3C8.11667 15.5 7.88333 15.6 7.6 15.6C7.31667 15.6 7.075 15.5 6.875 15.3Z" fill="white"/>
        </svg>
        </span> :        
        <span className="close">
        <svg onClick={onclick} onMouseDown={onmousedown} width="20" height="20" viewBox="0 0 10 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 5.93337L1.73334 9.20003C1.61111 9.32225 1.45556 9.38337 1.26667 9.38337C1.07778 9.38337 0.922224 9.32225 0.800002 9.20003C0.67778 9.07781 0.616669 8.92226 0.616669 8.73337C0.616669 8.54448 0.67778 8.38892 0.800002 8.2667L4.06667 5.00003L0.800002 1.73337C0.67778 1.61114 0.616669 1.45559 0.616669 1.2667C0.616669 1.07781 0.67778 0.922255 0.800002 0.800032C0.922224 0.67781 1.07778 0.616699 1.26667 0.616699C1.45556 0.616699 1.61111 0.67781 1.73334 0.800032L5 4.0667L8.26667 0.800032C8.38889 0.67781 8.54445 0.616699 8.73334 0.616699C8.92222 0.616699 9.07778 0.67781 9.2 0.800032C9.32222 0.922255 9.38334 1.07781 9.38334 1.2667C9.38334 1.45559 9.32222 1.61114 9.2 1.73337L5.93334 5.00003L9.2 8.2667C9.32222 8.38892 9.38334 8.54448 9.38334 8.73337C9.38334 8.92226 9.32222 9.07781 9.2 9.20003C9.07778 9.32225 8.92222 9.38337 8.73334 9.38337C8.54445 9.38337 8.38889 9.32225 8.26667 9.20003L5 5.93337Z" fill="currentColor" />
        </svg>
        </span>
    }
    
    return <div className="custom-modal" id="custom-modal">
        <div style={customStyle} className="modal-container">        
            {props.title && <div className="header">
                {props.title && props.closeModal && closeButton(props.backArrowFunc ? props.backArrowFunc : props.closeModal, props.onMouseDown ? props.onMouseDown : undefined)}
                <h4>{props.title}</h4>                
            </div>}         
            {!props.title && props.closeModal && <span className="close-cont">{closeButton(props.backArrowFunc ? props.backArrowFunc : props.closeModal, props.onMouseDown ? props.onMouseDown : undefined)}</span>}
            {props.customBody ? props.children :  
            <div className="body">
                {props.loading ? <div className="spinner"><Spinner /></div> : props.children}
            </div>}
            {!props.noFooter && <div className="footer">
                {(props.onSave || props.next) && <Button type="submit" id="save-btn" onMouseDown={props.onMouseDown ? props.onMouseDown : undefined} onClick={props.next ? props.next : props.onSave} disabled={props.loading || props.disableSave}>{props.saveBtnTxt ? props.saveBtnTxt : "SAVE"}</Button>}
                {props.closeModal && <button type="button" className="cancel-btn" onMouseDown={props.onMouseDown ? props.onMouseDown : undefined}  onClick={props.closeModal} disabled={props.loading}>{props.cancelBtnTxt ? props.cancelBtnTxt : "CANCEL"}</button>}
            </div>}     
        </div>
    </div>
} 


export default CustomModal;