import axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import { Button, Row, Col, Toast } from 'react-bootstrap';
import Config from "../../Utilities/Config";
import ClientUserPopup from "../ClientUserPopup";
import { Title } from "../SideBar";
import TableScrollBar from 'react-table-scrollbar';
import { startCase } from "lodash";
import Spinner from "../../Utilities/Spinner";
import { loadAllFacilities } from '../../redux/globalfunctions';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, Link } from "react-router-dom";
import isNullorEmpty from "../../Utilities/isNullorEmpty";
import Payment from "./PaymentForm";


const PendingFacilities = props => {
    const facilities = useSelector(state => state.globalState.allFacilities)
    const user = useSelector(state => state.globalState.user)
    const [error, setError] = useState(null)
    const [subscribePopup, setSubscribePopup] = useState(null)
    const [stagedFacilities, setStagedFacilities] = useState([])
    const [showPaymentForm, setShowPaymentForm] = useState(null)
    const dispatch = useDispatch()
    //needs to be checked
    const subFacility = props.subFacility
    const [saving, setSaving] = useState(false)
    const [showDisabledFacilities, setShowDisabledFacilities] = useState(false)
    const [loading, setLoading] = useState(false)
    // useEffect(() => {
    //     console.log(props)
    // }, [props])

    useEffect(() => {
        console.log("loading, show disabled: ", showDisabledFacilities) 
        console.log("pending facilities: ", facilities.filter(f=>f.is_active === 2) )           
    }, [showDisabledFacilities, stagedFacilities])

    useEffect(() => {
        if(!saving)
        {
            setStagedFacilities([]) 
            setLoading(false)
        }
      
    }, [facilities])


    const changeActiveStatus = (f,status) => {
        setSaving(true)
        setLoading(true)
        setSubscribePopup(null)
        setStagedFacilities([...stagedFacilities, f.facilityid])
        if(status===1)
            closeConfirmPopUp(f.facilityid)
        axios.put(`/api/activatefacilities/`, { facilityId: f.facilityid, activeStatus: status, groupCode: f.group, subscriptionId: subFacility}, Config()).then(res => {
            props.reloadBillingDetails()
            setSaving(false)
        }).catch(err => {
            setError(`Couldn't change facility active status`)
            setSaving(false)
        })
    }



    const closeConfirmPopUp = (facilityId) => {
        if(props.billingPerGroup){
            setSubscribePopup(null)
            document.getElementById(`popUpContainer_${facilityId}`).style.display = 'none';
        }
        else{
            setShowPaymentForm(null)
        }        
    }

    const subscribeFacility = (facilityid) => {
        if(props.billingPerGroup){
            // document.getElementById(`popUpContainer_${facilityid}`).classList.remove("dc_hide")

            setSubscribePopup(facilityid)
        }
        else{
            setShowPaymentForm(facilityid)
        }        
    }

    const SubscribeSvg = () => <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="subscribeIcon">
        <path d="M10.0417 8.91675V11.0417H7.91667V12.4584H10.0417V14.5834H11.4583V12.4584H13.5833V11.0417H11.4583V8.91675M6.5 0.416748C6.12428 0.416748 5.76394 0.566004 5.49827 0.83168C5.23259 1.09736 5.08333 1.45769 5.08333 1.83341C5.07836 1.9018 5.07836 1.97045 5.08333 2.03883C3.04333 2.64091 1.54167 4.53925 1.54167 6.79175V11.0417L0.125 12.4584V13.1667H6.74792C6.5854 12.712 6.50156 12.233 6.5 11.7501C6.5 10.6229 6.94777 9.54191 7.7448 8.74488C8.54183 7.94785 9.62283 7.50008 10.75 7.50008C10.9875 7.5015 11.2244 7.52283 11.4583 7.56383V6.79175C11.4583 4.53925 9.95667 2.64091 7.91667 2.03883C7.92164 1.97045 7.92164 1.9018 7.91667 1.83341C7.91667 1.45769 7.76741 1.09736 7.50173 0.83168C7.23606 0.566004 6.87572 0.416748 6.5 0.416748ZM5.08333 13.8751C5.08333 14.2508 5.23259 14.6111 5.49827 14.8768C5.76394 15.1425 6.12428 15.2917 6.5 15.2917C6.73022 15.2917 6.95697 15.2356 7.16062 15.1282C7.36426 15.0208 7.53867 14.8655 7.66875 14.6755C7.43969 14.433 7.23998 14.1643 7.07375 13.8751H5.08333Z" fill="white" />
    </svg>

    const CloseSvg = () => <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.711277 0.711216C0.798363 0.62391 0.901817 0.554642 1.01571 0.50738C1.12961 0.460118 1.25171 0.435791 1.37503 0.435791C1.49834 0.435791 1.62044 0.460118 1.73434 0.50738C1.84824 0.554642 1.95169 0.62391 2.03878 0.711216L7.00003 5.67434L11.9613 0.711216C12.0484 0.624051 12.1519 0.554908 12.2658 0.507735C12.3797 0.460562 12.5018 0.436282 12.625 0.436282C12.7483 0.436282 12.8704 0.460562 12.9842 0.507735C13.0981 0.554908 13.2016 0.624051 13.2888 0.711216C13.3759 0.798381 13.4451 0.901861 13.4923 1.01575C13.5394 1.12963 13.5637 1.2517 13.5637 1.37497C13.5637 1.49824 13.5394 1.6203 13.4923 1.73419C13.4451 1.84807 13.3759 1.95155 13.2888 2.03872L8.32565 6.99997L13.2888 11.9612C13.3759 12.0484 13.4451 12.1519 13.4923 12.2657C13.5394 12.3796 13.5637 12.5017 13.5637 12.625C13.5637 12.7482 13.5394 12.8703 13.4923 12.9842C13.4451 13.0981 13.3759 13.2016 13.2888 13.2887C13.2016 13.3759 13.0981 13.445 12.9842 13.4922C12.8704 13.5394 12.7483 13.5637 12.625 13.5637C12.5018 13.5637 12.3797 13.5394 12.2658 13.4922C12.1519 13.445 12.0484 13.3759 11.9613 13.2887L7.00003 8.32559L2.03878 13.2887C1.95161 13.3759 1.84813 13.445 1.73425 13.4922C1.62036 13.5394 1.4983 13.5637 1.37503 13.5637C1.25176 13.5637 1.12969 13.5394 1.01581 13.4922C0.901922 13.445 0.798442 13.3759 0.711277 13.2887C0.624112 13.2016 0.554969 13.0981 0.507796 12.9842C0.460623 12.8703 0.436343 12.7482 0.436343 12.625C0.436343 12.5017 0.460623 12.3796 0.507796 12.2657C0.554969 12.1519 0.624112 12.0484 0.711277 11.9612L5.6744 6.99997L0.711277 2.03872C0.623971 1.95163 0.554703 1.84818 0.507441 1.73428C0.460179 1.62038 0.435852 1.49828 0.435852 1.37497C0.435852 1.25165 0.460179 1.12955 0.507441 1.01565C0.554703 0.901756 0.623971 0.798302 0.711277 0.711216Z" fill="#1A3B5C" />
    </svg>

    const FacilitySvg = () => <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="facilityIcon">
        <path d="M1.75 21.75H3V5.95C3.00004 5.55643 3.12392 5.17284 3.3541 4.85359C3.58429 4.53434 3.90909 4.29562 4.2825 4.17125L14.2825 0.838752C14.5643 0.744886 14.8644 0.719322 15.1581 0.764165C15.4517 0.809008 15.7305 0.922975 15.9714 1.09668C16.2124 1.27038 16.4087 1.49885 16.544 1.76326C16.6794 2.02767 16.75 2.32046 16.75 2.6175V21.75H18V10.4875C18.0001 10.3951 18.0206 10.3038 18.0602 10.2203C18.0998 10.1367 18.1574 10.063 18.2289 10.0044C18.3004 9.94586 18.384 9.90386 18.4737 9.88148C18.5633 9.8591 18.6569 9.85689 18.7475 9.875L21.4925 10.425C21.9174 10.5099 22.2998 10.7394 22.5746 11.0744C22.8494 11.4094 22.9997 11.8292 23 12.2625V21.75H24.25C24.5815 21.75 24.8995 21.8817 25.1339 22.1161C25.3683 22.3505 25.5 22.6685 25.5 23C25.5 23.3315 25.3683 23.6495 25.1339 23.8839C24.8995 24.1183 24.5815 24.25 24.25 24.25H1.75C1.41848 24.25 1.10054 24.1183 0.866116 23.8839C0.631696 23.6495 0.5 23.3315 0.5 23C0.5 22.6685 0.631696 22.3505 0.866116 22.1161C1.10054 21.8817 1.41848 21.75 1.75 21.75Z" />
    </svg>

    return <>


        {(facilities.find(f => f.is_active === 2) || facilities.find(f => f.is_active === 0)) &&
            <div className="billingTablecont pendingFac">
                <div className="facilityheader pending">PENDING FACILITIES</div> 
                {(facilities.find(f => f.is_active === 0)) && 
                <div id="disabled_fac_btn" class="disabled_fac_btn" onClick={() => {setShowDisabledFacilities(!showDisabledFacilities)}}>{showDisabledFacilities ? "Exclude" : "Include"} Disabled Facilities</div>}
                 {saving && <div class="pending_fac_spinner"><Spinner/></div>}  
                <TableScrollBar height='calc(100% - 50px)'>
                    {!isNullorEmpty(showPaymentForm) && <Payment facility={showPaymentForm} onClose={() => closeConfirmPopUp(null)} new={true} reloadBillingDetails={props.reloadBillingDetails}/>}
                    <table>
                        <tbody>                            
                            {facilities.map((f) =>
                            //  ((f.is_active ==0 && showDisabledFacilities || f.is_active == 2)) && 
                             (!stagedFacilities.some(item => item === f.facilityid) && (f.is_active ==0 && showDisabledFacilities || f.is_active == 2)) && 
                                // f.is_active == 2 && !stagedFacilities.some(item => item === f.facilityid)&&
                                <div key={f.facilityid} id={f.facilityid} >

                                    <div className="facilityActions">
                                        <div className="facWrapper">
                                            <div className="facility"><FacilitySvg /></div>
                                            <div className="facilityName" id="pending-fac-name">{f.facility_name}</div>
                                        </div>
                                    </div>
                                    <div className="outerDiv">
                                        {/* <div className="subBtn faciltyBtn" onClick={() => subscribeFacility(f.facilityid)}><span><SubscribeSvg />Subscribe</span></div> */}
                                        <button className={`subBtn faciltyBtn ${loading ? 'disabled_sub' : ''}`} disabled={saving} onClick={() => subscribeFacility(f.facilityid)}><span><SubscribeSvg />Subscribe</span></button>
                                        {/* <button className={`subBtn faciltyBtn ${saving ? 'disabled_sub' : ''}`} disabled={saving} onClick={() => subscribeFacility(f.facilityid)}><span><SubscribeSvg />Subscribe</span></button> */}
                                    </div>
                                    {f.facilityid === subscribePopup && 
                                    <div id={`popUpContainer_${f.facilityid}`} className="subPopUpContainer">
                                        <div className="confirmPopup" id="subPopup">
                                            <div className="popupConfirmContainer" >
                                                <div className="closePopup" onClick={() => closeConfirmPopUp(f.facilityid)}><CloseSvg /></div>
                                                <div className="facilityheader">SUBSCRIBE</div>
                                                <p>Add <span>{f.facility_name}</span> for a recurring cost of ${user.billing_price} a month</p>
                                                <div className="subBtn subPopUpBtn" onClick={() => changeActiveStatus(f,1)}><span><SubscribeSvg />Confirm Subscribtion</span></div>
                                            </div>
                                        </div>
                                    </div>}
                                    {/* {(f.is_active == 2) && */}
                                    {(f.is_active == 2 && !stagedFacilities.some(item => item === f.facilityid)) &&
                                    <div className="denyBtn" onClick={() => changeActiveStatus(f,0)}>No Thanks</div>}
                                </div>
                                
                            )}
                        </tbody>
                    </table>
                </TableScrollBar>
            </div>

        }
    </>
}

export default PendingFacilities; 