import RegisterForm from './UserPages/RegisterForm';
import Client from './Client';
import Admin from './Admin';
import PrivacyPolicy from './UserPages/PrivacyPolicy';
import TermsOfUse from './UserPages/TermsOfUse';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router';
import QaDashboard from './QaDashboard';
import Document from './UserInterface/Document';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import isNullorEmpty from './Utilities/isNullorEmpty';

const App = props => {
    const [searchParams, setSearchParams] = useSearchParams();
    const routerLocation = useLocation()
    const routerNavigate = useNavigate()

    useEffect(() => {
        if (searchParams.has("dc_token")) {
            window.localStorage.setItem('dc_token', searchParams.get("dc_token"));
            searchParams.delete('dc_token')
        }
        if (searchParams.has("dc_browser")) {
            window.localStorage.setItem('dc_browser', searchParams.get("dc_browser"));
            searchParams.delete('dc_browser')
        }
        setSearchParams(searchParams)
        // navigate(routerLocation.pathname, { replace: true })
        if(routerLocation.pathname === "/" || routerLocation.pathname === ""){ routerNavigate(window.location.hostname.includes('internal') ? 'admin' : 'client') }   

    }, [])

    return <Routes>
        <Route exact path='/' element={<Navigate to={window.location.hostname.includes('internal') ? '/admin' : '/client'} />} />
        <Route exact path='' element={<Navigate to={window.location.hostname.includes('internal') ? '/admin' : '/client'} />} />


        <Route path='/admin/*' element={<Admin />} />
        <Route path='/client/*' element={<Client idle={props.idle} />} />
        <Route path='/document/:type/:id' element={<Document />} />
        <Route path='/document/:type/:id/:feature' element={<Document />} />


        <Route path='/qadashboard' element={<QaDashboard />} />

        <Route path='/register' element={<RegisterForm />} />
        <Route path='/privacypolicy' element={<PrivacyPolicy />} />
        <Route path='/termsofuse' element={<TermsOfUse />} />
    </Routes>

}

export default App;


{/* <div className="App">
        <header><img src={dolphinCareLogo} /></header>
    <div className='main'></div>
        
    </div>
        <img className='icon' src={dolphinCareLogoIcon} />
    </div> */}