import React, {useState} from "react";
import { Route, Routes } from "react-router";
import { useSelector } from "react-redux";
import {Navigate} from 'react-router-dom';
import IVList from "./IVList";
import SideBar, { ClientHeader } from './SideBar'
import ClientUsers from "./ClientUsers";
import AppPreferences from "./AppPreferences";
import ClientBilling from "./Billing/Billing";
import ParentBilling from "./Billing/ParentBilling";
import isNullorEmpty from "../Utilities/isNullorEmpty";

const UserInterface = props => {

    const user = useSelector(globalState => globalState.globalState.user)
    const routeDetails = () => [{path: 'history', element: <IVList />, permission_field: 'p_view_history', permission_value: user.p_view_history},
    {path: 'billing', element: <ParentBilling childGroups={props.childGroups}/>, permission_field: 'p_billing', permission_value: user.p_billing},
    {path: 'users', element: <ClientUsers />, permission_field: 'p_user_management', permission_value: user.p_user_management},
    {path: 'settings', element: <AppPreferences />, permission_field: 'p_edit_preferences', permission_value: user.p_edit_preferences},]

    return <div className="userinterface">
        <SideBar billing={props.billing} noActiveFacs={props.noActiveFacs}/>
        <ClientHeader/>
        <Routes>        
            {['/', '', 'signin'].map(p => <Route key={p} path={p} element={<Navigate to={`/client/${routeDetails().filter(r => r.permission_value === 1)[0].path}`} replace={true} />} />)}     
            {routeDetails().filter(r => r.permission_value === 1).map(r => <Route key={r.path} path={r.path} element={r.element} />)}    
        </Routes>
    </div>
}

export default UserInterface