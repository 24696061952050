import { capitalize } from "lodash"
import isNullorEmpty from './isNullorEmpty';

const Validation = (value, rules) => {
    let validity = '';
    if (isNullorEmpty(value)) {
        value = ''
    }
    if (rules.isNumeric) {
        // const pattern = /^\d+$/;
        // validity = pattern.test(value)
        //     ? validity
        //     : validity === ''
        //         ? '{field} must only contain numbers.'
        //         : validity + '\n{field} must only contain numbers.'
        value = value.replace(/[^0-9]/gi, '');
    }
    if (rules.requiredLength) {
        validity = value.length === rules.requiredLength
            ? validity
            : `{field} must be ${rules.requiredLength} digits.`;
    }
    if (rules.isState){
        const abc = /^[A-Za-z]+$/;
        validity = abc.test(value) && value.length == 2 ? validity : 'Invalid state format'    
    }
    if (rules.bluesnapAddress){
        validity = (value.length < 2 || value.length > 42) ? 'Invalid address length' : validity
    }
    if (rules.required) {
        if (typeof (value) === "number") {
            return validity;
        }

        validity = value && value.trim() !== ''
            ? validity
            : '{field} cannot be blank.';
    }
    const pattern = /^[\w\.\-\+]+@([\w\-]+\.)+[\w]{2,4}$/;
    if (rules.isEmail) {
        validity = pattern.test(value.toLowerCase())
            ? validity
            : 'Invalid email address';
    } 
    if (rules.isPassword) {
        // const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@\\/$%^&*-]).{8,}$/;
        // validity = pattern.test(value)
        //     ? validity
        //     : 'Does not meet password requirements.';        
        validity = value.length < 8 ? 'Password must be at least 8 characters' : validity
        
    } 
    return capitalize(validity);
}

export const stripNotNumbers = (value, leaveCommas) => { return value.replace(leaveCommas ? /[^-,0-9]/gi : /[^0-9]/gi, ''); }

export default Validation;