import React, { useEffect, useState, Fragment } from "react";
import { Title } from "./SideBar";
import TableScrollBar from 'react-table-scrollbar';
import { Button, Form } from 'react-bootstrap';
import { useSelector } from "react-redux";
import isNullorEmpty from "../Utilities/isNullorEmpty";
import Spinner from "../Utilities/Spinner";
import axios from "axios";
import Config from "../Utilities/Config";
import Validation, { stripNotNumbers } from "../Utilities/Validation";
import {capitalize} from 'lodash';

const AppPreferences = props => {
    const currentColor = '#163b5c'
    const [loading, setLoading] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const [currentPreferences, setCurrentPreferences] = useState({
        code_low_dextrose: 0, scan_connect_pref: 0, days_after_admission: 3, show_mismatch_popup_pref: 0
    })
    const user = useSelector(globalState => globalState.globalState.user)


    useEffect(() => {
        if(user){
            const prefCopy = {...currentPreferences}
            for (let [key, value] of Object.entries(currentPreferences)) {
                prefCopy[key] = user[key];
            }
            setCurrentPreferences(prefCopy)
        }
    }, [user]) 

    const inputChanged = (value, field) => {
        let prefCopy = {...currentPreferences}
        value = field === "days_after_admission" ? stripNotNumbers(value) : value
        prefCopy[field] = value
        setCurrentPreferences(prefCopy)
    }

    const validate = (inputIdentifier) => {
        const err = Validation(currentPreferences[inputIdentifier], {required: true, isNumeric: true}).replace(/{field}/ig, inputIdentifier.replace(/_/g, ' '))
        setErrMsg(capitalize(err))
        return err;
    }

    const savePreferences = () => {  
        setErrMsg('')        
        if(!isNullorEmpty(validate('days_after_admission'))){
            return
        }
        setLoading(true)
        axios.put('/api/updateapppreferences/', currentPreferences, Config()).then(res => {
            setLoading(false)
        }).catch(err => {
            console.log(err.response);         
            setErrMsg("OOPS! Something went wrong. Contact support if error persists.")
            setLoading(false);         
        })  
    }

    const Unchecked = () => <span className="checkbox"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={currentColor} class="bi bi-square" viewBox="0 0 16 16">
  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
  </svg></span>

    const Checked = () => <span className="checkbox"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={currentColor} class="bi bi-check-square" viewBox="0 0 16 16">
    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
    <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
    </svg></span>   

    const RadioFilled = () => <span className="checkbox"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={currentColor} class="bi bi-record-circle" viewBox="0 0 16 16">
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
    <path d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
  </svg></span>

  const EmptyCircle = () => <span className="checkbox"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={currentColor} class="bi bi-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
</svg></span>

    const showCheckbox = (value, onclick, label) =>  {return value ? <span className="check" onClick={onclick} ><Checked/>{label}</span> : 
    <span className="check" onClick={onclick}><Unchecked/>{label}</span> }                
    
    
    return <div className="appsettings">
        {loading ? <Spinner/> : <Fragment>
            {/* <div className="title-header">                                
            </div>  */}
            <Title/>
            <div className="tablecont">
                <h5>App Preferences</h5>
                <div className="preference-cont">                            
                    <span className="row">
                        {showCheckbox(currentPreferences.code_low_dextrose == 1, () => {inputChanged(currentPreferences.code_low_dextrose == 1 ? 0 : 1, 'code_low_dextrose')}, 'Code IV fluids when dextrose is ordered prn as protocol')}
                    </span>
                    <span className="row">
                        {showCheckbox(currentPreferences.show_mismatch_popup_pref == 1, () => {inputChanged(currentPreferences.show_mismatch_popup_pref == 1 ? 0 : 1, 'show_mismatch_popup_pref')}, 'Prompt nurse feedback for non-matching responses')}
                    </span>
                    {user.has_connect > 0 && <Fragment>
                    <h6 className="coming-soon">PCC Connect Integration - Coming Soon</h6>
                    <h6>When to Scan Connect Docs</h6>
                    <span className="row">
                        {currentPreferences.scan_connect_pref == 0 ? <span><Checked/><Form.Control size="sm" value={currentPreferences.days_after_admission} onChange={e => inputChanged(e.target.value, 'days_after_admission')} /> days after admission</span> : 
                            <span onClick={() => inputChanged(0, 'scan_connect_pref')}><Unchecked/><Form.Control size="sm" value={currentPreferences.days_after_admission} disabled /> days after admission</span>}
                     </span>
                    <span className="row"> 
                        {currentPreferences.scan_connect_pref == 1 ? <span><Checked/>When admission MDS is created (only with data relay)</span> : 
                            user.has_backend == 1 ? <span onClick={() => inputChanged(1, 'scan_connect_pref')}><Unchecked/>When admission MDS is created (only with data relay)</span> : 
                            <span className="disabled"><Unchecked/>When admission MDS is created (only with data relay)</span>}
                    </span></Fragment>}
                </div>
                             
            </div>
            {!isNullorEmpty(errMsg) && <div>{errMsg}</div>}
            <Button onClick={() => savePreferences()}>Save</Button>                
          
        </Fragment>}
    </div>

 
}

export default AppPreferences;