import axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import Config from "../../Utilities/Config";
import Spinner from "../../Utilities/Spinner";
import { useDispatch, useSelector } from 'react-redux';
import ClientMainBilling from "./ClientMainBilling";
import ClientBillingFacility from "./ClientBillingFacility";
import { Button, Form } from 'react-bootstrap';
import Payment from "./PaymentForm";
import FacilitiesForm from "./FacilitiesForm";
import { Title } from "../SideBar";
import { loadAllFacilities, setCurrentGroup, setIsChildGroup } from "../../redux/globalfunctions";
import isNullorEmpty from "../../Utilities/isNullorEmpty";
import { BackArrow } from "../../Utilities/SvgImages";
import ProcessingPage from "./ProcessingPage";

// Current Component Structure:
// *Billing*: checks how group being billed - displays message if billing not set up. 
// 	    If billing == 1 (billing per group) > *ClientBillingFacility*, displays *PendingFacilities* 
// 	    If billing == 2 (per facility) > *ClientMainBilling*, displays *PendingFacilities*. when click edit goes to *ClientBillingFacility*, 
//      back button goes back to *ClientMainBilling*
// 	        *PendingFacilities* calls *PaymentForm* for subscribing new facility
// 	        *ClientBillingFacility* calls *PaymentForm* for editing cc info 
//test

const ClientBilling   = props => {

    const [loading, setLoading] = useState(true)
    const facilities = useSelector(state => state.globalState.allFacilities)
    const [billing, setBilling] = useState(0)
    const [error, setError] = useState(null)
    const [message, setMessage] = useState(null)
    const [showPaymentForm, setShowPaymentForm] = useState(false)
    const [showFacilityForm, setShowFacilityForm] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [selectedFacilities, setSelectedFacilities] = useState([])
    const [ranGetBilling, setRanGetBilling] = useState(false)
    const dispatch = useDispatch()
    const user = useSelector(state => state.globalState.user)
    const currentGroup = useSelector(state => state.globalState.currentGroup)
    const isChildGroup = useSelector(state => state.globalState.isChildGroup)
    const [showProcessingPage, setShowProcessingPage] = useState(false)

    useEffect(() => { 
        if(!isChildGroup && isNullorEmpty(currentGroup) && user){
            dispatch(setCurrentGroup(user.group))
        }       
    },[user])

    const getGroupsBilling = () => {
        if (facilities.length > 0) {
            // const group = props.child ? props.child : user.group
            axios.get(`/api/groupbilling/${currentGroup}/`, Config()).then((res) => {
                // console.log("billing data: ", res.data)

                // if (typeof res.data !== 'object') {
                //     setError(res.data)
                //     setLoading(false)
                //     setRanGetBilling(true)
                // }
                if (res.data.billing !== 0) {
                    if(res.data.billing === 4){
                        setShowProcessingPage(true)
                        setLoading(false)
                        setRanGetBilling(true)
                    }
                    else{
                        setBilling(res.data.billing)
                        setLoading(false)
                        setRanGetBilling(true)
                    }
                }
                else {
                    setBilling(0)
                    calcTrialDate(res.data)
                }

            }).catch(err => {
                setError(err.response.status === 418 ? "Error - No billing info found for this group" : `Couldn't get group info for ${err.response.data}`)
                setLoading(false)
                setRanGetBilling(true)
                console.log(err);                
            })
        }
        else {
            setError("No facilities found in this group")
            setLoading(false)
            setRanGetBilling(true)
        }
    }

    const calcTrialDate=(data) => {
        if(data.billing_status=="Trialing") 
            {
                if(data.trial_start_date!==null)
                {
                var trialDate= new Date(data.trial_start_date)
                var endTrialDate = new Date(trialDate.getTime() + 12096e5);
                var today = new Date();
                let difference = endTrialDate.getTime() - today.getTime();
                let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
                console.log(TotalDays > 0)
                if(TotalDays > 0)
                    setMessage(`Your trial is ending in ${TotalDays} days`)
                }
                else{
                    setMessage("You are currently in the trial period")
                }
            }

                else if(data.billing_status=="Trial Ended")
                    setMessage("Your trial has ended") 
               setLoading(false)
               setRanGetBilling(true)
       }


    useEffect(() => {
    //    console.log("facilities ", facilities)
       if(!isNullorEmpty(facilities) && !isNullorEmpty(currentGroup) && !ranGetBilling)
        getGroupsBilling();        
    },[facilities, currentGroup])
 
    const selectBillingType = (type) => {
        setShowFacilityForm(type === 1)
        if(type == 2){
            saveNewBilling()
        }
    }

    const saveNewBilling = () => {
        setLoading(true)
        axios.post(`/api/activategroup/${currentGroup}/`, {'billingType': 2} ,Config()).then((res) => {
            getGroupsBilling()
            dispatch(loadAllFacilities())

        }).catch(err => {
            setLoading(false)
            console.log(err);
            setError(`Error updating billing. Contact support if error persists`)
        })
    }

    return <div className="billing">
        {loading ? <Spinner /> : <Fragment>
            {isChildGroup && <span onClick={() => {dispatch(setCurrentGroup(null)); dispatch(setIsChildGroup(false))}}><BackArrow/></span>} 
            {console.log(billing)}
            {(billing == 4 || showProcessingPage) ? <ProcessingPage/> :
            billing == 1 ? <ClientBillingFacility /> : billing == 2 ? <ClientMainBilling billingPage={false} /> :                
                error ? <div className="error-main">{error}</div> :     
                    <>
                        {showPaymentForm && <Payment onClose={() => { setShowPaymentForm(false); setShowFacilityForm(false); setSelectedFacilities([]) }} goBack={() => { setShowPaymentForm(false); setShowFacilityForm(true) }}
                            new={true} newGroup={true} facility={0} reloadBillingDetails={() => {setLoading(true);getGroupsBilling()}} quantity={selectedFacilities.length}
                            selectedFacilities={selectedFacilities} />}
                        {showFacilityForm && <FacilitiesForm facilities={facilities} setSelectedFacilities={setSelectedFacilities} selectedFacilities={selectedFacilities}
                            setShowPaymentForm={setShowPaymentForm} setShowFacilityForm={setShowFacilityForm} />}
                        {message &&
                            <div className="billing-message">
                            {!isChildGroup && showOptions && <span onClick={() => setShowOptions(false)}><BackArrow/></span>} 
                            <Title />
                            <div className="message-cont">
                                    
                                    {showOptions ? <Fragment>
                                        {/* <span onClick={() => setShowOptions(false)}><BackArrow/></span>      */}
                                        <div className="billing-text">How would you like to set up your billing?</div>
                                        <div className="billing-options">
                                            <button onClick={() => selectBillingType(1)}>Receive One Combined Invoice</button>                                
                                            <button onClick={() => selectBillingType(2)}>Receive a Separate Invoice Per Facility</button>
                                             {/* <span onClick={() => selectBillingType(1)}><Checkmark/> Receive One Combined Invoice</span>
                                            <span onClick={() => selectBillingType(2)}><Checkmark/> Receive a Seperate Invoice Per Facility</span> */}
                                        </div> 
                                    </Fragment> : <Fragment>
                                        <h6>{message}</h6>                 
                                        <div className="subtitle">Upgrade Your Subscription</div>
                                        <div className="billingTablecont">
                                            <div className="desc">DolphinCare Subscription</div>
                                            {/* update below for hvhc ------ */}
                                            {/* {user.billing_discount > 0 && <>
                                            <div className="desc">Special Discount</div>
                                            <div className="price"><span className="amount">${user.billing_discount}.00</span>/facility</div>
                                            <div className="price">Charged monthly for first 3 months, then</div>
                                            </>} */}
                                            <div className="price"><span className="amount">${user.billing_price}.00</span>/facility</div>
                                            <div className="price">Charged monthly</div>                                        
                                        </div>
                                        <button className="pay-now" onClick={() => setShowOptions(true)}>Upgrade Now</button>
                                    </Fragment>}
                            </div>
                            </div>}
                    </>}
              
                </Fragment>}
    </div>

}
export default ClientBilling  ;