import axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import { Button, Row, Col, Toast } from 'react-bootstrap';
import Config from "../Utilities/Config";
import ClientUserPopup from "./ClientUserPopup";
import { Title } from "./SideBar";
import TableScrollBar from 'react-table-scrollbar';
import { startCase } from "lodash";
import Spinner from "../Utilities/Spinner";
import dynamicSort from "../Utilities/SortTable";


const ClientUsers = props => {

    const [users, setUsers] = useState(null) 
    const [popupMode, setPopupMode] = useState(null)
    const [currentUser, setCurrentUser] = useState(null)    
    const [updatedPassword, setUpdatedPassword] = useState(false)
    const [addedUser, setAddedUser] = useState(false)
    const [loading, setLoading] = useState(true)
    const [allFacilities, setAllFacilities] = useState(null) 

    const getUsers = () => {
        axios.get(`/api/getclientusers/`, Config()).then(res => {
            console.log(res.data)
            setUsers(res.data)
        }).catch(err => {
            console.log(err.response);
        })
    }

    useEffect(() => {
        getUsers()
        axios.get(`/api/getallfacilitiesbygroup/`, Config()).then(res => {
            setAllFacilities(res.data.sort(dynamicSort('facility_name')))
        }).catch(err => {
            console.log(err.response);
        })    
    }, [])

    useEffect(() => {
        if (loading && users) {
            setLoading(false)
        }
    }, [users])


    const EditSvg = () => <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.0605 26.5604L23 12.6209L16.379 5.99994L2.4395 19.9394C2.2476 20.1316 2.11128 20.3721 2.045 20.6354L0.5 28.4999L8.363 26.9549C8.627 26.8889 8.8685 26.7524 9.0605 26.5604ZM27.5 8.12094C28.0624 7.55836 28.3784 6.79543 28.3784 5.99994C28.3784 5.20445 28.0624 4.44152 27.5 3.87894L25.121 1.49994C24.5584 0.937528 23.7955 0.621582 23 0.621582C22.2045 0.621582 21.4416 0.937528 20.879 1.49994L18.5 3.87894L25.121 10.4999L27.5 8.12094Z" fill="#81C8D0"/>
    </svg> 
    
    
    const AddSvg = () => <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 0.5C8.22562 0.533472 5.57431 1.65047 3.61239 3.61239C1.65047 5.57431 0.533472 8.22562 0.5 11C0.533472 13.7744 1.65047 16.4257 3.61239 18.3876C5.57431 20.3495 8.22562 21.4665 11 21.5C13.7744 21.4665 16.4257 20.3495 18.3876 18.3876C20.3495 16.4257 21.4665 13.7744 21.5 11C21.4665 8.22562 20.3495 5.57431 18.3876 3.61239C16.4257 1.65047 13.7744 0.533472 11 0.5ZM17 11.75H11.75V17H10.25V11.75H5V10.25H10.25V5H11.75V10.25H17V11.75Z" fill="white"/>
    </svg>
    
    
    // const saveSvg = <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    // <path d="M0.833252 4.72226C0.833252 3.69087 1.24297 2.70171 1.97228 1.9724C2.70159 1.24309 3.69074 0.833374 4.72214 0.833374H6.66659V7.63893C6.66659 8.41248 6.97388 9.15434 7.52086 9.70133C8.06784 10.2483 8.8097 10.5556 9.58325 10.5556H17.361C18.1346 10.5556 18.8764 10.2483 19.4234 9.70133C19.9704 9.15434 20.2777 8.41248 20.2777 7.63893V0.833374H21.0146C22.046 0.833594 23.0349 1.24346 23.7641 1.97282L26.916 5.12476C27.6454 5.8539 28.0553 6.84289 28.0555 7.87421V24.1667C28.0555 25.1981 27.6458 26.1873 26.9164 26.9166C26.1871 27.6459 25.198 28.0556 24.1666 28.0556V17.3612C24.1666 16.5876 23.8593 15.8457 23.3123 15.2988C22.7653 14.7518 22.0235 14.4445 21.2499 14.4445H7.63881C6.86526 14.4445 6.12339 14.7518 5.57641 15.2988C5.02943 15.8457 4.72214 16.5876 4.72214 17.3612V28.0556C3.69074 28.0556 2.70159 27.6459 1.97228 26.9166C1.24297 26.1873 0.833252 25.1981 0.833252 24.1667V4.72226ZM18.3333 0.833374H8.61103V7.63893C8.61103 7.89678 8.71346 8.14407 8.89579 8.32639C9.07811 8.50872 9.3254 8.61115 9.58325 8.61115H17.361C17.6189 8.61115 17.8662 8.50872 18.0485 8.32639C18.2308 8.14407 18.3333 7.89678 18.3333 7.63893V0.833374ZM22.2221 17.3612V28.0556H6.66659V17.3612C6.66659 17.1033 6.76902 16.856 6.95134 16.6737C7.13367 16.4914 7.38096 16.3889 7.63881 16.3889H21.2499C21.5078 16.3889 21.7551 16.4914 21.9374 16.6737C22.1197 16.856 22.2221 17.1033 22.2221 17.3612Z" fill="#81C8D0"/>
    // </svg>

    const editUser = (user, mode) => {
        setPopupMode(mode)
        setCurrentUser(user)     
    }

    const checkRow = (i,sum) => {
        return parseFloat(((i+1)/3 - Math.floor((i+1)/3)).toFixed(2)) === sum
    }

    const setColor = (i) => {
        return checkRow(i,.33) ? "purple" : checkRow(i,.67) ? "green" : "blue"
    }

    return <div className="clientusers">
        {popupMode && <ClientUserPopup mode={popupMode} currentUser={currentUser} setMode={setPopupMode} refreshUsers={getUsers} setUpdatedPassword={setUpdatedPassword} setAddedUser={setAddedUser} allFacilities={allFacilities} /> }
        {loading ? <Spinner /> : <Fragment><Title/>
            <button className="add-btn" onClick={() => editUser(null,'new')}>
                <span className="add-txt"><AddSvg/>&nbsp;Add new user</span>
            </button>            
            <Row>
                <Col xs={6} className="mb-2 mt-2">
                    <Toast onClose={() => setUpdatedPassword(false)} show={updatedPassword} delay={5000} autohide>
                        <Toast.Body>Password Updated Successfully</Toast.Body>
                    </Toast>
                    <Toast onClose={() => setAddedUser(false)} show={addedUser} delay={4000} autohide>
                        <Toast.Body>Invite Successfully Sent</Toast.Body>
                    </Toast>
                </Col>
            </Row>

           
            {users && <div className="tablecont">
                <TableScrollBar height='100%'><table>
                    <tbody>
                        {users.map((u, i) => 
                            <tr key={u.id} className={!u.is_active ? "disabled" : ""}>
                                <td><span className="clientheader user"><span className={!u.is_active ? "circle grey" :  "circle " + setColor(i)}>{u.first_name[0].toUpperCase()+u.last_name[0].toUpperCase()}</span></span></td>
                                <td>{startCase(u.first_name) + " " + startCase(u.last_name)}</td>
                                <td className="underline"><a href={"mailto:"+u.email}>{u.email}</a></td>
                                <td>{!u.is_active ? "Disabled" : (u.is_staff ? "Admin" : "User")}</td>
                                <td className="underline">{u.is_active && <a onClick={() => editUser(u,"changepass")}>Reset Password</a>}</td>
                                <td className="edituser"><span onClick={() => editUser(u,"edit")}><EditSvg/></span></td>
                            </tr>
                        )}                
                    </tbody>
                </table></TableScrollBar> 
            </div>} </Fragment>}
    </div>
}

export default ClientUsers;