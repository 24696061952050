import {Buffer} from 'buffer';



export const getPdfUrl = data => {
    const blob = new Blob([Buffer.from(data, 'base64')], { type: 'application/pdf' });
    return window.URL.createObjectURL(blob)
}


export const getHtmlUrl = html => {
    const blob = new Blob([Buffer.from([html])], { type: 'text/html' });
    return window.URL.createObjectURL(blob)
    
}