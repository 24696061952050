import React, { useEffect, useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import isNullorEmpty from '../Utilities/isNullorEmpty';
import Config from '../Utilities/Config';
import { formatDateTime, formatDate } from '../Utilities/FormatDate';
import dynamicSort from '../Utilities/SortTable';
import { defaultCursor, waitCursor } from '../Utilities/Cursor';
import GroupFilter from './GroupFilter';
import {capitalize} from 'lodash';

const today = new Date();
//today.setHours(0,0,0,0);

const SelectServices = () => {

    const [loading, setLoading] = useState(true)
    const [instances, setInstances] = useState(null)
    const [databases, setDatabases] = useState(null)
    const [targetGroups, setTargetGroups] = useState(null)
    const [instancesHealth, setInstancesHealth] = useState(null)
    const [error, setError] = useState(null)
    const [sortField, setSortField] = useState("last_name")
    const [completedAction, setCompletedAction] = useState(false)
    

    const changeSortField = field => {
        setSortField(sortField === field ? `-${field}` : field)
    }
    useEffect(() => {
        // if (window.location.host !== "internal.dolphincare.ai" && window.location.host !== "localhost:3001") {
        //     console.log(window.location.host)
        //     window.location.replace("http://app.dolphincare.ai/");
        // }
        // else {
            console.log("reloading")
            loadInsancesHealthStatus()
            loadDatabases()
            loadTargetGroups()
            loadInstances()
        // }
    }, [])

    useEffect(() => {
        console.log("loading after action")
        loadInstances()
        loadDatabases()
    }, [completedAction]);

    const loadInstances = () => {
        axios.get('/api/internal/getinstances/', Config()).then((res) => {
            console.log("instances loaded",res.data)
            setInstances(res.data)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            console.log(err);
            setError(`Couldn't get instances for ${err.response.data}`)
        })
    }

    const loadInsancesHealthStatus =() => {
        axios.get('/api/internal/gethealthstatus/', Config()).then((res) => {
            console.log("health status",res.data)
            setInstancesHealth(res.data)
            // console.log("testing: ", res.data.find(f=>f['Target']['Id']==='i-08f76b7aa1371bd2e')['TargetHealth']['State'])
        }).catch(err => {
            console.log(err);
            setError(`Couldn't get health status for ${err.response&&err.response.data}`)
        })
    }
        

    const loadDatabases = () => {
        axios.get('/api/internal/getdatabases/', Config()).then((res) => {
            console.log("DB loaded",res.data)
            setDatabases(res.data)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            console.log(err);
            setError(`Couldn't get databases for ${err.response.data}`)
        })
    }

    const loadTargetGroups = () => {
        axios.get('/api/internal/gettargetgroups/', Config()).then((res) => {
            console.log("target groups loaded",res.data)
            setTargetGroups(res.data)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            console.log(err);
            setError(`Couldn't get target groups for ${err.response.data}`)
        })
    }

    const instanceActions = (id, action) => {
        setCompletedAction(false)
        setLoading(true)
        axios.get(`/api/internal/${action}/${id}/`, Config()).then((res) => {
            setCompletedAction(true)
            console.log("action ", action," response: ", res.data)
        }).catch(err => {
            setCompletedAction(true)
            setLoading(false)
            console.log(err);
        })
    }

    const rebootDatabase = (id) => {
        setCompletedAction(false)
        setLoading(true)
        axios.get(`/api/internal/rebootdatabase/${id}/`, Config()).then((res) => {
            console.log("reboot database response: ", res.data)
            setCompletedAction(true)
        }).catch(err => {
            setCompletedAction(true)
            console.log(err);
        })
    }

    const checkIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#1d8102" className="bi bi-check-circle checkIcon" viewBox="0 0 16 16">
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
  </svg>

  const rebootIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#711a80" className="bi bi-bootstrap-reboot" viewBox="0 0 16 16">
  <path d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8z"/>
  <path d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141zm0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6z"/>
</svg>

const stopIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#980b0b" className="bi bi-dash-circle stopIcon" viewBox="0 0 16 16">
<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
<path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
</svg>

const pendingIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="##3c434b" class="bi bi-clock pendingIcon" viewBox="0 0 16 16">
<path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
<path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
</svg>

const proccesIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#1d8102" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
<path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
</svg>


    return <div className='usersInfo'>
        {loading ? <Spinner animation='border' variant='dark' /> :
            error ? <div>{error}</div> :
                instances  &&<div>
                    <Form className='wider'>
                        <h2>Instances</h2>
                        <table className='customerServiceTbl'>
                            <tbody>
                                <tr>
                                    <th onClick={() => changeSortField("name")}>Name
                                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                                    </th>
                                    <th onClick={() => changeSortField("instance_id")}>ID
                                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                                    </th>
                                    <th onClick={() => changeSortField("state")}>State
                                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                                    </th>
                                    <th onClick={() => changeSortField("pipeline")}>Pipeline
                                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                                    </th>
                                    <th>Health Status
                                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                                    </th>
                                    <th>Start</th>
                                    <th>Stop</th>
                                    <th>Reboot</th>
                                    <th>Restart all processes</th>

                                </tr>
                                {instances && instances.sort(dynamicSort(sortField)).map(i =>
                                    <tr key={i.instance_id}>
                                        <td>{i.name}</td>
                                        <td>{i.instance_id}</td>
                                        <td>{i.state=='running'? checkIcon : i.state=='stopped' ? stopIcon: pendingIcon}<span>{i.state.charAt(0).toUpperCase() + i.state.slice(1)}</span></td>
                                        <td>{i.pipeline}</td> 
                                        <td>{(instancesHealth && instancesHealth.find(f=>f['Target']['Id']===i.instance_id)) && 
                                        instancesHealth.find(f=>f['Target']['Id']===i.instance_id)['TargetHealth']['State']=='healthy'? checkIcon: ''}
                                        {/* instancesHealth.find(f=>f['Target']['Id']===i.instance_id)['TargetHealth']['State']=='healthy' ? {checkIcon} : ''} */}
                                      <span>{(instancesHealth && instancesHealth.find(f=>f['Target']['Id']===i.instance_id)) && 
                                      instancesHealth.find(f=>f['Target']['Id']===i.instance_id)['TargetHealth']['State'].charAt(0).toUpperCase() + instancesHealth.find(f=>f['Target']['Id']===i.instance_id)['TargetHealth']['State'].slice(1)}</span>
                                      </td>                    
                                        {/* <td>{instancesHealth && 'testing'}</td>                    */}
                                        <td className="servicesIcons" onClick={()=>instanceActions(i.instance_id, "startinstance")}>{checkIcon}</td>                    
                                        <td className="servicesIcons" onClick={()=>instanceActions(i.instance_id, "stopinstance")}>{stopIcon}</td>                    
                                        <td className="servicesIcons" onClick={()=>instanceActions(i.instance_id, "rebootinstance")}>{rebootIcon}</td>
                                        <td className="servicesIcons" onClick={()=>instanceActions(i.instance_id, "restartprocesses")}>{proccesIcon}</td>                     
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </Form>
                </div>
        }

                {databases && <div>
                    <Form className='wider'>
                        <h2>Databases</h2>
                        <table className='customerServiceTbl'>
                            <tbody>
                                <tr>
                                    <th onClick={() => changeSortField("DBInstanceIdentifier")}>Name
                                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                                    </th>
                                    <th onClick={() => changeSortField("DBInstanceStatus")}>Status
                                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                                    </th>
                                    <th onClick={() => changeSortField("Engine")}>Engine
                                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                                    </th>
                                    <th>Reboot</th>
                                </tr>
                                {databases && databases.sort(dynamicSort(sortField)).map(d =>
                                    <tr key={d.DBInstanceIdentifier}>
                                        <td>{d.DBInstanceIdentifier}</td>
                                        <td>{d.DBInstanceStatus=='available'? checkIcon : ''}<span>{d.DBInstanceStatus.charAt(0).toUpperCase() + d.DBInstanceStatus.slice(1)}</span></td>
                                        <td>{d.Engine}</td>                                     
                                        <td className="servicesIcons" onClick={()=>rebootDatabase(d.DBInstanceIdentifier)}>{rebootIcon}</td>                    
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </Form>
                </div>
        }


                {!isNullorEmpty(targetGroups) && <div>
                    <Form className='wider'>
                        <h2>Target Group</h2>
                        <table className='customerServiceTbl'>
                            <tbody>
                                <tr>
                                    <th onClick={() => changeSortField("TargetGroupName")}>Name
                                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                                    </th>
                                    {/* <th onClick={() => changeSortField("TargetGroupArn")}>ARN
                                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                                    </th> */}
                                    <th>Port</th>
                                    <th>Protocol</th>
                                    <th>Type</th>
                                    <th>Load balancer</th>
                                    <th>VPC ID</th>
                                    <th>Healthy Threshold </th>
                                    <th>Unhealthy Threshold</th>
                                   
                                </tr>
                                {!isNullorEmpty(targetGroups) && targetGroups.sort(dynamicSort(sortField)).map(t =>
                                    <tr key={t.VpcId}>
                                        <td>{t.TargetGroupName}</td>
                                        {/* <td>{t.TargetGroupArn}</td> */}
                                        <td>{t.Port}</td>                                                      
                                        <td>{t.Protocol}</td>                                                      
                                        <td>{t.TargetType}</td>                                                      
                                        <td>{t.LoadBalancerArns.length>0 && t.LoadBalancerArns[0].split('app/')[1].split('/')[0]}</td>                                                      
                                        <td>{t.VpcId}</td>                                                      
                                        <td>{t.HealthyThresholdCount}</td>                                                      
                                        <td>{t.UnhealthyThresholdCount}</td>                                                      
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </Form>
                </div>
        }

    </div>

}
export default SelectServices