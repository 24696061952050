import React, { useEffect, useState, Fragment } from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import isNullorEmpty from '../Utilities/isNullorEmpty';
import {capitalize} from 'lodash';
import Config from '../Utilities/Config';


const GroupFilter = (props) => {
    const [groups, setGroups] = useState(null)
    const [currentGroup, setCurrentGroup] = useState(props.allGroups ? '' : 'mqs')
    const [appusers, setAppUsers] = useState(null)
    const [currentAppUser, setCurrentAppUser] = useState(null)
  
    
    useEffect(() => {
        // Get All Groups
        axios.get('/api/internal/getgroups/?basic', Config()).then((res) => {
            setGroups(res.data)
        }).catch(err => {
            console.log(err);
        })
        // if(props.secondary){
        //     setAppUsers([...new Set(props.users.map((item) => item.combined_name))])
        // } 
    }, [])  

    useEffect(() => {
        props.setCurrentUsers(!isNullorEmpty(currentGroup) && currentGroup !== "All Groups" && currentGroup !=="Internal Users"? props.users.filter(user => user.group === currentGroup) : currentGroup === "All Groups" && currentGroup !=="Internal Users"? props.users : props.internalUsers)
        setCurrentAppUser(null) 
        // console.log("current users are",props.currentUsers)     
    }, [currentGroup, props.users, props.internalUsers])

  

    // useEffect(() => {
    //     props.setCurrentUsers(!isNullorEmpty(currentAppUser) && currentAppUser !== "All Users" ? props.users.filter(user => user.combined_name === currentAppUser) : props.users)
    //     setCurrentGroup(defaultGroup)       
    // }, [currentAppUser, props.users])

    // const customStyles = {
    //     dropdownIndicator: base => ({
    //       ...base,
    //       color: "black" 
    //     })
    //   };


    return <Fragment>
        <Form.Select className='group-filter' 
            value={currentGroup}
            onChange={(e) => setCurrentGroup(e.target.value)} >
            <option value={'All Groups'}>All Groups</option>
            {groups && groups.map(g => <option key={g.group_code} value={g.group_code}>{capitalize(g.name)}</option>)}
            <option value={'Internal Users'}>Internal Users</option>
        </Form.Select> 
        {/* {props.secondary && <Form.Select className='group-filter' 
            value={currentAppUser}
            onChange={(e) => setCurrentAppUser(e.target.value)} >
            <option value={null}>All Users</option>
            {appusers && appusers.map(g => <option key={g} value={g}>{g}</option>)}
        </Form.Select>}  */}
    </Fragment>                 


}
export default GroupFilter