import React, { Fragment, useEffect, useState } from 'react';
import { Form, Spinner, Button, Table, Badge } from 'react-bootstrap';
import axios from 'axios';
import dynamicSort from './Utilities/SortTable';
import { formatDateTime } from './Utilities/FormatDate';
import isNullorEmpty from './Utilities/isNullorEmpty';
import CustomModal from './Utilities/CustomModal';
import { capitalize, startCase, cloneDeep } from 'lodash';
import Config from './Utilities/Config';
// import {DateRangePicker} from 'react-date-range';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import GroupFilter from './AdminPages/GroupFilter';
import TableScrollBar from 'react-table-scrollbar'
import { useNavigate } from 'react-router-dom'



const QaDashboard = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [updating, setUpdating] = useState(false)
    const [docs, setDocs] = useState(null)
    const [error, setError] = useState(null)
    const [sortField, setSortField] = useState("-parsed_date")
    const [currentUser, setCurrentUser] = useState('')
    // const [currentRep, setCurrentRep] = useState([])
    const [updatingAssigned, setUpdatingAssigned] = useState(null)
    const [deletePopup, setDeletePopup] = useState(false)
    const [dateRange, setDateRange] = useState([new Date(new Date().setDate(new Date().getDate() - 1)), new Date()])
    const [currentDocId, setCurrentDocId] = useState(null)
    const [groups, setGroups] = useState(null)
    const [facilities, setFacilities] = useState(null)
    const [currentGroup, setCurrentGroup] = useState('crc')
    const [currentFacility, setCurrentFacility] = useState(0)
    const [currentPriority, setCurrentPriority] = useState(0)
    const [qaStatus, setQaStatus] = useState(0)
    const [docsType, setDocsType] = useState(0)
    const [priorities, setPriorities] = useState(null)
    const [docsNewPath, setDocsNewPath] = useState(null)
    const [apiPath, setApiPath] = useState('')
    const [marsObj, setMarsObj] = useState({})
    const [marsCheckbox, setMarsCheckbox] = useState(false)
    const [marsPopup, setMarsPopup] = useState(false)

    const reps = ['Eli Slade',
        'Gitty Herszaft', 'Racheli Weiner', 'Faigy Friedman', 'Rivky Hirschler', 'Esther Baila Adlin', 'Rivkah Kahn', 'Tzippora Rabinowitz', 
        'Rivky Feldman', 'Gitty Zitter', 'Racheli Kosowsky', 'Yehudis Carlebach']

    const changeSortField = field => {
        setSortField(sortField === field ? `-${field}` : field)
    }

    useEffect(() => {
        if (window.location.host !== "internal.dolphincare.ai" && window.location.host !== "localhost:3001") {
            axios.get('/api/qa/', Config()).then(res => {
                setApiPath('api')
            }).catch(err => navigate('/client/signin'))
        }
        else {
            setApiPath('internal')
        }
    }, [])
    
    useEffect(() => {
        loadGroupsAndPriorities()
        loadAdmissionDocs()
        loadFacilities()
        const interval = setInterval(() => {
            try { document.getElementById('load').click() } catch { }
        }, 60000);
        // return () => clearInterval(interval);
    }, [apiPath])

    
    const loadGroupsAndPriorities = () => {
        if (apiPath == '') return 
        axios.get(`/${apiPath}/getqagroups/`, Config()).then((res) => {
            setGroups(res.data)
            setPriorities(res.data.filter(g => g.priority > 0))
        }).catch(err => {
            console.log(err);
        })
    }

    const loadAdmissionDocs = () => {
        // const dateParams = dateRange.map(date => date = new Date(date.toString().replace(/-/g, '/')))
        //         let dates = null;
        //         try{
        //             dates = [document.querySelector('input[name="daterange_from"]').value, document.querySelector('input[name="daterange_to"]').value]
        //         } catch{
        // dates = dateRange
        //         }
        // const dates = dateRange.map(d => (new Date(d.toDateString())).toISOString().split('T')[0].replace(/-/g, '/'))
        const dates = dateRange.map(d => new Intl.DateTimeFormat('en-US').format(d))
        if (apiPath == '') return 
        axios.post(`/${apiPath}/documents/${docsType == 1 ? 'connect' : 'admission'}/${currentGroup}/${currentFacility}/${qaStatus}/${currentPriority}/`, { 'dates': dates }, Config()).then((res) => {
            const docs = res.data.map(d => ({ ...d, 'new_path_name': createDocPath(d) }))
            setDocs(docs)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            console.log(err);
            setError(`Couldn't get docs for ${err.response.data}`)
        })
    }
    const loadFacilities = () => {
        if (apiPath == '') return 
        axios.get(`/${apiPath}/getfacilitiesbygroup/${currentGroup}/`, Config()).then((res) => {
            setFacilities(res.data.sort(dynamicSort('facility_name')))
        }).catch(err => {
            setLoading(false)
            console.log(err);
        })
    }

    useEffect(() => {
        if (!isNullorEmpty(dateRange)) {
            setLoading(true)
            setDocs(null)
            loadAdmissionDocs()
            if (currentGroup != 0) {
                loadFacilities()
            }
        }
    }, [dateRange, currentGroup, currentFacility, qaStatus, docsType, currentPriority])

    useEffect(() => {
        if (!isNullorEmpty(updatingAssigned)) {
            setUpdatingAssigned(null)
        }
    }, [docs])

    const createDocPath = d => {
        const wrappedFacilityId = '/' + d.facility + '/';
        const path = docsType == 0 ? '\\\\192.168.100.200\\companies\\' + d.group + '\\facilities\\' + d.s3_doc_path.replace('admission-docs', 'admission_docs_subsets').replace(new RegExp('.pdf$'), d.add_links_date_modified ? '_link.pdf' : '_link.pdf')
            : d.doc_path.includes('H:/production') ? '\\\\192.168.100.200' + d.doc_path.replace('H:/production', '').replace('.txt', '.csv')
            : d.doc_path.startsWith('s3://') ? d.doc_path.replace('s3://', '\\\\192.168.100.200\\companies\\').replace('-pcc-documents', '\\facilities').replace(wrappedFacilityId,wrappedFacilityId+'connect_docs/').replace('/connect/', '/')+'.csv'
                : '\\\\192.168.100.200\\companies\\' + d.group + '\\facilities\\' + d.doc_path.replace('connect-docs', 'connect_docs').replace('.txt', '.csv')
        return path.replaceAll('/', '\\')
    }
    const resetStateValues = () => {
        setUpdating(false)
        setDeletePopup(false)
        setMarsPopup(false)
        // setDefaultRep()
        setCurrentDocId(null)
    }

    const updateDoc = (id, updateType, index = null) => {
        if (updateType === "update") {
            document.getElementById(`update-button-${id}`).innerHTML = "Updating..."
        }
        setUpdating(true)
        const path = docs.find(d => d.id === id).new_path_name;
        const data = updateType === "delete" ? { docPath: path, 'qa_rep_name': docs.find(d => d.id === id).qa_assigned_to, 'iv_fluid_found': 0 } : updateType === "update" ? { docPath: path, 'qa_rep_name': docs.find(d => d.id === id).qa_assigned_to, 'iv_fluid_found': 1 } : updateType === 'mars' ? marsObj : { docPath: path, 'qa_assigned_to': index }
        axios.put(`/${apiPath}/updateadmissiondoc/${id}/${docsType == 1 ? "connect" : "admission"}/`, data, Config()).then((res) => {
            if (updateType === "delete") {
                let docsCopy = cloneDeep(docs)
                docsCopy = docsCopy.filter(d => d.id !== id)
                setDocs(docsCopy)
            }
            else if (updateType === "update") {
                document.getElementById(`update-button-${id}`).innerHTML = "Completed"
            }
            resetStateValues()
            loadAdmissionDocs()
        }).catch(err => {
            if (updateType === "update") {
                document.getElementById(`update-button-${id}`).innerHTML = "Completed"
            }
            resetStateValues()
            console.log(err);
            setError(`Couldn't update doc for ${err.response.data}`)
        })
    }

    // useEffect(() => {
    //     setDefaultRep()   
    // }, [currentUser])

    // const setDefaultRep = () => {
    //     if(docs){
    //         let repCopy = []
    //         docs.map((r,i) => {
    //             repCopy[i] = currentUser
    //         });
    //         setCurrentRep(repCopy) 
    //     }
    // }

    const changeRep = (id, value) => {
        updateDoc(id, 'assign', value);
        setCurrentUser(value)
        setUpdatingAssigned(id)
        // const repCopy = [...currentRep]
        // repCopy[index] = value
        // setCurrentRep(repCopy);        
    }

    const changeMars = (value, field) => {
        let marsCopy = {...marsObj}
        marsCopy[field] = value
        setMarsObj(marsCopy)
    }

    const openMarsPopup = (id, mar_pages) => {
        setCurrentDocId(id)
        setMarsObj({'id': id, 'qa_mar_pages' : mar_pages, 'non_mar_on_mar_pg' : 0})
        setMarsPopup(true)
    }

    const tableBody = (d, i) => {
        const pageNums = !isNullorEmpty(d.qa_mar_pages) ? d.qa_mar_pages : d.mar_pages;
        return <tr key={d.id} className={d.parsed === 2 ? "bold" : ''}>
            <td><Button className='table-btn' disabled={isNullorEmpty(d.qa_assigned_to)} onClick={() => { setDeletePopup(true); setCurrentDocId(d.id) }}>Delete</Button></td>
            <td>{capitalize(d.group)}</td>
            <td>{startCase(d.facility_name)}</td>
            <td>{formatDateTime(d.parsed_date)}</td>
            {docsType == 1 && <td><a href={`https://${window.location.host}/document/connect/${d.id}`} target="_blank">View</a></td>}
            <td className='long'>{d.new_path_name}</td>
            {docsType == 0 && <>
            <td className='long'>{!isNullorEmpty(d.ivf_highlighted_doc_path) ? d.ivf_highlighted_doc_path : ''}</td>
            <td>{!isNullorEmpty(d.mar_pages) ?
            <Fragment>
                {pageNums.length > 55 ? `${pageNums.substring(0, 55)}...` : pageNums}             
                <Button className='table-btn' onClick={() => openMarsPopup(d.id, pageNums)}>{!isNullorEmpty(d.qa_mar_pages) ? 'Edit' : 'Confirm'}</Button>
            </Fragment> : ''}
            </td></> }
            <td>{!isNullorEmpty(d.qa_date_modified) ? formatDateTime(new Date(d.qa_date_modified).toLocaleString("en-US", { timeZone: 'Asia/Jerusalem' })) : ''}</td>
            <td>{d.qa_rep_name}</td>
            <td>
                <Form.Select id={`name-drop-${d.id}`} className='dropdown name'
                    value={updatingAssigned === d.id ? "updating" : d.qa_assigned_to}
                    onChange={(e) => changeRep(d.id, e.target.value)} >
                    <option hidden value={"updating"}>Updating...</option>
                    <option value={""}>Select Your Name</option>
                    {reps.sort().map(r => <option key={r} value={r}>{r}</option>)}
                </Form.Select>
                <Button className='table-btn' id={`update-button-${d.id}`} disabled={isNullorEmpty(d.qa_assigned_to) || !isNullorEmpty(updatingAssigned)} onClick={() => updateDoc(d.id, 'update')}>Completed</Button>
            </td>
        </tr>
    }


    return <div className='qa-dashboard usersInfo'>
        {loading ? <Spinner animation='border' variant='dark' /> :
            error ? <div>{error}</div> :
                docs && <div>
                    {deletePopup &&
                        <CustomModal width='45%' title={`Confirm Delete - ${docs.find(d => d.id === currentDocId).doc_name}`} closeModal={() => resetStateValues()}
                            onSave={() => updateDoc(currentDocId, 'delete')} saveBtnTxt='Confirm'>
                            {updating ? <div className='body'><Spinner animation='border' variant='dark' /></div> :
                                <Fragment>Are you sure you want to delete this subset?</Fragment>}
                        </CustomModal>}
                    {marsPopup && <CustomModal title={`Confirm MAR pgs - ${docs.find(d => d.id === marsObj.id).doc_name}`} closeModal={() => {setMarsPopup(false); setMarsCheckbox(false)}}
                        onSave={() => updateDoc(currentDocId, 'mars')} saveBtnTxt='Confirm' loading={updating} saveDisabled={marsCheckbox && isNullorEmpty(marsObj.non_mar_on_mar_pg)}>
                        <div className='mars-popup'>
                            <Form.Control as="textarea" rows={Math.floor(marsObj.qa_mar_pages.length/70)} value={marsObj.qa_mar_pages} onChange={e => changeMars(e.target.value, 'qa_mar_pages')} />
                            <br/>
                            <Form.Check type="checkbox" label="Non MAR instance on MAR page" checked={marsCheckbox} onChange={() => setMarsCheckbox(!marsCheckbox)} />
                            {marsCheckbox && <Fragment>                                
                                <Form.Label>Page #(s):</Form.Label>
                                <Form.Control value={marsObj.non_mar_on_mar_pg} onChange={e => changeMars(e.target.value, 'non_mar_on_mar_pg')} />
                            </Fragment>}
                        </div>                       
                    </CustomModal>}
                    <div className='wider'>
                        <h2>QA Dashboard</h2>
                        <div className="filter">
                            <DateRangePicker disabled={loading} onChange={setDateRange} value={dateRange} />
                            <Form.Select className='dropdown group'
                                value={currentPriority}
                                onChange={(e) => { setCurrentPriority(e.target.value); setCurrentGroup(0) }} >
                                <option value={0}>Select Priority</option>
                                {priorities && [...new Set(priorities.map(f => f.priority))].sort().map(p => <option key={p} value={p}>{"Priority " + p}</option>)}
                            </Form.Select>
                            <Form.Select className='dropdown group'
                                value={currentGroup}
                                onChange={(e) => { setCurrentGroup(e.target.value); setCurrentPriority(0); setCurrentFacility(0) }} >
                                {groups && groups.sort(dynamicSort('group_code')).map(g => <option key={g.group_code} value={g.group_code}>{capitalize(g.group_code)}</option>)}
                                <option value={0}>All Groups</option>
                            </Form.Select>
                            <Form.Select className={currentGroup == 0 || currentPriority != 0 ? 'dropdown group disabled' : 'dropdown group'}
                                disabled={currentGroup == 0 || currentPriority != 0}
                                value={currentFacility}
                                onChange={(e) => setCurrentFacility(e.target.value)} >
                                <option value={0}>All Facilities</option>
                                {facilities && facilities.map(f => <option key={f.facilityid} value={f.facilityid}>{startCase(f.facility_name)}</option>)}
                            </Form.Select>
                            <Form.Select className='dropdown group'
                                value={qaStatus}
                                onChange={(e) => setQaStatus(e.target.value)} >
                                <option value={2}>All Subsets</option>
                                <option value={0}>QA Not Completed</option>
                                <option value={1}>QA Completed</option>
                            </Form.Select>
                            <Form.Select className='dropdown group'
                                value={docsType}
                                onChange={(e) => setDocsType(e.target.value)} >
                                <option value={0}>Admission Docs</option>
                                <option value={1}>Connect Docs</option>
                            </Form.Select>
                            {/* <div className='right'>                       
                            <Form.Select className='dropdown'
                                value={currentUser}
                                onChange={(e) => setCurrentUser(e.target.value)} >
                                <option value={""}>Select Your Name</option>
                                {reps.sort().map(r => <option key={r} value={r}>{r}</option>)}
                            </Form.Select> 
                        </div>                            */}
                        </div>
                        <div className='filter'>
                            {!isNullorEmpty(priorities) && <span><Form.Label>Today's Priorities: </Form.Label>{priorities.sort(dynamicSort('priority')).map(g => <span> <Badge bg="light" text="dark">{g.priority}</Badge> {capitalize(g.group_code)} </span>)}</span>}
                            <Form.Label className='right'>{`Displaying: ${docs.length} document/s`}</Form.Label>
                        </div>
                        <Table>
                            <tbody>
                                <tr>
                                    <th>Delete</th>
                                    <th onClick={() => changeSortField("group")}>Group
                                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                                    </th>
                                    <th onClick={() => changeSortField("facility_name")}>Facility
                                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                                    </th>
                                    <th onClick={() => changeSortField("parsed_date")}>Parsed Date
                                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                                    </th>
                                    {docsType == 1 && <th onClick={() => changeSortField("id")}>Doc Link
                                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                                    </th>}
                                    <th className='long' onClick={() => changeSortField("doc_name")}>Document
                                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                                    </th>
                                    {docsType == 0 && <>
                                        <th className='long' onClick={() => changeSortField("ivf_highlighted_doc_path")}>IVF Model Highlighted Doc
                                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                                    </th>    
                                    <th onClick={() => changeSortField("mar_pages")}>MAR pages 
                                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                                    </th>    </>}
                                    <th onClick={() => changeSortField("qa_date_modified")}>Date Modified
                                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                                    </th>
                                    <th onClick={() => changeSortField("qa_rep_name")}>Modified By
                                        <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                                    </th>                             
                                    <th>Mark Complete</th>
                                </tr>
                                {docs && docs.filter(dd => dd.parsed === 2).sort(dynamicSort(sortField)).map((d, i) =>
                                    tableBody(d, i)
                                )}
                                {docs && docs.filter(dd => dd.parsed !== 2).sort(dynamicSort(sortField)).map((d, i) =>
                                    tableBody(d, i)
                                )}
                            </tbody>
                        </Table>
                        <button id='load' onClick={loadAdmissionDocs}></button>
                    </div>
                </div>
        }

    </div>

}
export default QaDashboard;
