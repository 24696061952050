import React, { useEffect, useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import isNullorEmpty from '../Utilities/isNullorEmpty';
import Config from '../Utilities/Config';

const PreregisterUsers = (props) => {

    const [loading, setLoading] = useState(true)
    const [groups, setGroups] = useState(null)
    const [currentGroup, setCurrentGroup] = useState(0)   
    const [emails, setEmails] = useState('')
    const [preregisterResponse, setPreregisterResponse] = useState(false)
    const [error, setError] = useState(null)
    const [emailError, setEmailError] = useState(null)

    useEffect(() => {
        // if (window.location.host !== "internal.dolphincare.ai" && window.location.host !== "localhost:3001") {
        //     console.log(window.location.host)
        //     window.location.replace("http://internal.dolphincare.ai/");
        // }
        // else {
        //     axios.get('/api/internal/getgroups/', Config()).then((res) => {
        //         setGroups(res.data)
        //         setLoading(false)
        //     }).catch(err => {
        //         console.log(err);
        //     })
        // }
    }, [])

    useEffect(() => {
        if (!isNullorEmpty(props.groups)) {
            setGroups(props.groups)          
            setLoading(false)
        }
    }, [props.groups])   


    const validateEmails = (value) => {
        let error = ''
        if (value === '') {
            error = 'Email cannot be blank'
        }
        else {
            const pattern = /^[\w\.\-\+]+@([\w\-]+\.)+[\w]{2,4}$/;
            console.log(value);
            const list = value.includes('%3B') ? value.replace(';', '%3B').split('%3B') : value.split(';');
            list.forEach(e => {
                error = pattern.test(e.trim().toLowerCase())
                    ? error
                    : error === '' ? 'Invalid email address: ' + e
                        : error + ' \nInvalid email address: ' + e;
            });
        }
        setEmailError(error)
    }
    const preregister = () => {
        setError('')
        setPreregisterResponse('')
        setLoading(true)
        axios.post('/api/internal/preregisteruser/', { emails: emails, group: currentGroup }, Config()).then((res) => {
            setPreregisterResponse(JSON.parse(res.data))
            setLoading(false)
        }).catch(err => {
            console.log(err);
            setError(err.response.status === 406 ? 'An unexpected error occured. Emails did not get sent starting from ' + err.response.data : `An unexpected error occured. Check the console or contact support.`)
            setLoading(false)
        })

    }


    return <div className='register'>
        {loading ? <Spinner animation='border' variant='dark' /> : <>

            {groups && <Form className='wider'>
                <h2>Add Users</h2>

                <Form.Control as='textarea'
                    placeholder="Enter email addresses separated by semicolon"
                    value={emails}
                    onChange={e => { setEmails(e.target.value); setEmailError('')}}
                    onBlur={e => validateEmails(emails)} />

                {!isNullorEmpty(emailError) && <Form.Label className='error'>{emailError}</Form.Label>}

                <Form.Control as="select"
                    value={currentGroup}
                    onChange={(e) => setCurrentGroup(e.target.value)} >
                    <option value="0">Select A Group</option>
                    {groups.map(g => <option key={g.group_code} value={g.group_code}>{g.name}</option>)}
                </Form.Control>                
              
                <div>
                    <Button disabled={isNullorEmpty(emails) || currentGroup === 0 || !isNullorEmpty(emailError)} onClick={() => preregister()}>Add</Button>
                </div>
            </Form>}

            {error && <div>{error}</div>}

            {preregisterResponse && <table>
                <tr><th>Email</th><th>Action</th></tr>
                {preregisterResponse.map((e, i) => <tr key={i}><td>{e.email}</td><td>{e.action}</td></tr>)}
            </table>}
        </>}
    </div>
}
export default PreregisterUsers