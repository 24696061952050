import axios from 'axios';
import React, { useEffect, useState, Fragment } from 'react';
import { Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import Config from '../../Utilities/Config';
import CustomModal from '../../Utilities/CustomModal';
import importScript from '../../Utilities/importScript'
import isNullorEmpty from '../../Utilities/isNullorEmpty';
import { cloneDeep, capitalize } from 'lodash';
import Validation from '../../Utilities/Validation';
import validateWhileTyping, { types } from '../../Utilities/ValidateWhileTyping';
import Spinner from '../../Utilities/Spinner';
import { useSelector } from 'react-redux';

const Payment = (props) => {
    const currentGroup = useSelector(state => state.globalState.currentGroup)
    const user = useSelector(state => state.globalState.user)
    const facilities = useSelector(state => state.globalState.allFacilities)
    const [loading, setLoading] = useState(false)
    const [hpfToken, setHpfToken] = useState(true)
    const [ccData, setCCData] = useState({
        ccn: { error: '', complete: false },
        exp: { error: '', complete: false },
        cvv: { error: '', complete: false }
    })
    const [errMsg, setErrMsg] = useState('')
    const [billingData, setBillingData] = useState({
        first_name: {
            value: "",
            rules: {
                required: true,
            },
            type: "string",
            error: ''
        },
        last_name: {
            value: "",
            rules: {
                required: true,
            },
            type: "string",
            error: ''
        },
        address: {
            value: "",
            rules: {
                required: true,
                bluesnapAddress: true
            },
            type: "string",
            error: "",
        },
        city: {
            value: "",
            rules: {
                required: true,
            },
            type: "string",
            error: "",
        },
        state: {
            value: '',
            rules: {
                required: true,
                isState: true
            },
            type: 'string',
            error: '',
        },
        zip: {
            value: "",
            rules: {
                required: true,
                requiredLength: 5,
                isNumeric: true,
            },
            type: "string",
            valType: types.Zip,
            error: "",
        }
    })

    const setUpPaymentToken = () => {
        setCCData({
            ccn: { error: '', complete: false },
            exp: { error: '', complete: false },
            cvv: { error: '', complete: false }
        })
        axios.get('/api/bluesnaptoken/', Config()).then(res => {
            console.log(res.data)
            importScript('https://pay.bluesnap.com/web-sdk/5/bluesnap.js')
            //update token after hour bluesnap.hostedPaymentFieldsUpdateToken(new token string);
            //documentation: https://developers.bluesnap.com/v8976-Tools/reference/hosted-payment-fields#implementing-hosted-payment-fields-in-your-checkout-form
            setHpfToken(res.data)
            const bsObj = {
                token: res.data,
                onFieldEventHandler: {
                    setupComplete: function () { console.warn("setupComplete") },
                    threeDsChallengeExecuted: function () { console.warn("threeDsChallengeExecuted") },
                    onFocus: function (tagId) { },
                    onBlur: function (tagId) { },
                    onType: function (tagId, cardType, cardData) {},
                    onEnter: function (tagId) { },
                    onError: function (tagId, errorCode, errorDescription, eventOrigin) { console.log(tagId, errorDescription); setCCData(ccd => ({ ...ccd, [tagId]: { error: errorDescription == "empty" ? "cannot be blank" : errorDescription, complete: false } })) },
                    onValid: function (tagId) { setCCData(ccd => ({ ...ccd, [tagId]: { error: '', complete: true } })) },
                },
                style: { ":focus": { color: "orange" }, input: { color: "blue" }, ".invalid": { color: "red" } },
                ccnPlaceHolder: "1234 5678 9012 3456",
                cvvPlaceHolder: "123",
                expPlaceHolder: "MM/YY",
            };
            window.bsObj = bsObj
            createHPF(bsObj)
        })
    }


    useEffect(() => {
        setUpPaymentToken()        
    }, [])

    useEffect(() => {
       if(!isNullorEmpty(props.new) && props.new){
            let objCopy = {...billingData}
            objCopy['email'] = {
                value: "",
                rules: {
                    isEmail: true,
                    required: true
                },
                type: "string",
                error: ''
            }            
            objCopy['phone'] = {
                value: '',
                rules: {
                    required: true,
                    isNumeric: true,
                    requiredLength: 10,
                },
                type: 'string',
                valType: types.Phone,
                error: ''
            }
            setBillingData(objCopy)
            if (facilities && props.facility !== 0){
                console.log(facilities)
                console.log(props.facility)
            //     facilities.find()                
            // {props.new && props.facility !== 0 && <div>{facilities && facilities.find(f => f.id == props.facility).facility_name}</div>}
            }
       }
       
    }, [props.new])

    const createHPF = (bsObject) => {
        setTimeout(() => {
            try {
                window.bluesnap.hostedPaymentFieldsCreate(bsObject)
                return true
            } catch {
                createHPF(bsObject)
            }
        }, 1000)
    }

    const axiosRequest = (data) => {
        return props.new ? axios.post(`/api/sendbillinginfo/${currentGroup}/${props.facility}/${props.newGroup ? '?newGroup' : ''}`, data, Config()) : 
        axios.put(`/api/sendbillinginfo/${currentGroup}/${props.billingDetails['Shopper ID']}/${props.billingDetails['Subscription ID']}/`, data, Config())   
    }

    const submitPayment = () => {   
        console.log(ccData)     
        setErrMsg('')
        setLoading(true)
        const errors = [];
        //verify that there's no error in payment fields
        for (let [key, value] of Object.entries(ccData)) {
            if(!isNullorEmpty(value.error)){
                errors.push(key + " " + value.error)
            }      
            else if(!value.complete){
                errors.push(key + " invalid")
            }
        }

        const objCopy = {...billingData}
        const objToSave = {};     
        for (let [key, value] of Object.entries(billingData)) {
            const message = Validation(value.value, value.rules);
            if (message !== '') {
                errors.push(message.replace(/{field}/ig, capitalize(key.replace(/_/g, ' '))))
            }
            objToSave[key] = key === "phone" ? value.value.replace(/\D/g, '') : value.value;   
            objCopy[key].errMsg = ''           
        }            
        if (errors.length > 0) {
            setBillingData(objCopy)
            setErrMsg(errors.reduce((a, b) => a + '\u000A' + b));
            setLoading(false)
            return;
        }
        objToSave['hptoken'] = hpfToken;
        objToSave['quantity'] = props.newGroup ? props.quantity : 1       
        if (props.newGroup){
            objToSave['facilities'] = props.selectedFacilities
        }
        window.bluesnap.hostedPaymentFieldsSubmitData(callback => {
            console.log(callback)
            if (callback.cardData) {   
                //send to backend object with first name, last name, address, city, state, zip, and token
                //save to bluesnap with api 
                const savedCCInfo = {"card-type": callback.cardData.ccType, "last-four": callback.cardData.last4Digits}
                if (!props.new){
                    objToSave['ccInfo'] = savedCCInfo
                    objToSave['activeStatus'] = props.billingDetails.Status
                }       
                axiosRequest(objToSave).then(res => {             
                    console.log(res)
                    props.reloadBillingDetails()
                    setLoading(false)
                    props.onClose()
                }).catch(err => {
                    console.log(err);
                    setUpPaymentToken()
                    setErrMsg(err.response.status === 418 ? err.response.data : "Error updating billing details. Contact support if error persists.")
                    setLoading(false)
                })
                
            } else {
                var errorArray = callback.error;
                for (var i in errorArray) {
                    setCCData(ccd => ({ ...ccd, [errorArray[i].tagId]: { error: errorArray[i].errorDescription, complete: false } }))
                    console.log("Received error: tagId= " + errorArray[i].tagId + ", errorDescription= " + errorArray[i].errorDescription);
                }
                setErrMsg("Error updating billing details. Contact support if error persists.")
                setLoading(false)
            }
        });
    }

    const inputChanged = (event, inputIdentifier) => {
        let value = ['string', 'number'].includes(typeof event)
            ? event
            : event.target.value;
        value = billingData[inputIdentifier].valType ? validateWhileTyping(billingData[inputIdentifier].valType, value, billingData[inputIdentifier].value) : value;
        const newObj = cloneDeep(billingData)
        newObj[inputIdentifier].value = value;
        setBillingData(newObj);
    }

    const validate = (inputIdentifier) => {
        const err = Validation(billingData[inputIdentifier].value, billingData[inputIdentifier].rules).replace(/{field}/ig, inputIdentifier.replace(/_/g, ' '))
        const billingC = cloneDeep(billingData);
        billingC[inputIdentifier].error = capitalize(err);
        setBillingData(billingC)                    
    }

    const getFacilityName=() => {
        try{
            return <div className='payment-facility-name'>{facilities && capitalize(facilities.find(f => f.facilityid === props.facility).facility_name).split('-')[0]}</div>
        }
        catch{
            return
        }        
    }

    //chnage3 body to component and wrap if new group

    const FormBody = () => {
    return  <><Row>
    <Form.Group as={Col}>
        <Form.Label className="form-label">First Name</Form.Label>
        <Form.Control size='sm' value={billingData.first_name.value} onChange={e => inputChanged(e, 'first_name')} onBlur={e => validate('first_name')} />
        {!isNullorEmpty(billingData.first_name.error) && <Form.Label className='error'>{billingData.first_name.error}</Form.Label>}
    </Form.Group >

    <Form.Group as={Col}>
        <Form.Label className="form-label">Last Name</Form.Label>
        <Form.Control size='sm' value={billingData.last_name.value} onChange={e => inputChanged(e, 'last_name')} onBlur={e => validate('last_name')} />
        {!isNullorEmpty(billingData.last_name.error) && <Form.Label className='error'>{billingData.last_name.error}</Form.Label>}
    </Form.Group>
</Row>
<Row>
    <Form.Group as={Col}>
        <Form.Label className="form-label">Address</Form.Label>
        <Form.Control size='sm' value={billingData.address.value} onChange={e => inputChanged(e, 'address')} onBlur={e => validate('address')}/>
        {!isNullorEmpty(billingData.address.error) && <Form.Label className='error'>{billingData.address.error}</Form.Label>}
    </Form.Group>
</Row>
<Row>
    <Form.Group as={Col}>
        <Form.Label className="form-label">City</Form.Label>
        <Form.Control size='sm' value={billingData.city.value} onChange={e => inputChanged(e, 'city')} onBlur={e => validate('city')} />
        {!isNullorEmpty(billingData.city.error) && <Form.Label className='error'>{billingData.city.error}</Form.Label>}
    </Form.Group>
    <Form.Group as={Col}>
        <Form.Label className="form-label">State</Form.Label>
        <Form.Control size='sm' value={billingData.state.value} onChange={e => inputChanged(e, 'state')} onBlur={e => validate('state')} />
        {!isNullorEmpty(billingData.state.error) && <Form.Label className='error'>{billingData.state.error}</Form.Label>}
    </Form.Group>
    <Form.Group as={Col}>
        <Form.Label className="form-label">Zip</Form.Label>
        <Form.Control size='sm' value={billingData.zip.value} onChange={e => inputChanged(e, 'zip')} onBlur={e => validate('zip')}/>
        {!isNullorEmpty(billingData.zip.error) && <Form.Label className='error'>{billingData.zip.error}</Form.Label>}
    </Form.Group>
</Row>
{billingData.email && billingData.phone && <Fragment>
<Row>
    <Form.Group as={Col}>
        <Form.Label className="form-label">Email Address</Form.Label>
        <Form.Control size='sm' value={billingData.email.value} onChange={e => inputChanged(e, 'email')} onBlur={e => validate('email')} />
        {!isNullorEmpty(billingData.email.error) && <Form.Label className='error'>{billingData.email.error}</Form.Label>}
    </Form.Group>
    <Form.Group as={Col}>
        <Form.Label className="form-label">Phone</Form.Label>
        <Form.Control size='sm' value={billingData.phone.value} onChange={e => inputChanged(e, 'phone')} onBlur={e => validate('phone')} />
        {!isNullorEmpty(billingData.phone.error) && <Form.Label className='error'>{billingData.phone.error}</Form.Label>}
    </Form.Group>
</Row>
</Fragment> }
{/* <div className='card-cont'>
    <h6>Credit Card</h6> 
    <div>
        <img height="30px" src='https://pay.bluesnap.com/web-sdk/5/assets/cards/amex.png' />
    </div>
    <div>
        <img height="30px" src='https://pay.bluesnap.com/web-sdk/5/assets/cards/diners.png' />
    </div>
    <div>
        <img height="30px" src='https://pay.bluesnap.com/web-sdk/5/assets/cards/discover.png' />
    </div>
    <div>
        <img height="30px" src='https://pay.bluesnap.com/web-sdk/5/assets/cards/mastercard.png' />
    </div>
    <div>
        <img height="30px" src='https://pay.bluesnap.com/web-sdk/5/assets/cards/visa.png' />
    </div>
</div> */}
<Form.Group as={Col}>
    <Form.Label className="form-label" >Card Number</Form.Label>
    <div className='form-control' data-bluesnap="ccn" id="ccn"></div>
    {!isNullorEmpty(ccData.ccn.error) && <Form.Label className='error'>{ccData.ccn.error}</Form.Label>}
</Form.Group>
<Row>
    <Form.Group as={Col}>
        <Form.Label className="form-label" >Expiration</Form.Label>
        <div className='form-control' data-bluesnap="exp" id="exp"></div>
        {!isNullorEmpty(ccData.exp.error) && <Form.Label className='error'>{ccData.exp.error}</Form.Label>}
    </Form.Group>
    <Form.Group as={Col}>
        <Form.Label className="form-label" >CVV</Form.Label>
        <div className='form-control' data-bluesnap="cvv" id="cvv"></div>
        {!isNullorEmpty(ccData.cvv.error) && <Form.Label className='error'>{ccData.cvv.error}</Form.Label>}
    </Form.Group>
</Row>
{props.new && <Row className='consent'>
    <div>By clicking "Submit" you are providing consent to securely store your card details and be charged according to the following payment terms:</div>
    {user.billing_discount > 0 ? 
        <div>${user.billing_discount}.00/facility charged monthly for the first 3 months, then ${user.billing_price}.00/facility monthly</div>
        : props.newGroup ? <>
        <div><span className='terms'>${user.billing_price}.00/facility</span> charged monthly - <span className='terms'>${(user.billing_price*props.quantity).toFixed(2)}</span>/month for <span className='terms'>{props.quantity}</span> {props.quantity === 1 ? 'facility' : 'facilities'}</div>
    </> : <div className='terms'>${user.billing_price}.00 charged monthly</div>}
</Row>}
{!isNullorEmpty(errMsg) && <Row><div className='error'>{errMsg}</div></Row>}</>}

    return <div className={props.new ? 'payment-modal new' : 'payment-modal update'}><CustomModal maxHeight='95%' height={(props.newGroup && !loading) ? '95%' : 'fit-content'} title={props.new ? 'Add Subscription' : 'Update Payment Method'} 
    onSave={submitPayment} saveBtnTxt={props.new ? 'Submit' : 'Save'} closeModal={props.onClose} backArrow={props.newGroup} backArrowFunc={props.newGroup ? props.goBack : undefined} disableSave={loading}>
        {props.new && props.facility !== 0 && getFacilityName()}
        {loading && <div className="spinner"><Spinner /></div>}
        <div className={loading ? 'payment-form hide' : 'payment-form'}>  
            {props.newGroup ? <Tabs activeKey="billing" fill>
                <Tab eventKey="facilities" title={<span><svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.37492 14.0417H2.16659V4.03503C2.16661 3.78577 2.24507 3.54283 2.39085 3.34064C2.53663 3.13845 2.74234 2.98726 2.97884 2.90849L9.31217 0.797909C9.49066 0.738461 9.68072 0.72227 9.86669 0.75067C10.0527 0.779071 10.2292 0.85125 10.3818 0.961262C10.5344 1.07127 10.6587 1.21597 10.7445 1.38343C10.8302 1.55089 10.8749 1.73632 10.8749 1.92445V14.0417H11.6666V6.90878C11.6666 6.85024 11.6796 6.79244 11.7047 6.73954C11.7298 6.68664 11.7663 6.63995 11.8115 6.60285C11.8568 6.56574 11.9098 6.53915 11.9666 6.52497C12.0234 6.5108 12.0826 6.50939 12.14 6.52087L13.8785 6.8692C14.1476 6.92299 14.3898 7.06832 14.5638 7.28047C14.7379 7.49263 14.8331 7.75853 14.8333 8.03295V14.0417H15.6249C15.8349 14.0417 16.0362 14.1251 16.1847 14.2736C16.3332 14.422 16.4166 14.6234 16.4166 14.8334C16.4166 15.0433 16.3332 15.2447 16.1847 15.3932C16.0362 15.5416 15.8349 15.625 15.6249 15.625H1.37492C1.16496 15.625 0.963592 15.5416 0.815126 15.3932C0.666659 15.2447 0.583252 15.0433 0.583252 14.8334C0.583252 14.6234 0.666659 14.422 0.815126 14.2736C0.963592 14.1251 1.16496 14.0417 1.37492 14.0417Z" fill="#6c757d" />
                </svg> Facilites</span>} disabled>                    
                </Tab>
                <Tab eventKey="billing" title={<span><svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="billingIcon">
                            <path d="M15.6666 3.66659H2.33329V1.99992H15.6666M15.6666 11.9999H2.33329V6.99992H15.6666M15.6666 0.333252H2.33329C1.40829 0.333252 0.666626 1.07492 0.666626 1.99992V11.9999C0.666626 12.4419 0.842221 12.8659 1.15478 13.1784C1.46734 13.491 1.89127 13.6666 2.33329 13.6666H15.6666C16.1087 13.6666 16.5326 13.491 16.8451 13.1784C17.1577 12.8659 17.3333 12.4419 17.3333 11.9999V1.99992C17.3333 1.55789 17.1577 1.13397 16.8451 0.821407C16.5326 0.508847 16.1087 0.333252 15.6666 0.333252Z" fill="white" />
                        </svg>Billing Details</span>}>
                    {FormBody()}
                </Tab>
            </Tabs> : FormBody()}
        </div>

    </CustomModal></div>

}


export default Payment