const validateWhileTyping = (valueType, value, orig) => {
    value = valueType.onlyNums ? stripNotNumbers(value, (valueType.format === formatNumberList)) : value;
    if (valueType.length > 0) {
        if (value.length > valueType.length) {
            return orig;
        }
        value = value.substring(0, valueType.length);
    }
    value = valueType.format(value);
    return value;
}

const stripNotNumbers = (value, leaveCommas) => { return value.replace(leaveCommas ? /[^-,0-9]/gi : /[^0-9]/gi, ''); }

const formatPhone = phone => {
    phone = phone.replace(/^(\d{1,3})/, '($1');
    phone = phone.replace(/^\((\d{3})(\d{1,3})/, '($1)$2');
    phone = phone.replace(/^\((\d{3})\)(\d{3})(.+)/, '($1)$2-$3');
    return phone;
}

const formatSSN = (ssn) => {
    ssn = ssn.replace(/^(\d{3})(\d{1,2})/, '$1-$2');
    ssn = ssn.replace(/^(\d{3})-(\d{2})(.+)/, '$1-$2-$3');
    return ssn;
}

const formatEIN = ein => {
    ein = ein.replace(/^(\d{2})(.+)/, '$1-$2');
    return ein;

}

const formatFileName = filename => {
    for (const char of [':','/','\\','<','>','"','|','?','*']) {
        filename = filename.replace(char, '')
    }
    return filename;

}

const formatMoney = (money) => {
    money = money.replace(/\b0+/g, '')
    money = Number(money) / 100
    money = money.toFixed(2);
    return money;
}
const formatTax = (num) => {
    num = num.toFixed(3)
    return num;
}
const format = value => value;

const formatNumberList = list => list;

export const types = {
    SSN: { length: 9, format: formatSSN, onlyNums: true },
    Phone: { length: 10, format: formatPhone, onlyNums: true },
    EIN: { length: 9, format: formatEIN, onlyNums: true },
    Zip: { length: 5, format: format, onlyNums: true },
    NPI: { length: 10, format: format, onlyNums: true },
    Num: { length: 0, format: format, onlyNums: true },
    Filename: { length: 0, format: formatFileName, onlyNums: false },
    MoneyUpTo19: { length: 19, format: formatMoney, onlyNums: true },
    MoneyUpTo5: { length: 5, format: formatMoney, onlyNums: true },
    SalesTax: { length: 5, format: formatTax, onlyNums: true },
    NumberList: { length: 0, format: formatNumberList, onlyNums: true }
}

export default validateWhileTyping;