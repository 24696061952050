import axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import { Title } from "../SideBar";
import TableScrollBar from 'react-table-scrollbar';
import PendingFacilities from "./SubscribePendingFac";
import Spinner from "../../Utilities/Spinner";
import { useDispatch, useSelector } from 'react-redux';
import isNullorEmpty from "../../Utilities/isNullorEmpty";
import ClientBillingFacility from "./ClientBillingFacility"
import { loadAllFacilities } from "../../redux/globalfunctions";


const ClientMainBilling   = props => {

    const [showBilling, setShowBilling] = useState(props.billingPage)
    const [error, setError] = useState(null)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const facilities = useSelector(state => state.globalState.allFacilities)
    const [facilityDetails, setFacilityDetails]= useState({
        name: "",
        id: "",
        group: ""
    })
    const user = useSelector(state => state.globalState.user)

    useEffect(() => {
       console.log("facilities ", facilities)
       if(facilities)
        setLoading(false)
        console.log("pending: ",facilities.find(f => f.is_active === 2))
        console.log("subscribed: ",facilities.find(f => f.is_active === 1))
    },[facilities, showBilling])



    const showBillingPage= (facility)=>
    {
        console.log("clicked");
        setFacilityDetails({
            name: facility.facility_name,
            id: facility.facilityid,
            group: facility.group
        });
        setShowBilling(true);
    }
     

    const FacilitySvg = () => <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="facilityIcon">
    <path d="M1.75 21.75H3V5.95C3.00004 5.55643 3.12392 5.17284 3.3541 4.85359C3.58429 4.53434 3.90909 4.29562 4.2825 4.17125L14.2825 0.838752C14.5643 0.744886 14.8644 0.719322 15.1581 0.764165C15.4517 0.809008 15.7305 0.922975 15.9714 1.09668C16.2124 1.27038 16.4087 1.49885 16.544 1.76326C16.6794 2.02767 16.75 2.32046 16.75 2.6175V21.75H18V10.4875C18.0001 10.3951 18.0206 10.3038 18.0602 10.2203C18.0998 10.1367 18.1574 10.063 18.2289 10.0044C18.3004 9.94586 18.384 9.90386 18.4737 9.88148C18.5633 9.8591 18.6569 9.85689 18.7475 9.875L21.4925 10.425C21.9174 10.5099 22.2998 10.7394 22.5746 11.0744C22.8494 11.4094 22.9997 11.8292 23 12.2625V21.75H24.25C24.5815 21.75 24.8995 21.8817 25.1339 22.1161C25.3683 22.3505 25.5 22.6685 25.5 23C25.5 23.3315 25.3683 23.6495 25.1339 23.8839C24.8995 24.1183 24.5815 24.25 24.25 24.25H1.75C1.41848 24.25 1.10054 24.1183 0.866116 23.8839C0.631696 23.6495 0.5 23.3315 0.5 23C0.5 22.6685 0.631696 22.3505 0.866116 22.1161C1.10054 21.8817 1.41848 21.75 1.75 21.75Z" />
    </svg>
    

    const DetailsSvg = ()=><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.98142 16.0647L14.5 7.54608L10.4538 3.49991L1.93525 12.0185C1.81798 12.1359 1.73467 12.2829 1.69417 12.4438L0.75 17.2499L5.55517 16.3057C5.7165 16.2654 5.86408 16.182 5.98142 16.0647ZM17.25 4.79608C17.5937 4.45228 17.7868 3.98604 17.7868 3.49991C17.7868 3.01378 17.5937 2.54754 17.25 2.20374L15.7962 0.74991C15.4524 0.406213 14.9861 0.213135 14.5 0.213135C14.0139 0.213135 13.5476 0.406213 13.2038 0.74991L11.75 2.20374L15.7962 6.24991L17.25 4.79608Z" fill="#81C8D0"/>
    </svg>



    return <>
    {loading ? <Spinner /> : showBilling ? <ClientBillingFacility billingPage={showBilling} facility={facilityDetails} /> : 
    error ? <div>{error}</div> :
        <Fragment><Title/> 
         {facilities &&  
            <>
         <div className="tablesContainer">
            <div className={isNullorEmpty(facilities.find(f => f.is_active === 2)) ? 'billingTablecont tablecont fullWidth' : 'billingTablecont tablecont subFac'}>
           <div className="facilityheader">SUBSCRIBED FACILITIES</div>
           <TableScrollBar height='calc(100% - 40px)'><table>                      
                       <thead>
                               <tr>
                                   <th className="facilityheader"><FacilitySvg/>Facility </th>
                                   <th className="facilityheader">Monthly Cost</th>
                                   <th></th>
                               </tr>
                        </thead>
                               
                               <tbody>
                               {facilities.map((f) => 
                               f.is_active==1 &&
                               <tr key={f.facilityid}>
                                   <td><span className="facility" id={`fac_${f.facilityid}`}><span><FacilitySvg/><span id='main-fac-name'>{ f.facility_name}</span></span></span></td>
                                   <td>${user.billing_discount ? user.billing_discount : user.billing_price}</td>
                                   <td><span className="billingDetails" onClick={()=>showBillingPage(f)}><DetailsSvg/></span></td>
                               </tr>
                           )}    
                               </tbody>
                   </table>
                   </TableScrollBar>  
           </div>
           <PendingFacilities subFacility={null} billingPerGroup={false} reloadBillingDetails={() => dispatch(loadAllFacilities())}/>
        </div>
                            
        </>
        }
        </Fragment>
}
    </>
   
}

export default ClientMainBilling  ;