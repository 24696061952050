import React, { useEffect, useState } from 'react';
import { Spinner, Form, Button } from 'react-bootstrap';
import {capitalize} from 'lodash';
import { formatDateTime } from '../Utilities/FormatDate';
import dynamicSort from '../Utilities/SortTable';
import isNullorEmpty from '../Utilities/isNullorEmpty';
import axios from 'axios';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Config from '../Utilities/Config';
import downloadCsv from './DownloadData';
import UserFeedback from './UserFeedback';


const UserActivity = (props) => {

    // const [dateFilter, setDateFilter]=useState(new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString('en-GB').split('/').reverse().join('-')) 
    const [dateFilter, setDateFilter]=useState([new Date(new Date().setDate(new Date().getDate() - 1)), new Date()])
    const [loading, setLoading] = useState(true)
    const [activity, setActivity] = useState(null)
    const [sortField, setSortField] = useState("-date")
    const [groupFilter, setGroupFilter] = useState('mqs')
    const [feedback, setFeedback] = useState(null)
    const [viewFeedback, setViewFeedback] = useState(false)

    const changeSortField = field => {
        setSortField(sortField === field ? `-${field}` : field)
    }

    useEffect(() => {
        // if (window.location.host !== "internal.dolphincare.ai" && window.location.host !== "localhost:3001") {
        //     console.log(window.location.host)
        //     window.location.replace("http://internal.dolphincare.ai/");
        // }
        // else
        // {
            getFeedback()
          getActivity()  
        // }
    }, [])


    const getActivity = () => {
        setLoading(true)
        console.log("state date: ", dateFilter)
        axios.post(`/api/internal/getactivity/${groupFilter}/`, { 'dates': dateFilter }, Config()).then((res) => {          
            console.log(res.data)
            let mappedActivity = res.data.map(a => {return {...a, 'combined_name' : isNullorEmpty(a.user) ? a.email : (capitalize(a.user_first_name) + " " + capitalize(a.user_last_name) + " (" + a.user + ")")}})
            setActivity(mappedActivity)
            setLoading(false)
        }).catch(err => {
            console.log(err);
        }) 
    }

    const getFeedback = () => {
        axios.get(`/api/internal/getuserfeedback/`, Config()).then((res) => {
            setFeedback(res.data)
        }).catch(err => {
            console.log(err);
        }) 
    }
    
    return <div className='usersInfo'>
         {loading ? <Spinner animation='border' variant='dark' /> :
            viewFeedback ? <UserFeedback feedback={feedback} setViewFeedback={setViewFeedback}/> :
            activity && <div className='app-activity'>
                {/* <input type="date" className='date-filter' name="trip-start" value={dateFilter}  onChange={(e) => setDateFilter(e.target.value)}/> */}
                <div className='filters'>                        
                    <DateRangePicker disabled={loading} onChange={setDateFilter} value={dateFilter} />
                    <Form.Select
                    value = {groupFilter}
                    onChange={e => setGroupFilter(e.target.value)}>
                        <option value={0}>All Groups</option>
                        {props.groups && props.groups.sort(dynamicSort('group_code')).map(g => <option key={g.group_code} value={g.group_code}>{capitalize(g.group_code)}</option>)}
                    </Form.Select>
                    <Button className='search-btn' onClick={() => getActivity()}>Search</Button>
                    <div className='addBtn' onClick={() => downloadCsv(activity.sort(dynamicSort(sortField)), 'dc_activity')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                        </svg>
                    </div> 
                </div>
                        
                <h2>Activity</h2>
                {!isNullorEmpty(feedback) && <div>Popup Feedback: {feedback.length} <b className='pointer' onClick={() => setViewFeedback(feedback.length > 0)}>{feedback.length > 0 ? "View" : ""}</b></div>}
             
                <table>
                    <tr>
                        <th onClick={() => changeSortField("user_last_name")}>User
                            <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                        </th>
                        <th onClick={() => changeSortField("group")}>Group
                            <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                        </th>
                        <th onClick={() => changeSortField("facility")}>Facility
                            <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                        </th>
                        <th onClick={() => changeSortField("resident")}>Resident
                            <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                        </th>
                        <th onClick={() => changeSortField("date")}>Date
                            <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                        </th>
                        <th onClick={() => changeSortField("action")}>Action
                            <svg className="filterIcon" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" /></svg>
                        </th>
                    </tr>
                    {activity && activity.sort(dynamicSort(sortField)).map(a =>
                        <tr key={a.id}>
                            <td id={a.user}>{a.combined_name}</td>
                            <td>{a.group_name}</td>
                            <td>{a.resident_facility}</td>
                            <td>{!isNullorEmpty(a.resident) ? a.resident_name + " (" +a.resident + ")" : ""}</td>
                            <td>{formatDateTime(a.date)}</td>
                            <td>{a.action}</td>
                        </tr>)}
                </table>
            </div>}
    </div>
}

export default UserActivity;

